import { formatDate } from '@angular/common';
import { Component, TemplateRef, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Department } from 'src/app/models/department';
import { DocumentTemplate, DocumentTemplateType, ContractDocument, ContractDocumentStatus, ContractThirdParty, ContractDocumentCorrespondence, DocumentCorrespondenceType, DocumentCorrespondenceAttachment, ContractDocumentAgreementType, ContractDocumentCost, ContractDocumentRequestType, DocumentRiskRating } from 'src/app/models/documents';
import { AlertService } from 'src/app/services/alert.service';
import { DocumentsApiService } from 'src/app/services/documents-api.service';
import { LookupApiService } from 'src/app/services/lookup-api.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-contracts-add-edit',
  templateUrl: './contracts-add-edit.component.html',
  styleUrl: './contracts-add-edit.component.scss'
})
export class ContractsAddEditComponent {

  private modalService = inject(NgbModal);
  
  companyId: number;
  orgId: number;
  apiCompanyId?: number;

  userId: number;
  user: boolean = false;
  contractAdmin: boolean = false;
  companyAdmin: boolean = false;
  orgAdmin: boolean = false;
  superAdmin: boolean = false;

  templateTypes: DocumentTemplateType[];
  templates: DocumentTemplate[];
  documentCorrespondenceTypes: DocumentCorrespondenceType[];

  statuses: ContractDocumentStatus[];
  agreementTypes: ContractDocumentAgreementType[];

  requestTypes: ContractDocumentRequestType[];
  requestTypeOther!: ContractDocumentRequestType;
  riskRatings: DocumentRiskRating[];
  departments: Department[];
  
  submitted = false;

  mainForm = new FormGroup({
    id: new FormControl(),
    createdAt: new FormControl(),
    lastModified: new FormControl(),
    referenceNumber: new FormControl(),

    documentFilePath: new FormControl(),
    documentFileData: new FormControl(),
    
    standardTemplate: new FormControl(undefined, Validators.required),
    useAsTemplate: new FormControl(false),
    templateName: new FormControl(undefined),

    documentTemplateId: new FormControl(),

    requestedDate: new FormControl(undefined, Validators.required),

    requestorName: new FormControl(),
    departmentId: new FormControl(),
    requestorEmail: new FormControl(undefined, Validators.email),

    contractDocumentRequestTypeId: new FormControl(undefined, Validators.required),
    contractDocumentRequestTypeOther: new FormControl(undefined),
    documentRiskRatingId: new FormControl(undefined),

    draftedByName: new FormControl(),
    draftedByDepartment: new FormControl(),
    draftedByEmail: new FormControl(undefined, Validators.email),

    dueDiligenceDocumentFilePath: new FormControl(),
    dueDiligenceDocumentFileData: new FormControl(),

    
    contractDetails: new FormControl(),
    companyStandard: new FormControl(),
    organisationOwnAgreement: new FormControl(),
    agreementSuppliedByOtherParty: new FormControl(),

    purposeDetails: new FormControl(),
    serviceSubjectMatterDetails: new FormControl(),
    objective: new FormControl(),
    inputs: new FormControl(),
    outcomes: new FormControl(),

    organisationName: new FormControl(),
    department: new FormControl(),
    negotiatingTeam: new FormControl(),
    headOfTeam: new FormControl(),
    contractsAdministrator: new FormControl(),

    disbursementCost: new FormControl(undefined, Validators.min(0)),
    disbursementCostPerMonth: new FormControl(undefined, Validators.min(0)),
    disbursementCostPerAnnum: new FormControl(undefined, Validators.min(0)),
    disbursementIncreasePerAnnum: new FormControl(undefined, Validators.min(0)),

    dealValue: new FormControl(undefined, Validators.min(0)),
    dealValuePerMonth: new FormControl(undefined, Validators.min(0)),
    dealValuePerAnnum: new FormControl(undefined, Validators.min(0)),
    dealIncreasePerAnnum: new FormControl(undefined, Validators.min(0)),

    commencementDate: new FormControl(),
    contractDuration: new FormControl(),
    expectedEndDate: new FormControl(),

    terminationForNoCause: new FormControl(),
    terminationForNoCauseNoticePeriod: new FormControl(),
    otherRightsToTerminateDetails: new FormControl(),
    renewRightOfFirstRefusal: new FormControl(),
    renewDetails: new FormControl(),
    renewOptions: new FormControl(),

    coveredTermDurationCommencementDateAndTermination: new FormControl(),
    coveredRightsToRenew: new FormControl(),
    coveredPOPIAClauseIncludingReferenceToOperator: new FormControl(),
    coveredObligations: new FormControl(),
    coveredContractFeeAndPayment: new FormControl(),
    coveredConfidentiality: new FormControl(),
    coveredIntellectualPropertyRights: new FormControl(),
    coveredTrademarks: new FormControl(),
    coveredDesigns: new FormControl(),
    coveredCopyright: new FormControl(),
    coveredIPProtectedUnderNoCompeteClause: new FormControl(),
    coveredEmployeeClause: new FormControl(),
    coveredIndependentContractor: new FormControl(),
    coveredInsiderTradingClause: new FormControl(),
    coveredDatabaseUse: new FormControl(),
    coveredChangeManagementClause: new FormControl(),
    coveredManagementInterventionClause: new FormControl(),
    coveredArbitration: new FormControl(),
    coveredMergerAcquisitionClause: new FormControl(),
    coveredOptionsToRenewOrFirstRightOfRefusal: new FormControl(),
    coveredCostsAndEscalations: new FormControl(),
    coveredWarranties: new FormControl(),
    coveredGuaranties: new FormControl(),
    coveredInsurance: new FormControl(),
    coveredIndemnities: new FormControl(),
    coveredNoticeAndDomicilium: new FormControl(),
    coveredElectronicSignature: new FormControl(),
    coveredBreachAndRightToTerminate: new FormControl(),
    coveredTaxProvisions: new FormControl(),
    coveredApplicableLawAndJurisdiction: new FormControl(),
    coveredLegislationSHEAndSec37Indemnity: new FormControl(),
    coveredLegislationEnvironmentalEIA: new FormControl(),
    coveredLegislationOther: new FormControl(),
    coveredAnnexures: new FormControl(),
    coveredServiceLevels: new FormControl(),
    coveredOther: new FormControl(),
    coveredTermDurationCommencementDateAndTerminationDetails: new FormControl(),
    coveredRightsToRenewDetails: new FormControl(),
    coveredPOPIAClauseIncludingReferenceToOperatorDetails: new FormControl(),
    coveredObligationsDetails: new FormControl(),
    coveredContractFeeAndPaymentDetails: new FormControl(),
    coveredConfidentialityDetails: new FormControl(),
    coveredIntellectualPropertyRightsDetails: new FormControl(),
    coveredTrademarksDetails: new FormControl(),
    coveredDesignsDetails: new FormControl(),
    coveredCopyrightDetails: new FormControl(),
    coveredIPProtectedUnderNoCompeteClauseDetails: new FormControl(),
    coveredEmployeeClauseDetails: new FormControl(),
    coveredIndependentContractorDetails: new FormControl(),
    coveredInsiderTradingClauseDetails: new FormControl(),
    coveredDatabaseUseDetails: new FormControl(),
    coveredChangeManagementClauseDetails: new FormControl(),
    coveredManagementInterventionClauseDetails: new FormControl(),
    coveredArbitrationDetails: new FormControl(),
    coveredMergerAcquisitionClauseDetails: new FormControl(),
    coveredOptionsToRenewOrFirstRightOfRefusalDetails: new FormControl(),
    coveredCostsAndEscalationsDetails: new FormControl(),
    coveredWarrantiesDetails: new FormControl(),
    coveredGuarantiesDetails: new FormControl(),
    coveredInsuranceDetails: new FormControl(),
    coveredIndemnitiesDetails: new FormControl(),
    coveredNoticeAndDomiciliumDetails: new FormControl(),
    coveredElectronicSignatureDetails: new FormControl(),
    coveredBreachAndRightToTerminateDetails: new FormControl(),
    coveredTaxProvisionsDetails: new FormControl(),
    coveredApplicableLawAndJurisdictionDetails: new FormControl(),
    coveredLegislationSHEAndSec37IndemnityDetails: new FormControl(),
    coveredLegislationEnvironmentalEIADetails: new FormControl(),
    coveredLegislationOtherDetails: new FormControl(),
    coveredAnnexuresDetails: new FormControl(),
    coveredServiceLevelsDetails: new FormControl(),
    coveredOtherDetails: new FormControl(),

    riskAndOnerousClauses: new FormControl(),
    complianceActions: new FormControl(),
    serviceLevels: new FormControl(),
    milestones: new FormControl(),
    approvedByLegal: new FormControl(),
    signedByBothParties: new FormControl(),
    signedNames: new FormControl(),
    signedDate: new FormControl(),
    userId: new FormControl(0),
    user: new FormControl(),
    companyId: new FormControl(),
    company: new FormControl(),
    contractDocumentStatusId: new FormControl(undefined, Validators.required),
    contractDocumentStatus: new FormControl(),
    contractThirdParties: new FormArray([]),
    contractDocumentAgreementTypeId: new FormControl(undefined, Validators.required),
    contractDocumentAgreementType: new FormControl(),

    contractDocumentCosts: new FormArray([]),
  });



  currentDocumentId = 0;
  //currentDocument: ContractDocument;

  constructor(
    private documentsApi: DocumentsApiService,
    private route: ActivatedRoute,
    private token: TokenService,
    private router: Router,
    private spinner: SpinnerService,
    private alertService: AlertService,
    private lookupApi: LookupApiService) { }

  ngOnInit() {

    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user) {
      this.companyId = this.token.companyId();
      if (this.companyId) {
        this.init();
      }

    }
    else if (this.superAdmin || this.orgAdmin) {
      // this.route.paramMap.subscribe((params: ParamMap) => {
      //   this.companyId = +params.get('companyId');
      //   this.apiCompanyId = this.companyId;
      //   if (this.companyId) {
      //     this.init();
      //   }
      // });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }


    this.route.paramMap.subscribe((params: ParamMap) => {
      this.currentDocumentId = +params.get('id');

      this.init();
    });

    this.documentsApi.GetContractStatuses().subscribe(data => {
      this.statuses = data;
    });

    this.documentsApi.GetContractAgreementTypes().subscribe(data => {
      this.agreementTypes = data;
    });

    this.lookupApi.ListDepartments().subscribe(data => {
      this.departments = data;
    });

    this.mainForm.controls.standardTemplate.valueChanges.subscribe(data => {
      this.mainForm.controls.documentTemplateId.clearValidators();
      this.mainForm.controls.documentFileData.clearValidators();

      if (this.currentDocumentId == 0) {
        if (data == true) {
          this.mainForm.controls.documentTemplateId.addValidators(Validators.required);
        }
        else {
          this.mainForm.controls.documentFileData.addValidators(Validators.required);
        }


      }

      this.mainForm.controls.documentTemplateId.updateValueAndValidity();
      this.mainForm.controls.documentFileData.updateValueAndValidity();
    });

    this.mainForm.controls.useAsTemplate.valueChanges.subscribe(data => {
      if (this.mainForm.controls.useAsTemplate.value){
        this.mainForm.controls.templateName.addValidators(Validators.required);
      } else{
        this.mainForm.controls.templateName.clearValidators();
      }
      this.mainForm.controls.templateName.updateValueAndValidity();
    });

    this.documentsApi.GetRiskRatings().subscribe(data => {
      this.riskRatings = data;
    });
    
    this.documentsApi.GetContractRequestTypes().subscribe(data => {
      this.requestTypes = data;
      this.requestTypeOther = this.requestTypes.find(f => f.name == 'Other');
    });

    this.mainForm.controls.contractDocumentRequestTypeId.valueChanges.subscribe(data => {
      
      if (this.mainForm.controls.contractDocumentRequestTypeId.value == this.requestTypeOther?.id) {
        this.mainForm.controls.contractDocumentRequestTypeOther.addValidators(Validators.required);
      }
      else {
        this.mainForm.controls.contractDocumentRequestTypeOther.removeValidators(Validators.required);
      }
      this.mainForm.controls.contractDocumentRequestTypeOther.updateValueAndValidity();
    });

  }


  currentDocument: ContractDocument;
  init() {

    if (this.apiCompanyId) {
      this.documentsApi.GetTemplates(this.apiCompanyId, 1).subscribe(data => this.templates = data);
    }
    
    
    if (this.currentDocumentId > 0 && this.apiCompanyId) {

      this.documentsApi.GetDocumentCorrespondenceTypes('Contract').subscribe(data => {
        this.documentCorrespondenceTypes = data;
      });

      this.documentsApi.GetContractDocument(this.currentDocumentId, this.apiCompanyId).subscribe(data => {

        if (!data){
          this.router.navigateByUrl('/dashboard/documents/contracts');
          return;
        }

        this.currentDocument = data;

        if (this.mainForm.controls.contractDocumentRequestTypeId.value == this.requestTypeOther?.id) {
          this.mainForm.controls.contractDocumentRequestTypeOther.addValidators(Validators.required);
        }
        else {
          this.mainForm.controls.contractDocumentRequestTypeOther.removeValidators(Validators.required);
        }
        this.mainForm.controls.contractDocumentRequestTypeOther.updateValueAndValidity();

        this.mainForm.patchValue(data);

        this.mainForm.patchValue({
          requestedDate: data.requestedDate ? formatDate(data.requestedDate, 'yyyy-MM-dd', 'en') : undefined,
          signedDate: data.signedDate ? formatDate(data.signedDate, 'yyyy-MM-dd', 'en') : undefined,
          expectedEndDate: data.expectedEndDate ? formatDate(data.expectedEndDate, 'yyyy-MM-dd', 'en') : undefined,
          commencementDate: data.commencementDate ? formatDate(data.commencementDate, 'yyyy-MM-dd', 'en') : undefined,
        });

        this.mainForm.controls.contractThirdParties = new FormArray([]);

        data.contractThirdParties.forEach(tp => {
          this.addThirdParty(tp)  
        });

        this.mainForm.controls.contractDocumentCosts = new FormArray([]);

        data.contractDocumentCosts.forEach(tp => {
          this.addCost(tp)
        });


        this.mainForm.updateValueAndValidity();
      })
    }
    else {
      this.mainForm.reset();
      this.mainForm.patchValue({
        standardTemplate: false,
        useAsTemplate: false
      });
      this.mainForm.controls.contractThirdParties = new FormArray([]);
      this.mainForm.controls.contractDocumentCosts = new FormArray([]);
      this.addThirdParty();
    }

  }

  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.init();
    }
    else {
      
    }
  }

  fileUpload(control: FormControl, event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 2147483648) { //2MB
        control.setValue(undefined);
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        control.patchValue(reader.result);
      };
    }
  }

  newThirdParty(thirdparty: ContractThirdParty = null): FormGroup {

    let fg = new FormGroup({
      id: new FormControl(0),
      name: new FormControl(undefined, Validators.required),
      registrationNumber: new FormControl(),
      ckNumber: new FormControl(),
      identityNumber: new FormControl(),
      address: new FormControl(),
      postal: new FormControl(),
      physical: new FormControl(),
      email: new FormControl(undefined, Validators.email),
      contactPerson: new FormControl(),
      contactNumber: new FormControl(),
      contractDocumentId: new FormControl(0)
    });

    if (thirdparty){
      fg.patchValue(thirdparty);
    }
    return fg;
  }

  addThirdParty(thirdparty: ContractThirdParty = null) {
    this.mainForm.controls.contractThirdParties.push(this.newThirdParty(thirdparty));
    this.mainForm.updateValueAndValidity();
  }

  removeThirdParty(index: number) {
    this.mainForm.controls.contractThirdParties.removeAt(index);
    this.mainForm.updateValueAndValidity();
  }


  newCost(cost: ContractDocumentCost = null): FormGroup {

    let fg = new FormGroup({
      id: new FormControl(0),
      date: new FormControl(undefined, Validators.required),
      description: new FormControl(undefined, Validators.required),
      rate: new FormControl(undefined, [Validators.required, Validators.min(0.01)]),
      time: new FormControl(undefined, [Validators.required, Validators.min(0.01)]),
      contractDocumentId: new FormControl(0)
    });

    if (cost) {
      fg.patchValue(cost);

      fg.patchValue({
        date: cost.date ? formatDate(cost.date, 'yyyy-MM-dd', 'en') : undefined
      });
    }
    return fg;
  }

  addCost(cost: ContractDocumentCost = null) {
    this.mainForm.controls.contractDocumentCosts.push(this.newCost(cost));
    this.mainForm.updateValueAndValidity();
    return this.mainForm.controls.contractDocumentCosts.length - 1;
  }

  removeCost(index: number) {
    this.mainForm.controls.contractDocumentCosts.removeAt(index);
    this.mainForm.updateValueAndValidity();
  }


  submit() {

    let index = this.addCost();
    this.removeCost(index);

    this.mainForm.markAllAsTouched();

    if (!this.mainForm.valid) {
      this.findInvalidControls();
      this.alertService.error('Not all required fields have been completed please check the form.');

      return;
    }

    let document = this.mainForm.value as ContractDocument;
    document.id = this.currentDocumentId;
    document.companyId = this.apiCompanyId;
    document.userId = this.userId;
    if (!document.useAsTemplate) document.useAsTemplate = false;

    this.spinner.startBackground();
    let func: Observable<{}>;

    if (this.currentDocumentId == 0) {
      func = this.documentsApi.AddContractDocument(document);

    } else {
      func = this.documentsApi.UpdateContractDocument(document);
    }

    func.subscribe({ 
      next:() => {
        this.spinner.stopBackground();
        this.alertService.success('Record succesfully added');
        this.router.navigateByUrl('/dashboard/documents/contracts');
      }, 
      error: (error) => {
        this.spinner.stopBackground();
        this.alertService.error(JSON.stringify(error));
      }
    });
  }

  correspondenceForm: FormGroup;
  correspondenceFormSubmitted = false;

  sendCorrespondence(content: TemplateRef<any>) {
    this.correspondenceForm = new FormGroup({
      name: new FormControl(undefined, Validators.required),
      email: new FormControl(undefined, [Validators.required, Validators.email]),
      sender: new FormControl(undefined, Validators.required),
      documentFileData: new FormControl(undefined, Validators.required),
      documentCorrespondenceTypeId: new FormControl(undefined, Validators.required),
      attachments: new FormArray([])
    });

    this.correspondenceFormSubmitted = false;
    
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				
			},
			(reason) => {
				
			},
		);
  }

  addAttachement() {
    (this.correspondenceForm.controls.attachments as FormArray).push(new FormControl(undefined, Validators.required));
      
    this.correspondenceForm.updateValueAndValidity();
  }

  removeAttachement(index: number) {
    (this.correspondenceForm.controls.attachments as FormArray).removeAt(index);
    this.correspondenceForm.updateValueAndValidity();
  }

 
  submitCorrespondence(){
    this.correspondenceFormSubmitted = true;
    if (!this.correspondenceForm.valid){
      return;
    }

    this.showCorrespondence = false;

    let attachments: DocumentCorrespondenceAttachment[] = [];

    (this.correspondenceForm.controls.attachments as FormArray).controls.forEach(f => {
        let a: DocumentCorrespondenceAttachment = {
          documentFileData: f.value
        };
        attachments.push(a);
    });

    let model: ContractDocumentCorrespondence = {
      documentCorrespondenceTypeId: this.correspondenceForm.controls.documentCorrespondenceTypeId.value,
      contractDocumentId: this.currentDocumentId,
      email: this.correspondenceForm.controls.email.value,
      name: this.correspondenceForm.controls.name.value,
      sender: this.correspondenceForm.controls.sender.value,
      documentFileData: this.correspondenceForm.controls.documentFileData.value,
      attachments: attachments
    };

    this.spinner.start();

    this.documentsApi.addContractDocumentCorrespondence(model, this.apiCompanyId).subscribe(data => {
      this.spinner.stop();
      this.alertService.success('Correspondence succesfully submitted');
      this.modalService.dismissAll();
    }, error => {
      this.spinner.stop();
      this.alertService.error(JSON.stringify(error));
    })

  }

  correspondence: ContractDocumentCorrespondence[];
  showCorrespondence = false;
  viewCorrespondence(){
    if (this.showCorrespondence){
      this.showCorrespondence = false;
      return;
    }
    this.spinner.startBackground();
    this.documentsApi.getContractDocumentCorrespondence(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
      this.showCorrespondence = true;
      this.correspondence = data;
      this.spinner.stopBackground();
    });
  }

  exportCorrespondence(){
    this.spinner.startBackground();
    this.documentsApi.exportContractDocumentCorrespondence(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }

  hideCorrespondence(){
    this.showCorrespondence = false;
  }

  download(ev: Event, id: number, file: string) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.getContractDocumentCorrespondenceFile(id, this.apiCompanyId).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  downloadAttachment(ev: Event, attachmentId: number, correspondenceId: number){
    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.getContractDocumentCorrespondenceAttachmentFile(this.currentDocumentId, attachmentId, correspondenceId, this.apiCompanyId).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    });
  }

  downloadDocumentFile(ev: Event, file: string) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.GetContractDocumentFile(this.currentDocumentId, this.apiCompanyId, file).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  downloadDocumentHistoryFile(ev: Event, id: number) {
    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.GetContractDocumentHistoryFile(this.currentDocumentId, this.apiCompanyId, id).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  public findInvalidControls() {

    const controls = this.mainForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        console.log('INVALID', name);
      }
    }

  }

  print() {
    let popupWin = window.open('', '_blank', 'width=1080,height=595');
    if (!popupWin) {
      return;
    }
    popupWin.document.open();
    let printContents = document.getElementById("mainForm")?.innerHTML;

    let printHead = document.head.innerHTML;
    popupWin.document
      .write(`<html>
           ${printHead}
          <body onload="window.print();">${printContents}</body></html>`);
    popupWin.document.close();

  }
}
