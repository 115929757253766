import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DocumentCorrespondenceAttachment, DocumentCorrespondenceType, DocumentRiskRating, DocumentTemplateType, FicaDocument, FicaDocumentCorrespondence, FicaDocumentCost, FicaDocumentRequestType, FicaDocumentStatus, FicaOwnerDirector } from 'src/app/models/documents';
import { DocumentsApiService } from 'src/app/services/documents-api.service';
import { TokenService } from 'src/app/services/token.service';
import { formatDate } from '@angular/common';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Department } from 'src/app/models/department';
import { LookupApiService } from 'src/app/services/lookup-api.service';

@Component({
  selector: 'app-fica-add-edit',
  templateUrl: './fica-add-edit.component.html',
  styleUrl: './fica-add-edit.component.scss'
})
export class FicaAddEditComponent implements OnInit {

  private modalService = inject(NgbModal);
  
  companyId: number;
  orgId: number;
  apiCompanyId?: number;

  userId: number;
  user: boolean = false;
  contractAdmin: boolean = false;
  companyAdmin: boolean = false;
  orgAdmin: boolean = false;
  superAdmin: boolean = false;

  templateTypes: DocumentTemplateType[];
  documentCorrespondenceTypes: DocumentCorrespondenceType[];

  //regions: DocumentRegion[];
  //facilities: DocumentRegion[];
  statuses: FicaDocumentStatus[];

  requestTypes: FicaDocumentRequestType[];
  requestTypeOther!: FicaDocumentRequestType;
  riskRatings: DocumentRiskRating[];
  departments: Department[];
  
  submitted = false;

  mainForm = new FormGroup({
    id: new FormControl(0),
    createdAt: new FormControl(),
    lastModified: new FormControl(),
    referenceNumber: new FormControl(),

    documentFilePath: new FormControl(),
    documentFileData: new FormControl(undefined, Validators.required),

    //standardTemplate: new FormControl(undefined, Validators.required),
    //useAsTemplate: new FormControl(false),
    //templateName: new FormControl(undefined),

    //documentTemplateId: new FormControl(),

    recipientFirstname: new FormControl(undefined, Validators.required),
    recipientLastname: new FormControl(undefined, Validators.required),
    recipientContactNumber: new FormControl(undefined, Validators.required),
    recipientEmail: new FormControl(undefined, [Validators.required, Validators.email]),

    //documentRegionId: new FormControl(undefined, Validators.required),
    //documentFacilityId: new FormControl(undefined, Validators.required),

    receivedDate: new FormControl(undefined, Validators.required),
    acknowledgedDate: new FormControl(undefined, Validators.required),

    requestorName: new FormControl(),
    requestorIDNumber: new FormControl(),
    departmentId: new FormControl(),

    clientFirstname: new FormControl(),
    clientLastname: new FormControl(),
    clientIDNumber: new FormControl(),
    clientCompany: new FormControl(),
    clientCompanyRegistrationNumber: new FormControl(),
    clientContactNumber: new FormControl(),
    clientEmail: new FormControl(),
    clientAddress: new FormControl(),
    clientBusinessStructure: new FormControl(),
    clientOwnership: new FormControl(),
    clientShareholder: new FormControl(),
    clientControlPersons: new FormControl(),
    clientVerificationFilePath: new FormControl(),
    clientVerificationFileData: new FormControl(),

    ficaDocumentRequestTypeId: new FormControl(undefined, Validators.required),
    ficaDocumentRequestTypeOther: new FormControl(undefined),
    documentRiskRatingId: new FormControl(undefined),

    requestDetails: new FormControl(),
    requestReason: new FormControl(),
    requestDate: new FormControl(),

    confirmFinancialStatements: new FormControl(),
    confirmFinancialStatementsDetails: new FormControl(),
    confirmFinancialStatementsFilePath: new FormControl(),
    confirmFinancialStatementsFileData: new FormControl(),
    confirmCreditHistory: new FormControl(),
    confirmCreditHistoryDetails: new FormControl(),
    confirmCreditHistoryFilePath: new FormControl(),
    confirmCreditHistoryFileData: new FormControl(),
    confirmProfitabilityStability: new FormControl(),
    confirmProfitabilityStabilityDetails: new FormControl(),
    confirmBusinessModel: new FormControl(),
    confirmBusinessModelDetails: new FormControl(),
    confirmOperationalEfficiency: new FormControl(),
    confirmOperationalEfficiencyDetails: new FormControl(),
    confirmIndustryAnalysis: new FormControl(),
    confirmIndustryAnalysisDetails: new FormControl(),
    confirmCreditRisk: new FormControl(),
    confirmCreditRiskDetails: new FormControl(),
    confirmMarketRisk: new FormControl(),
    confirmMarketRiskDetails: new FormControl(),
    confirmOperationalRisk: new FormControl(),
    confirmOperationalRiskDetails: new FormControl(),
    confirmReputationRisk: new FormControl(),
    confirmReputationRiskDetails: new FormControl(),
    confirmAMLRisk: new FormControl(),
    confirmAMLRiskDetails: new FormControl(),
    confirmSanctionsPEPs: new FormControl(),
    confirmSanctionsPEPsDetails: new FormControl(),
    confirmLegalCompliance: new FormControl(),
    confirmLegalComplianceDetails: new FormControl(),
    confirmCorporateHistory: new FormControl(),
    confirmCorporateHistoryDetails: new FormControl(),
    confirmLitigationLegal: new FormControl(),
    confirmLitigationLegalDetails: new FormControl(),
    confirmManagementTeam: new FormControl(),
    confirmManagementTeamDetails: new FormControl(),
    confirmDueDiligenceReport: new FormControl(),
    confirmDueDiligenceReportDetails: new FormControl(),
    confirmRiskRating: new FormControl(),
    confirmRiskRatingDetails: new FormControl(),
    confirmPeriodicReviews: new FormControl(),
    confirmPeriodicReviewsDetails: new FormControl(),
    confirmTransactionMonitoring: new FormControl(),
    confirmTransactionMonitoringDetails: new FormControl(),

    ficaDocumentStatusId: new FormControl(undefined, Validators.required),
    dueDilligenceFileData: new FormControl(),
    dueDilligenceFileOath: new FormControl(),
    sanctionsTest: new FormControl(),
    sanctionsTestFileData: new FormControl(),
    sanctionsTestFilePath: new FormControl(),

    creditBureuxFee: new FormControl(),
    sanctionsFee: new FormControl(),
    otherFee: new FormControl(),
    totalFee: new FormControl(),
    departmentToBeCharged: new FormControl(),

    decision: new FormControl(),
    decisionReason: new FormControl(),
    decisionDate: new FormControl(),

    //infoRequired: new FormControl(),
    //infoRequiredReason: new FormControl(),

    transactionOpenedDate: new FormControl(),
    transactionApprovedDate: new FormControl(),
    transactionClosedDate: new FormControl(),

    disbursementCost: new FormControl(undefined, Validators.min(0)),
    disbursementCostPerMonth: new FormControl(undefined, Validators.min(0)),
    disbursementCostPerAnnum: new FormControl(undefined, Validators.min(0)),
    disbursementIncreasePerAnnum: new FormControl(undefined, Validators.min(0)),

    dealValue: new FormControl(undefined, Validators.min(0)),
    dealValuePerMonth: new FormControl(undefined, Validators.min(0)),
    dealValuePerAnnum: new FormControl(undefined, Validators.min(0)),
    dealIncreasePerAnnum: new FormControl(undefined, Validators.min(0)),


    //renewalDate: new FormControl(),

    kycFormFilePath: new FormControl(),
    kycFormFileData: new FormControl(),

    companyDocumentsFilePath: new FormControl(),
    companyDocumentsFileData: new FormControl(),

    identityDocumentsFilePath: new FormControl(),
    identityDocumentsFileData: new FormControl(),

    additionalTransactionFilePath: new FormControl(),
    additionalTransactionFileData: new FormControl(),

    companyId: new FormControl(0),
    company: new FormControl(),
    userId: new FormControl(0),
    user: new FormControl(),

    notes: new FormControl(),

    //documentTemplate: new FormControl(),
    //ficaDocumentStatus: new FormControl(),
    //documentFacility: new FormControl(),

    ficaOwnerDirectors: new FormArray([]),

    ficaDocumentCosts: new FormArray([]),
  });

  currentDocumentId = 0;
  //currentDocument: FicaDocument;

  constructor(
    private documentsApi: DocumentsApiService,
    private route: ActivatedRoute,
    private token: TokenService,
    private router: Router,
    private spinner: SpinnerService,
    private alertService: AlertService,
    private lookupApi: LookupApiService) { }

  ngOnInit() {

    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user || this.contractAdmin) {
      this.companyId = this.token.companyId();
      this.apiCompanyId = this.companyId;
      if (this.companyId) {
        this.init();
      }

    }
    else if (this.superAdmin || this.orgAdmin) {
      // this.route.paramMap.subscribe((params: ParamMap) => {
      //   this.companyId = +params.get('companyId');
      //   this.apiCompanyId = this.companyId;
      //   if (this.companyId) {
      //     this.init();
      //   }
      // });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }


    this.route.paramMap.subscribe((params: ParamMap) => {
      this.currentDocumentId = +params.get('id');
      this.init();
    });

    // this.documentsApi.GetRegions().subscribe(data => {
    //   this.regions = data;
    // });
    this.documentsApi.GetFicaStatuses().subscribe(data => {
      this.statuses = data;
    });

    // this.mainForm.controls.documentRegionId.valueChanges.subscribe(data => {

    //   if (data) {
    //     this.facilities = undefined;
    //     this.mainForm.controls.documentFacilityId.patchValue(undefined);
    //     this.documentsApi.GetFacilities(this.mainForm.controls.documentRegionId.value).subscribe(data => {
    //       this.facilities = data;
    //     })
    //   }
    // });

    this.documentsApi.GetRiskRatings().subscribe(data => {
      this.riskRatings = data;
    });

    this.documentsApi.GetFicaRequestTypes().subscribe(data => {
      this.requestTypes = data;
      this.requestTypeOther = this.requestTypes.find(f => f.name == 'Other');
    });

    this.lookupApi.ListDepartments().subscribe(data => {
      this.departments = data;
    });

    this.mainForm.controls.ficaDocumentRequestTypeId.valueChanges.subscribe(data => {
      
      if (this.mainForm.controls.ficaDocumentRequestTypeId.value == this.requestTypeOther?.id) {
        this.mainForm.controls.ficaDocumentRequestTypeOther.addValidators(Validators.required);
      }
      else {
        this.mainForm.controls.ficaDocumentRequestTypeOther.removeValidators(Validators.required);
      }
      this.mainForm.controls.ficaDocumentRequestTypeOther.updateValueAndValidity();
    });

  }

  currentDocument: FicaDocument;
  init() {
    
    if (this.currentDocumentId > 0 && this.apiCompanyId) {
      this.documentsApi.GetDocumentCorrespondenceTypes('FICA').subscribe(data => {
        this.documentCorrespondenceTypes = data;
      });
      this.documentsApi.GetFicaDocument(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
        if (!data){
          this.router.navigateByUrl('/dashboard/documents/fica');
          return;
        }

        this.currentDocument = data;
        
        
        this.mainForm.patchValue(data);

        this.mainForm.controls.documentFileData.clearValidators();
        this.mainForm.controls.documentFileData.updateValueAndValidity();

        if (this.mainForm.controls.ficaDocumentRequestTypeId.value == this.requestTypeOther?.id) {
          this.mainForm.controls.ficaDocumentRequestTypeOther.addValidators(Validators.required);
        }
        else {
          this.mainForm.controls.ficaDocumentRequestTypeOther.removeValidators(Validators.required);
        }
        this.mainForm.controls.ficaDocumentRequestTypeOther.updateValueAndValidity();


        this.mainForm.patchValue({
          acknowledgedDate: data.acknowledgedDate ? formatDate(data.acknowledgedDate, 'yyyy-MM-dd', 'en') : undefined,
          //renewalDate: data.renewalDate? formatDate(data.renewalDate, 'yyyy-MM-dd', 'en') : undefined,
          requestDate: data.requestDate? formatDate(data.requestDate, 'yyyy-MM-dd', 'en') : undefined,
          receivedDate: data.receivedDate? formatDate(data.receivedDate, 'yyyy-MM-dd', 'en') : undefined,
          decisionDate: data.decisionDate? formatDate(data.decisionDate, 'yyyy-MM-dd', 'en') : undefined,
          transactionClosedDate: data.transactionClosedDate? formatDate(data.transactionClosedDate, 'yyyy-MM-dd', 'en') : undefined,
          transactionOpenedDate: data.transactionOpenedDate? formatDate(data.transactionOpenedDate, 'yyyy-MM-dd', 'en') : undefined,
          transactionApprovedDate: data.transactionApprovedDate? formatDate(data.transactionApprovedDate, 'yyyy-MM-dd', 'en') : undefined
        });


        this.mainForm.controls.ficaOwnerDirectors = new FormArray([]);

        data.ficaOwnerDirectors.forEach(tp => {
          this.addOwnerDirector(tp)  
        });

        this.mainForm.controls.ficaDocumentCosts = new FormArray([]);

        data.ficaDocumentCosts.forEach(tp => {
          this.addCost(tp)
        });


        this.mainForm.updateValueAndValidity();


      })
    }
    else{
      this.mainForm.controls.ficaOwnerDirectors = new FormArray([]);
      this.mainForm.controls.ficaDocumentCosts = new FormArray([]);
      this.addOwnerDirector();
    }
  }

  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.init();
    }
    else {
      
    }
  }

  fileUpload(control: FormControl, event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 2147483648) { //2MB
        control.setValue(undefined);
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        control.patchValue(reader.result);
      };
    }
  }


  newOwnerDirector(ownerDirector: FicaOwnerDirector = null): FormGroup {

    let fg = new FormGroup({
      id: new FormControl(0),
      name: new FormControl(undefined, Validators.required),
      surname: new FormControl(undefined, Validators.required),
      identityNumber: new FormControl(undefined, Validators.required),
      address: new FormControl(undefined, Validators.required),
    });

    if (ownerDirector){
      fg.patchValue(ownerDirector);
    }
    return fg;
  }

  addOwnerDirector(ownerDirector: FicaOwnerDirector = null) {
    this.mainForm.controls.ficaOwnerDirectors.push(this.newOwnerDirector(ownerDirector));
    this.mainForm.updateValueAndValidity();
  }

  removeOwnerDirector(index: number) {
    this.mainForm.controls.ficaOwnerDirectors.removeAt(index);
    this.mainForm.updateValueAndValidity();
  }


  newCost(cost: FicaDocumentCost = null): FormGroup {

    let fg = new FormGroup({
      id: new FormControl(0),
      date: new FormControl(undefined, Validators.required),
      description: new FormControl(undefined, Validators.required),
      rate: new FormControl(undefined, [Validators.required, Validators.min(0.01)]),
      time: new FormControl(undefined, [Validators.required, Validators.min(0.01)]),
      ficaDocumentId: new FormControl(0)
    });

    if (cost) {
      fg.patchValue(cost);

      fg.patchValue({
        date: cost.date ? formatDate(cost.date, 'yyyy-MM-dd', 'en') : undefined
      });
    }
    return fg;
  }

  addCost(cost: FicaDocumentCost = null) {
    this.mainForm.controls.ficaDocumentCosts.push(this.newCost(cost));
    this.mainForm.updateValueAndValidity();
    return this.mainForm.controls.ficaDocumentCosts.length - 1;
  }

  removeCost(index: number) {
    this.mainForm.controls.ficaDocumentCosts.removeAt(index);
    this.mainForm.updateValueAndValidity();
  }

  submit() {

    let index = this.addCost();
    this.removeCost(index);

    console.log(this.apiCompanyId);
    this.mainForm.markAllAsTouched();

    if (!this.mainForm.valid) {
      this.alertService.error('Not all required fields have been completed please check the form.');
      return;
    }

    let document = this.mainForm.value as FicaDocument;
    document.id = this.currentDocumentId;
    document.companyId = this.apiCompanyId;

    this.spinner.startBackground();
    let func: Observable<{}>;

    if (this.currentDocumentId == 0) {
      func = this.documentsApi.AddFicaDocument(document);
      
    } else {
      func = this.documentsApi.UpdateFicaDocument(document);
    }

    func.subscribe(() => {
      this.spinner.stopBackground();
      this.alertService.success('Record succesfully added');
      this.router.navigateByUrl('/dashboard/documents/fica');
    }, error => {
      this.spinner.stopBackground();
      this.alertService.error(JSON.stringify(error));
    });
  
  
    
  }

  correspondenceForm: FormGroup;
  correspondenceFormSubmitted = false;

  sendCorrespondence(content: TemplateRef<any>) {
    this.correspondenceForm = new FormGroup({
      name: new FormControl(undefined, Validators.required),
      email: new FormControl(undefined, [Validators.required, Validators.email]),
      sender: new FormControl(undefined, Validators.required),
      documentFileData: new FormControl(undefined, Validators.required),
      documentCorrespondenceTypeId: new FormControl(undefined, Validators.required),
      attachments: new FormArray([])
    });

    this.correspondenceFormSubmitted = false;
    
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				
			},
			(reason) => {
				
			},
		);
  }

  addAttachement() {
    (this.correspondenceForm.controls.attachments as FormArray).push(new FormControl(undefined, Validators.required));
      
    this.correspondenceForm.updateValueAndValidity();
  }

  removeAttachement(index: number) {
    (this.correspondenceForm.controls.attachments as FormArray).removeAt(index);
    this.correspondenceForm.updateValueAndValidity();
  }

  submitCorrespondence(){
    this.correspondenceFormSubmitted = true;
    if (!this.correspondenceForm.valid){
      return;
    }

    this.showCorrespondence = false;

    let attachments: DocumentCorrespondenceAttachment[] = [];

    (this.correspondenceForm.controls.attachments as FormArray).controls.forEach(f => {
        let a: DocumentCorrespondenceAttachment = {
          documentFileData: f.value
        };
        attachments.push(a);
    });

    
    let model: FicaDocumentCorrespondence = {
      documentCorrespondenceTypeId: this.correspondenceForm.controls.documentCorrespondenceTypeId.value,
      ficaDocumentId: this.currentDocumentId,
      email: this.correspondenceForm.controls.email.value,
      name: this.correspondenceForm.controls.name.value,
      sender: this.correspondenceForm.controls.sender.value,
      documentFileData: this.correspondenceForm.controls.documentFileData.value,
      attachments: attachments
    };

    this.spinner.start();

    this.documentsApi.addFicaDocumentCorrespondence(model, this.apiCompanyId).subscribe(data => {
      this.spinner.stop();
      this.alertService.success('Correspondence succesfully submitted');
      this.modalService.dismissAll();
    }, error => {
      this.spinner.stop();
      this.alertService.error(JSON.stringify(error));
    })

  }

  correspondence: FicaDocumentCorrespondence[];
  showCorrespondence = false;
  viewCorrespondence(){
    if (this.showCorrespondence){
      this.showCorrespondence = false;
      return;
    }
    this.spinner.startBackground();
    this.documentsApi.getFicaDocumentCorrespondence(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
      this.showCorrespondence = true;
      this.correspondence = data;
      this.spinner.stopBackground();
    });
  }

  exportCorrespondence(){
    this.spinner.startBackground();
    this.documentsApi.exportFicaDocumentCorrespondence(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }

  hideCorrespondence(){
    this.showCorrespondence = false;
  }

  download(ev: Event, id: number) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.getFicaDocumentCorrespondenceFile(id, this.apiCompanyId).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  downloadAttachment(ev: Event, attachmentId: number, correspondenceId: number){
    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.getFicaDocumentCorrespondenceAttachmentFile(this.currentDocumentId, attachmentId, correspondenceId, this.apiCompanyId).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    });
  }

  downloadDocumentFile(ev: Event, file: string) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.GetFicaDocumentFile(this.currentDocumentId, this.apiCompanyId, file).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  downloadDocumentHistoryFile(ev: Event, id: number){
    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.GetFicaDocumentHistoryFile(this.currentDocumentId, this.apiCompanyId, id).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  print() {
    let popupWin = window.open('', '_blank', 'width=1080,height=595');
    if (!popupWin) {
      return;
    }
    popupWin.document.open();
    let printContents = document.getElementById("mainForm")?.innerHTML;

    let printHead = document.head.innerHTML;
    popupWin.document
      .write(`<html>
           ${printHead}
          <body onload="window.print();">${printContents}</body></html>`);
    popupWin.document.close();

  }
  
}
