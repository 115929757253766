import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OrganisationApiService } from '../../services/organisation-api.service';
import { CompanyApiService } from '../../services/company-api.service';
import { SpinnerService } from '../../services/spinner.service';
import { Company } from 'src/app/models/company';
import { Organisation } from 'src/app/models/organisation';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit {

  organisations: Organisation[];
  selectedOrganisation: number;
  savedOrganisation: number;

  companies: Company[];
  selectedCompany: number;
  savedCompany: number;

  subCompanies: Company[];
  selectedSubCompany: number;
  savedSubCompany: number;

  businessUnits: Company[];
  selectedBusinessUnit: number;
  savedBusinessUnit: number;

  areas: Company[];
  selectedArea: number;
  savedArea: number;

  categories: Company[];
  selectedCategory: number;
  savedCategory: number;

  superAdmin = false;
  orgAdmin = false;

  constructor(
    private organisationApi: OrganisationApiService,
    private companyApi: CompanyApiService,
    private spinner: SpinnerService,
    private token: TokenService) { }

  ngOnInit() {
    this.superAdmin = this.token.isSuperAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();

    if (this.superAdmin) {
      this.savedOrganisation = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
    }
    else {
      this.savedOrganisation = this.token.organisationId();
    }

    this.savedCompany = parseInt(localStorage.getItem('SELECTED_COMPANY'));
    this.savedSubCompany = parseInt(localStorage.getItem('SELECTED_SUBCOMPANY'));
    this.savedBusinessUnit = parseInt(localStorage.getItem('SELECTED_BUSINESSUNIT'));
    this.savedArea = parseInt(localStorage.getItem('SELECTED_AREA'));
    this.savedCategory = parseInt(localStorage.getItem('SELECTED_CATEGORY'));

    if (this.savedOrganisation == 0) { this.savedOrganisation = null; }
    if (this.savedCompany == 0) { this.savedCompany = null; }
    if (this.savedSubCompany == 0) { this.savedSubCompany = null; }
    if (this.savedBusinessUnit == 0) { this.savedBusinessUnit = null; }
    if (this.savedArea == 0) { this.savedArea = null; }
    if (this.savedCategory == 0) { this.savedCategory = null; }

    if (this.superAdmin) {
      this.spinner.startBackground();
      this.organisationApi.List().subscribe(data => {
        this.organisations = data;

        if (this.savedOrganisation) {
          this.selectedOrganisation = this.savedOrganisation;
          this.selectOrganisation({ id: this.savedOrganisation });
        }

        this.spinner.stopBackground();
      });
    }

    if (this.orgAdmin){
      this.selectedOrganisation = this.savedOrganisation;
      this.selectOrganisation({ id: this.savedOrganisation });
    }
  }

  @Output()
  selected: EventEmitter<any> = new EventEmitter();


  selectOrganisation(org: Organisation) {
    
    localStorage.removeItem('SELECTED_COMPANY');
    localStorage.removeItem('SELECTED_BUSINESSUNIT');
    localStorage.removeItem('SELECTED_SUBCOMPANY');
    localStorage.removeItem('SELECTED_BUSINESSUNIT');
    localStorage.removeItem('SELECTED_AREA');
    localStorage.removeItem('SELECTED_CATEGORY');

    this.selectedCompany = null;
    this.selectedSubCompany = null;
    this.selectedBusinessUnit = null;
    this.selectedArea = null;
    this.selectedCategory = null;


    this.selected.emit(undefined);
    if (this.selectedOrganisation) {
      localStorage.setItem('SELECTED_ORGANISATION', this.selectedOrganisation.toString());
      this.spinner.startBackground();
      this.companyApi.ListByOrganisationId(org.id).subscribe(data => {
        this.companies = data;

        if (this.savedCompany) {
          this.selectedCompany = this.savedCompany;
          this.selectCompany(this.companies.find(f => f.id == this.selectedCompany));
        }

        this.spinner.stopBackground();
      });
    }
    else {
      this.companies = null;
    };
  }

  selectCompany(event: Company) {
    localStorage.setItem('SELECTED_COMPANY', this.selectedCompany.toString());
    
    localStorage.removeItem('SELECTED_SUBCOMPANY');
    localStorage.removeItem('SELECTED_BUSINESSUNIT');
    localStorage.removeItem('SELECTED_AREA');
    localStorage.removeItem('SELECTED_CATEGORY');

    this.selectedSubCompany = null;
    this.selectedBusinessUnit = null;
    this.selectedArea = null;
    this.selectedCategory = null;
    
    
    this.spinner.startBackground();
    this.companyApi.ListByCompanyId(event.id).subscribe(data => {
      this.subCompanies = data;

      if (this.savedSubCompany) {
        this.selectedSubCompany = this.savedSubCompany;
        this.selectSubCompany(this.subCompanies.find(f => f.id == this.selectedSubCompany));
      }
      else {
        this.selected.emit(event);
      }

      this.spinner.stopBackground();
    });
   
  }

  selectSubCompany(event: Company) {
    localStorage.setItem('SELECTED_SUBCOMPANY', this.selectedSubCompany.toString());
    localStorage.removeItem('SELECTED_BUSINESSUNIT');
    localStorage.removeItem('SELECTED_CATEGORY');
    localStorage.removeItem('SELECTED_AREA');

    this.selectedBusinessUnit = null;
    this.selectedCategory = null;
    this.selectedArea = null;
    
    this.spinner.startBackground();
    this.companyApi.ListByCompanyId(event.id).subscribe(data => {
      this.businessUnits = data;
      if (this.savedBusinessUnit) {
        this.selectedBusinessUnit = this.savedBusinessUnit;
        this.selectBusinessUnit(this.businessUnits.find(f => f.id == this.selectedBusinessUnit));
      }
      else {
        this.selected.emit(event);
      }
      this.spinner.stopBackground();
    });
  }

  selectBusinessUnit(event: Company) {
    localStorage.setItem('SELECTED_BUSINESSUNIT', this.selectedBusinessUnit.toString());
    localStorage.removeItem('SELECTED_CATEGORY');
    localStorage.removeItem('SELECTED_AREA');

    this.selectedCategory = null;
    this.selectedArea = null;
    
    this.spinner.startBackground();
    this.companyApi.ListByCompanyId(event.id).subscribe(data => {
      this.areas = data;
      if (this.savedArea) {
        this.selectedArea = this.savedArea;
        this.selectArea(this.areas.find(f => f.id == this.selectedArea));
      }
      else {
        this.selected.emit(event);
      }
      this.spinner.stopBackground();
    });
  }

  selectArea(event: Company) {
    localStorage.setItem('SELECTED_AREA', this.selectedArea.toString());
    localStorage.removeItem('SELECTED_CATEGORY');

    this.selectedCategory = null;
    
    this.spinner.startBackground();
    this.companyApi.ListByCompanyId(event.id).subscribe(data => {
      this.categories = data;
      if (this.savedCategory) {
        this.selectedCategory = this.savedCategory;
        this.selectCategory(this.categories.find(f => f.id == this.selectedCategory));
      }
      else {
        this.selected.emit(event);
      }
      this.spinner.stopBackground();
    });
  }

  selectCategory(event: Company) {
    localStorage.setItem('SELECTED_CATEGORY', this.selectedCategory.toString());
    this.selected.emit(event);
  }

  clearOrganisation() {
    this.companies = null;
    this.selectedCompany = null;
    this.selectBusinessUnit = null;
    this.selected.emit(undefined);
  }

  clearCompany() {
    localStorage.removeItem('SELECTED_COMPANY');
    localStorage.removeItem('SELECTED_SUBCOMPANY');
    localStorage.removeItem('SELECTED_BUSINESSUNIT');
    localStorage.removeItem('SELECTED_AREA');
    localStorage.removeItem('SELECTED_CATEGORY');
    this.selectedCompany = null;
    this.selectedBusinessUnit = null;
    this.selected.emit(undefined);
  }

  clearSubCompany() {
    
    localStorage.removeItem('SELECTED_SUBCOMPANY');
    localStorage.removeItem('SELECTED_BUSINESSUNIT');
    localStorage.removeItem('SELECTED_AREA');
    localStorage.removeItem('SELECTED_CATEGORY');
    this.selectedBusinessUnit = null;
    this.selected.emit(this.companies.find(f => f.id == this.selectedCompany));
  }

  clearBusinessUnit() {
    
    localStorage.removeItem('SELECTED_BUSINESSUNIT');
    localStorage.removeItem('SELECTED_AREA');
    localStorage.removeItem('SELECTED_CATEGORY');
    this.selectedBusinessUnit = null;
    this.selected.emit(this.companies.find(f => f.id == this.selectedSubCompany));
  }

  clearArea() {
    
    localStorage.removeItem('SELECTED_AREA');
    localStorage.removeItem('SELECTED_CATEGORY');
    this.selectedArea = null;
    this.selected.emit(this.companies.find(f => f.id == this.selectedBusinessUnit));
  }

  clearCategory() {
    
    localStorage.removeItem('SELECTED_CATEGORY');
    this.selectedCategory = null;
    this.selected.emit(this.companies.find(f => f.id == this.selectedArea));
  }


  public clear() {
    localStorage.removeItem('SELECTED_COMPANY');
    localStorage.removeItem('SELECTED_BUSINESSUNIT');
    this.companies = null;
    this.businessUnits = null;
    this.selectedBusinessUnit = null;
    this.selectedCompany = null;
    this.selectedOrganisation = null;
    this.selected.emit(undefined);
  }
}
