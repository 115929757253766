import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { ContractDocument, ContractDocumentAgreementType, ContractDocumentCorrespondence, ContractDocumentReminder, ContractDocumentRequestType, ContractDocumentResult, ContractDocumentStatus, DocumentCorrespondenceType, DocumentFacility, DocumentRegion, DocumentRiskRating, DocumentTemplate, DocumentTemplateType, FicaDocument, FicaDocumentCorrespondence, FicaDocumentReminder, FicaDocumentRequestType, FicaDocumentResult, FicaDocumentStatus, MatterManagementDocument, MatterManagementDocumentAppealStatus, MatterManagementDocumentCorrespondence, MatterManagementDocumentReferral, MatterManagementDocumentReminder, MatterManagementDocumentRequestType, MatterManagementDocumentResult, MatterManagementDocumentStatus, PaiaDocument, PaiaDocumentAppealStatus, PaiaDocumentCorrespondence, PaiaDocumentReferral, PaiaDocumentReminder, PaiaDocumentResult, PaiaDocumentStatus, PermitDocument, PermitDocumentCorrespondence, PermitDocumentReminder, PermitDocumentRequestType, PermitDocumentResult, PermitDocumentStatus, PolicyDocument, PolicyDocumentCorrespondence, PolicyDocumentReminder, PolicyDocumentRequestType, PolicyDocumentResult, PolicyDocumentStatus, UpdateContractDocumentNotes, UpdateFicaDocumentNotes, UpdateMatterManagementDocumentNotes, UpdatePaiaDocumentNotes, UpdatePermitDocumentNotes, UpdatePolicyDocumentNotes } from '../models/documents';


@Injectable({
  providedIn: 'root'
})
export class DocumentsApiService extends ApiService<Document> {

  _controller: string = 'documents';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('documents', http, env);
  }

  public GetTemplateTypes() {
    let url = `api/${this._controller}/template-types`;
    return this.get<DocumentTemplateType[]>(url);
  }

  public GetTemplates(companyId: number, documentTemplateTypeid: number) {
    let url = `api/${this._controller}/templates?typeId=${documentTemplateTypeid}&?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    
    return this.get<DocumentTemplate[]>(url);
  }

  public GetRegions() {
    let url = `api/${this._controller}/regions`;
    return this.get<DocumentRegion[]>(url);
  }

  public GetFacilities(regionId?: number) {
    let url = `api/${this._controller}/facilities`;

    if (regionId) {
      url = `${url}?regionId=${regionId}`
    }
    return this.get<DocumentFacility[]>(url);
  }

  public GetDocumentCorrespondenceTypes(type: string) {
    let url = `api/${this._controller}/correspondence-types?type=${type}`;
    return this.get<DocumentCorrespondenceType[]>(url);
  }

  public GetRiskRatings() {
    let url = `api/${this._controller}/risk-ratings`;
    return this.get<DocumentRiskRating[]>(url);
  }

  /////////////////////////////////////////////////////////////////////
  //            PAIA
  /////////////////////////////////////////////////////////////////////

  public GetPaiaStatuses() {
    let url = `api/${this._controller}/paia/statuses`;
    return this.get<PaiaDocumentStatus[]>(url);
  }

  public GetPaiaAppealStatuses() {
    let url = `api/${this._controller}/paia/appeal-statuses`;
    return this.get<PaiaDocumentAppealStatus[]>(url);
  }

  public GetPaiaReferrals() {
    let url = `api/${this._controller}/paia/referrals`;
    return this.get<PaiaDocumentReferral[]>(url);
  }

  public GetPaiaDocuments(
    companyId: number = undefined, 
    paiaDocumentStatusId: number = undefined, 
    decision: boolean = undefined, 
    documentRegionId: number = undefined, 
    documentFacilityId: number = undefined, 
    appealStatusId: number = undefined,
    accessGranted: boolean = undefined,
    consent: boolean = undefined,
    newOrExisting: boolean = undefined,
    paiaDocumentReferralId: number = undefined,
    from: Date = undefined,
    to: Date = undefined,
    requestType: string = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {

    let url = `api/${this._controller}/paia/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (paiaDocumentStatusId != undefined) {
      url = `${url}&paiaDocumentStatusId=${paiaDocumentStatusId}`
    }

    if (decision != undefined) {
      url = `${url}&decision=${decision}`
    }

    if (documentRegionId != undefined) {
      url = `${url}&documentRegionId=${documentRegionId}`
    }

    if (documentFacilityId != undefined) {
      url = `${url}&documentFacilityId=${documentFacilityId}`
    }

    if (appealStatusId != undefined) {
      url = `${url}&appealStatusId=${appealStatusId}`
    }

    if (accessGranted != undefined) {
      url = `${url}&accessGranted=${accessGranted}`
    }

    if (consent != undefined) {
      url = `${url}&consent=${consent}`
    }

    if (newOrExisting != undefined) {
      url = `${url}&newOrExisting=${newOrExisting}`
    }

    if (paiaDocumentReferralId != undefined) {
      url = `${url}&paiaDocumentReferralId=${paiaDocumentReferralId}`
    }

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (requestType != undefined) {
      url = `${url}&requestType=${requestType}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.get<PaiaDocumentResult>(url);
  }

  public exportPaiaDocuments(companyId: number, 
    paiaDocumentStatusId: number = undefined, 
    decision: boolean = undefined, 
    documentRegionId: number = undefined, 
    documentFacilityId: number = undefined, 
    appealStatusId: number = undefined,
    accessGranted: boolean = undefined,
    consent: boolean = undefined,
    newOrExisting: boolean = undefined,
    paiaDocumentReferralId: number = undefined,
    from: Date = undefined,
    to: Date = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/paia/export/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (paiaDocumentStatusId != undefined) {
      url = `${url}&paiaDocumentStatusId=${paiaDocumentStatusId}`
    }

    if (decision != undefined) {
      url = `${url}&decision=${decision}`
    }

    if (documentRegionId != undefined) {
      url = `${url}&documentRegionId=${documentRegionId}`
    }

    if (documentFacilityId != undefined) {
      url = `${url}&documentFacilityId=${documentFacilityId}`
    }

    if (appealStatusId != undefined) {
      url = `${url}&appealStatusId=${appealStatusId}`
    }

    if (accessGranted != undefined) {
      url = `${url}&accessGranted=${accessGranted}`
    }

    if (consent != undefined) {
      url = `${url}&consent=${consent}`
    }

    if (newOrExisting != undefined) {
      url = `${url}&newOrExisting=${newOrExisting}`
    }

    if (paiaDocumentReferralId != undefined) {
      url = `${url}&paiaDocumentReferralId=${paiaDocumentReferralId}`
    }

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetPaiaDocument(id: number, companyId: number) {
    let url = `api/${this._controller}/paia/${id}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<PaiaDocument>(url);
  }

  public AddPaiaDocument(document: PaiaDocument) {
    let url = `api/${this._controller}/paia`;
    return this.post<PaiaDocument, {}>(url, document);
  }

  public UpdatePaiaDocument(document: PaiaDocument) {
    let url = `api/${this._controller}/paia`;
    return this.put<PaiaDocument, {}>(url, document);
  }

  public GetPaiaDocumentFile(id: number, companyId: number, file: string) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/paia/${id}/${file}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetPaiaDocumentHistoryFile(id: number, companyId: number, historyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/paia/${id}/history/${historyId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  

  public getPaiaDocumentCorrespondence(id: number, companyId: number) {
    let url = `api/${this._controller}/paia/${id}/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<PaiaDocumentCorrespondence[]>(url);
  }

  public exportPaiaDocumentCorrespondence(id: number, companyId: number) {

    let url = `${this.env.getApiUrl()}/api/${this._controller}/paia/${id}/correspondence/export?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    //return this.get<PaiaDocumentCorrespondence[]>(url);

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public addPaiaDocumentCorrespondence(data: PaiaDocumentCorrespondence, companyId: number) {
    let url = `api/${this._controller}/paia/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<PaiaDocumentCorrespondence, {}>(url, data);
  }

  public getPaiaDocumentCorrespondenceFile(id: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/paia/${id}/correspondence/download?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getPaiaDocumentCorrespondenceAttachmentFile(id: number, attachmentId: number, correspondenceId: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/paia/${id}/correspondence/${correspondenceId}/attachment/${attachmentId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public updatePaiaDocumentNotes(data: UpdatePaiaDocumentNotes, companyId: number) {
    let url = `api/${this._controller}/paia/notes?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<UpdatePaiaDocumentNotes, {}>(url, data);
  }

  public getPaiaDocumentReminders(id: number, companyId: number) {
    let url = `api/${this._controller}/paia/${id}/reminders?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<PaiaDocumentReminder[]>(url);
  }

  public addPaiaDocumentReminder(data: PaiaDocumentReminder, companyId: number) {
    let url = `api/${this._controller}/paia/reminder?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<PaiaDocumentReminder, {}>(url, data);
  }



  /////////////////////////////////////////////////////////////////////
  //            PERMITS
  /////////////////////////////////////////////////////////////////////

  public GetPermitStatuses() {
    let url = `api/${this._controller}/permits/statuses`;
    return this.get<PermitDocumentStatus[]>(url);
  }

  
  public GetPermitRequestTypes() {
    let url = `api/${this._controller}/permits/request-types`;
    return this.get<PermitDocumentRequestType[]>(url);
  }

  public GetPermitDocuments(companyId: number, permitDocumentStatusId: number = undefined, granted: boolean = undefined, permitDocumentRequestTypeId: number = undefined, riskRatingId: number = undefined, disbursementValueMin: number = undefined,
    disbursementValueMax: number = undefined,
    anticipatedValueMin: number = undefined,
    anticipatedValueMax: number = undefined,
    timeAndAttendanceTimeMin: number = undefined,
    timeAndAttendanceTimeMax: number = undefined,
    timeAndAttendanceCostMin: number = undefined,
    timeAndAttendanceCostMax: number = undefined,
    perPage: number = 5, currentPage: number = 1) {
    let url = `api/${this._controller}/permits/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (permitDocumentStatusId != undefined) {
      url = `${url}&permitDocumentStatusId=${permitDocumentStatusId}`
    }

    if (granted != undefined) {
      url = `${url}&granted=${granted}`
    }

    if (permitDocumentRequestTypeId != undefined) {
      url = `${url}&permitDocumentRequestTypeId=${permitDocumentRequestTypeId}`
    }

    if (riskRatingId != undefined) {
      url = `${url}&riskRatingId=${riskRatingId}`
    }

    if (disbursementValueMin != undefined) {
      url = `${url}&disbursementValueMin=${disbursementValueMin}`
    }
    if (disbursementValueMax != undefined) {
      url = `${url}&disbursementValueMax=${disbursementValueMax}`
    }
    if (anticipatedValueMin != undefined) {
      url = `${url}&anticipatedValueMin=${anticipatedValueMin}`
    }
    if (anticipatedValueMax != undefined) {
      url = `${url}&anticipatedValueMax=${anticipatedValueMax}`
    }
    if (timeAndAttendanceTimeMin != undefined) {
      url = `${url}&timeAndAttendanceTimeMin=${timeAndAttendanceTimeMin}`
    }
    if (timeAndAttendanceTimeMax != undefined) {
      url = `${url}&timeAndAttendanceTimeMax=${timeAndAttendanceTimeMax}`
    }
    if (timeAndAttendanceCostMin != undefined) {
      url = `${url}&timeAndAttendanceCostMin=${timeAndAttendanceCostMin}`
    }
    if (timeAndAttendanceCostMax != undefined) {
      url = `${url}&timeAndAttendanceCostMax=${timeAndAttendanceCostMax}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.get<PermitDocumentResult>(url);
  }

  public ExportPermitDocuments(companyId: number, permitDocumentStatusId: number = undefined, granted: boolean = undefined, permitDocumentRequestTypeId: number = undefined, riskRatingId: number = undefined, 
    disbursementValueMin: number = undefined,
    disbursementValueMax: number = undefined,
    anticipatedValueMin: number = undefined,
    anticipatedValueMax: number = undefined,
    timeAndAttendanceTimeMin: number = undefined,
    timeAndAttendanceTimeMax: number = undefined,
    timeAndAttendanceCostMin: number = undefined,
    timeAndAttendanceCostMax: number = undefined,
    perPage: number = 5, currentPage: number = 1) {
    
    let url = `${this.env.getApiUrl()}/api/${this._controller}/permits/export/?`;
    
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (permitDocumentStatusId != undefined) {
      url = `${url}&permitDocumentStatusId=${permitDocumentStatusId}`
    }

    if (granted != undefined) {
      url = `${url}&granted=${granted}`
    }

    if (permitDocumentRequestTypeId != undefined) {
      url = `${url}&permitDocumentRequestTypeId=${permitDocumentRequestTypeId}`
    }

    if (riskRatingId != undefined) {
      url = `${url}&riskRatingId=${riskRatingId}`
    }

    if (disbursementValueMin != undefined) {
      url = `${url}&disbursementValueMin=${disbursementValueMin}`
    }
    if (disbursementValueMax != undefined) {
      url = `${url}&disbursementValueMax=${disbursementValueMax}`
    }
    if (anticipatedValueMin != undefined) {
      url = `${url}&anticipatedValueMin=${anticipatedValueMin}`
    }
    if (anticipatedValueMax != undefined) {
      url = `${url}&anticipatedValueMax=${anticipatedValueMax}`
    }
    if (timeAndAttendanceTimeMin != undefined) {
      url = `${url}&timeAndAttendanceTimeMin=${timeAndAttendanceTimeMin}`
    }
    if (timeAndAttendanceTimeMax != undefined) {
      url = `${url}&timeAndAttendanceTimeMax=${timeAndAttendanceTimeMax}`
    }
    if (timeAndAttendanceCostMin != undefined) {
      url = `${url}&timeAndAttendanceCostMin=${timeAndAttendanceCostMin}`
    }
    if (timeAndAttendanceCostMax != undefined) {
      url = `${url}&timeAndAttendanceCostMax=${timeAndAttendanceCostMax}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetPermitDocument(id: number, companyId: number) {
    let url = `api/${this._controller}/permits/${id}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<PermitDocument>(url);
  }

  public AddPermitDocument(document: PermitDocument) {
    let url = `api/${this._controller}/permits`;
    return this.post<PermitDocument, {}>(url, document);
  }

  public UpdatePermitDocument(document: PermitDocument) {
    let url = `api/${this._controller}/permits`;
    return this.put<PermitDocument, {}>(url, document);
  }

  public GetPermitDocumentFile(id: number, companyId: number, file: string) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/permits/${id}/${file}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }
  public GetPermitDocumentHistoryFile(id: number, companyId: number, historyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/permits/${id}/history/${historyId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getPermitDocumentCorrespondence(id: number, companyId: number) {
    let url = `api/${this._controller}/permits/${id}/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<PermitDocumentCorrespondence[]>(url);
  }

  public exportPermitDocumentCorrespondence(id: number, companyId: number) {

    let url = `${this.env.getApiUrl()}/api/${this._controller}/permits/${id}/correspondence/export?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    //return this.get<FicaDocumentCorrespondence[]>(url);

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public addPermitDocumentCorrespondence(data: PermitDocumentCorrespondence, companyId: number) {
    let url = `api/${this._controller}/permits/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<PermitDocumentCorrespondence, {}>(url, data);
  }

  public getPermitDocumentCorrespondenceFile(id: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/permits/${id}/correspondence/download?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getPermitDocumentCorrespondenceAttachmentFile(id: number, attachmentId: number, correspondenceId: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/permits/${id}/correspondence/${correspondenceId}/attachment/${attachmentId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }


  public updatePermitDocumentNotes(data: UpdatePermitDocumentNotes, companyId: number) {
    let url = `api/${this._controller}/permits/notes?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<UpdatePermitDocumentNotes, {}>(url, data);
  }

  public getPermitDocumentReminders(id: number, companyId: number) {
    let url = `api/${this._controller}/permits/${id}/reminders?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<PermitDocumentReminder[]>(url);
  }

  public addPermitDocumentReminder(data: PermitDocumentReminder, companyId: number) {
    let url = `api/${this._controller}/permits/reminder?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<PermitDocumentReminder, {}>(url, data);
  }

    ///////////////////////////////////////////////////////////
  //            POLICIES
  /////////////////////////////////////////////////////////////////////

  public GetPolicyStatuses() {
    let url = `api/${this._controller}/policies/statuses`;
    return this.get<PolicyDocumentStatus[]>(url);
  }

  public GetPolicyRequestTypes() {
    let url = `api/${this._controller}/policies/request-types`;
    return this.get<PolicyDocumentRequestType[]>(url);
  }

  public GetPolicyDocuments(companyId: number, policyDocumentStatusId: number = undefined, granted: boolean = undefined, policyDocumentRequestTypeId: number = undefined, riskRatingId: number = undefined, disbursementValueMin: number = undefined,
    disbursementValueMax: number = undefined,
    anticipatedValueMin: number = undefined,
    anticipatedValueMax: number = undefined,
    timeAndAttendanceTimeMin: number = undefined,
    timeAndAttendanceTimeMax: number = undefined,
    timeAndAttendanceCostMin: number = undefined,
    timeAndAttendanceCostMax: number = undefined,
    perPage: number = 5, currentPage: number = 1) {
    let url = `api/${this._controller}/policies/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (policyDocumentStatusId != undefined) {
      url = `${url}&policyDocumentStatusId=${policyDocumentStatusId}`
    }

    if (granted != undefined) {
      url = `${url}&granted=${granted}`
    }

    if (policyDocumentRequestTypeId != undefined) {
      url = `${url}&policyDocumentRequestTypeId=${policyDocumentRequestTypeId}`
    }

    if (riskRatingId != undefined) {
      url = `${url}&riskRatingId=${riskRatingId}`
    }

    if (disbursementValueMin != undefined) {
      url = `${url}&disbursementValueMin=${disbursementValueMin}`
    }
    if (disbursementValueMax != undefined) {
      url = `${url}&disbursementValueMax=${disbursementValueMax}`
    }
    if (anticipatedValueMin != undefined) {
      url = `${url}&anticipatedValueMin=${anticipatedValueMin}`
    }
    if (anticipatedValueMax != undefined) {
      url = `${url}&anticipatedValueMax=${anticipatedValueMax}`
    }
    if (timeAndAttendanceTimeMin != undefined) {
      url = `${url}&timeAndAttendanceTimeMin=${timeAndAttendanceTimeMin}`
    }
    if (timeAndAttendanceTimeMax != undefined) {
      url = `${url}&timeAndAttendanceTimeMax=${timeAndAttendanceTimeMax}`
    }
    if (timeAndAttendanceCostMin != undefined) {
      url = `${url}&timeAndAttendanceCostMin=${timeAndAttendanceCostMin}`
    }
    if (timeAndAttendanceCostMax != undefined) {
      url = `${url}&timeAndAttendanceCostMax=${timeAndAttendanceCostMax}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.get<PolicyDocumentResult>(url);
  }

  public ExportPolicyDocuments(companyId: number, policyDocumentStatusId: number = undefined, granted: boolean = undefined, policyDocumentRequestTypeId: number = undefined, riskRatingId: number = undefined, disbursementValueMin: number = undefined,
    disbursementValueMax: number = undefined,
    anticipatedValueMin: number = undefined,
    anticipatedValueMax: number = undefined,
    timeAndAttendanceTimeMin: number = undefined,
    timeAndAttendanceTimeMax: number = undefined,
    timeAndAttendanceCostMin: number = undefined,
    timeAndAttendanceCostMax: number = undefined,
    perPage: number = 5, currentPage: number = 1) {
    
    let url = `${this.env.getApiUrl()}/api/${this._controller}/policies/export/?`;
    
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (policyDocumentStatusId != undefined) {
      url = `${url}&policyDocumentStatusId=${policyDocumentStatusId}`
    }

    if (granted != undefined) {
      url = `${url}&granted=${granted}`
    }

    if (policyDocumentRequestTypeId != undefined) {
      url = `${url}&policyDocumentRequestTypeId=${policyDocumentRequestTypeId}`
    }

    if (riskRatingId != undefined) {
      url = `${url}&riskRatingId=${riskRatingId}`
    }

    if (disbursementValueMin != undefined) {
      url = `${url}&disbursementValueMin=${disbursementValueMin}`
    }
    if (disbursementValueMax != undefined) {
      url = `${url}&disbursementValueMax=${disbursementValueMax}`
    }
    if (anticipatedValueMin != undefined) {
      url = `${url}&anticipatedValueMin=${anticipatedValueMin}`
    }
    if (anticipatedValueMax != undefined) {
      url = `${url}&anticipatedValueMax=${anticipatedValueMax}`
    }
    if (timeAndAttendanceTimeMin != undefined) {
      url = `${url}&timeAndAttendanceTimeMin=${timeAndAttendanceTimeMin}`
    }
    if (timeAndAttendanceTimeMax != undefined) {
      url = `${url}&timeAndAttendanceTimeMax=${timeAndAttendanceTimeMax}`
    }
    if (timeAndAttendanceCostMin != undefined) {
      url = `${url}&timeAndAttendanceCostMin=${timeAndAttendanceCostMin}`
    }
    if (timeAndAttendanceCostMax != undefined) {
      url = `${url}&timeAndAttendanceCostMax=${timeAndAttendanceCostMax}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetPolicyDocument(id: number, companyId: number) {
    let url = `api/${this._controller}/policies/${id}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<PolicyDocument>(url);
  }

  public AddPolicyDocument(document: PolicyDocument) {
    let url = `api/${this._controller}/policies`;
    return this.post<PolicyDocument, {}>(url, document);
  }

  public UpdatePolicyDocument(document: PolicyDocument) {
    let url = `api/${this._controller}/policies`;
    return this.put<PolicyDocument, {}>(url, document);
  }

  public GetPolicyDocumentFile(id: number, companyId: number, file: string) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/policies/${id}/${file}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }
  public GetPaiaPolicyHistoryFile(id: number, companyId: number, historyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/policies/${id}/history/${historyId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getPolicyDocumentCorrespondence(id: number, companyId: number) {
    let url = `api/${this._controller}/policies/${id}/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<PolicyDocumentCorrespondence[]>(url);
  }

  public exportPolicyDocumentCorrespondence(id: number, companyId: number) {

    let url = `${this.env.getApiUrl()}/api/${this._controller}/policies/${id}/correspondence/export?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    //return this.get<FicaDocumentCorrespondence[]>(url);

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public addPolicyDocumentCorrespondence(data: PolicyDocumentCorrespondence, companyId: number) {
    let url = `api/${this._controller}/policies/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<PolicyDocumentCorrespondence, {}>(url, data);
  }

  public getPolicyDocumentCorrespondenceFile(id: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/policies/${id}/correspondence/download?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getPolicyDocumentCorrespondenceAttachmentFile(id: number, attachmentId: number, correspondenceId: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/policies/${id}/correspondence/${correspondenceId}/attachment/${attachmentId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }


  public updatePolicyDocumentNotes(data: UpdatePolicyDocumentNotes, companyId: number) {
    let url = `api/${this._controller}/policies/notes?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<UpdatePolicyDocumentNotes, {}>(url, data);
  }

  public getPolicyDocumentReminders(id: number, companyId: number) {
    let url = `api/${this._controller}/policies/${id}/reminders?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<PolicyDocumentReminder[]>(url);
  }

  public addPolicyDocumentReminder(data: PolicyDocumentReminder, companyId: number) {
    let url = `api/${this._controller}/policies/reminder?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<PolicyDocumentReminder, {}>(url, data);
  }

  
  /////////////////////////////////////////////////////////////////////
  //            CONTRACTS
  /////////////////////////////////////////////////////////////////////


  public GetContractStatuses() {
    let url = `api/${this._controller}/contracts/statuses`;
    return this.get<ContractDocumentStatus[]>(url);
  }

  public GetContractAgreementTypes() {
    let url = `api/${this._controller}/contracts/agreement-types`;
    return this.get<ContractDocumentAgreementType[]>(url);
  }

  public GetContractRequestTypes() {
    let url = `api/${this._controller}/contracts/request-types`;
    return this.get<ContractDocumentRequestType[]>(url);
  }

  public GetContractDocuments(
    companyId: number, 
    contractDocumentStatusId: number = undefined, 
    from: Date = undefined,
    to: Date = undefined, 
    contractDocumentRequestTypeId: number = undefined, 
    riskRatingId: number = undefined, 
    disbursementValueMin: number = undefined,
    disbursementValueMax: number = undefined,
    anticipatedValueMin: number = undefined,
    anticipatedValueMax: number = undefined,
    timeAndAttendanceTimeMin: number = undefined,
    timeAndAttendanceTimeMax: number = undefined,
    timeAndAttendanceCostMin: number = undefined,
    timeAndAttendanceCostMax: number = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {
    let url = `api/${this._controller}/contracts/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (contractDocumentStatusId != undefined) {
      url = `${url}&contractDocumentStatusId=${contractDocumentStatusId}`
    }

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (contractDocumentRequestTypeId != undefined) {
      url = `${url}&contractDocumentRequestTypeId=${contractDocumentRequestTypeId}`
    }

    if (riskRatingId != undefined) {
      url = `${url}&riskRatingId=${riskRatingId}`
    }

    if (disbursementValueMin != undefined) {
      url = `${url}&disbursementValueMin=${disbursementValueMin}`
    }
    if (disbursementValueMax != undefined) {
      url = `${url}&disbursementValueMax=${disbursementValueMax}`
    }
    if (anticipatedValueMin != undefined) {
      url = `${url}&anticipatedValueMin=${anticipatedValueMin}`
    }
    if (anticipatedValueMax != undefined) {
      url = `${url}&anticipatedValueMax=${anticipatedValueMax}`
    }
    if (timeAndAttendanceTimeMin != undefined) {
      url = `${url}&timeAndAttendanceTimeMin=${timeAndAttendanceTimeMin}`
    }
    if (timeAndAttendanceTimeMax != undefined) {
      url = `${url}&timeAndAttendanceTimeMax=${timeAndAttendanceTimeMax}`
    }
    if (timeAndAttendanceCostMin != undefined) {
      url = `${url}&timeAndAttendanceCostMin=${timeAndAttendanceCostMin}`
    }
    if (timeAndAttendanceCostMax != undefined) {
      url = `${url}&timeAndAttendanceCostMax=${timeAndAttendanceCostMax}`
    }



    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.get<ContractDocumentResult>(url);
  }

  public ExportContractDocuments(companyId: number, 
    contractDocumentStatusId: number = undefined, 
    from: Date = undefined,
    to: Date = undefined, 
    contractDocumentRequestTypeId: number = undefined, 
    riskRatingId: number = undefined, 
    disbursementValueMin: number = undefined,
    disbursementValueMax: number = undefined,
    anticipatedValueMin: number = undefined,
    anticipatedValueMax: number = undefined,
    timeAndAttendanceTimeMin: number = undefined,
    timeAndAttendanceTimeMax: number = undefined,
    timeAndAttendanceCostMin: number = undefined,
    timeAndAttendanceCostMax: number = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/contracts/export/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (contractDocumentStatusId != undefined) {
      url = `${url}&contractDocumentStatusId=${contractDocumentStatusId}`
    }

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (contractDocumentRequestTypeId != undefined) {
      url = `${url}&contractDocumentRequestTypeId=${contractDocumentRequestTypeId}`
    }

    if (riskRatingId != undefined) {
      url = `${url}&riskRatingId=${riskRatingId}`
    }

    if (disbursementValueMin != undefined) {
      url = `${url}&disbursementValueMin=${disbursementValueMin}`
    }
    if (disbursementValueMax != undefined) {
      url = `${url}&disbursementValueMax=${disbursementValueMax}`
    }
    if (anticipatedValueMin != undefined) {
      url = `${url}&anticipatedValueMin=${anticipatedValueMin}`
    }
    if (anticipatedValueMax != undefined) {
      url = `${url}&anticipatedValueMax=${anticipatedValueMax}`
    }
    if (timeAndAttendanceTimeMin != undefined) {
      url = `${url}&timeAndAttendanceTimeMin=${timeAndAttendanceTimeMin}`
    }
    if (timeAndAttendanceTimeMax != undefined) {
      url = `${url}&timeAndAttendanceTimeMax=${timeAndAttendanceTimeMax}`
    }
    if (timeAndAttendanceCostMin != undefined) {
      url = `${url}&timeAndAttendanceCostMin=${timeAndAttendanceCostMin}`
    }
    if (timeAndAttendanceCostMax != undefined) {
      url = `${url}&timeAndAttendanceCostMax=${timeAndAttendanceCostMax}`
    }


    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetContractDocument(id: number, companyId: number) {
    let url = `api/${this._controller}/contracts/${id}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<ContractDocument>(url);
  }

  public AddContractDocument(document: ContractDocument) {
    let url = `api/${this._controller}/contracts`;
    return this.post<ContractDocument, {}>(url, document);
  }

  public UpdateContractDocument(document: ContractDocument) {
    let url = `api/${this._controller}/contracts`;
    return this.put<ContractDocument, {}>(url, document);
  }

  public GetContractDocumentFile(id: number, companyId: number, file: string) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/contracts/${id}/${file}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }
  public GetContractDocumentHistoryFile(id: number, companyId: number, historyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/contracts/${id}/history/${historyId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getContractDocumentCorrespondence(id: number, companyId: number) {
    let url = `api/${this._controller}/contracts/${id}/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<ContractDocumentCorrespondence[]>(url);
  }

  public exportContractDocumentCorrespondence(id: number, companyId: number) {

    let url = `${this.env.getApiUrl()}/api/${this._controller}/contracts/${id}/correspondence/export?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    //return this.get<FicaDocumentCorrespondence[]>(url);

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public addContractDocumentCorrespondence(data: ContractDocumentCorrespondence, companyId: number) {
    let url = `api/${this._controller}/contracts/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<ContractDocumentCorrespondence, {}>(url, data);
  }

  public getContractDocumentCorrespondenceFile(id: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/contracts/${id}/correspondence/download?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getContractDocumentCorrespondenceAttachmentFile(id: number, attachmentId: number, correspondenceId: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/contracts/${id}/correspondence/${correspondenceId}/attachment/${attachmentId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public updateContractDocumentNotes(data: UpdateContractDocumentNotes, companyId: number) {
    let url = `api/${this._controller}/contracts/notes?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<UpdateContractDocumentNotes, {}>(url, data);
  }

  public getContractDocumentReminders(id: number, companyId: number) {
    let url = `api/${this._controller}/contracts/${id}/reminders?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<ContractDocumentReminder[]>(url);
  }

  public addContractDocumentReminder(data: ContractDocumentReminder, companyId: number) {
    let url = `api/${this._controller}/contracts/reminder?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<ContractDocumentReminder, {}>(url, data);
  }


/////////////////////////////////////////////////////////////////////
  //            FICA
  /////////////////////////////////////////////////////////////////////

  public GetFicaStatuses() {
    let url = `api/${this._controller}/fica/statuses`;
    return this.get<FicaDocumentStatus[]>(url);
  }

  public GetFicaRequestTypes() {
    let url = `api/${this._controller}/fica/request-types`;
    return this.get<FicaDocumentRequestType[]>(url);
  }

  public GetFicaDocuments(
    companyId: number = undefined, 
    ficaDocumentStatusId: number = undefined, 
    decision: boolean = undefined, 
    documentRegionId: number = undefined, 
    documentFacilityId: number = undefined, 
    from: Date = undefined,
    to: Date = undefined,
    filterFicaDocumentRequestTypeId: number = undefined, 
    riskRatingId: number = undefined, 
    disbursementValueMin: number = undefined,
    disbursementValueMax: number = undefined,
    anticipatedValueMin: number = undefined,
    anticipatedValueMax: number = undefined,
    timeAndAttendanceTimeMin: number = undefined,
    timeAndAttendanceTimeMax: number = undefined,
    timeAndAttendanceCostMin: number = undefined,
    timeAndAttendanceCostMax: number = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {

    let url = `api/${this._controller}/fica/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (ficaDocumentStatusId != undefined) {
      url = `${url}&ficaDocumentStatusId=${ficaDocumentStatusId}`
    }

    if (decision != undefined) {
      url = `${url}&decision=${decision}`
    }

    if (documentRegionId != undefined) {
      url = `${url}&documentRegionId=${documentRegionId}`
    }

    if (documentFacilityId != undefined) {
      url = `${url}&documentFacilityId=${documentFacilityId}`
    }

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (filterFicaDocumentRequestTypeId != undefined) {
      url = `${url}&ficaDocumentRequestTypeId=${filterFicaDocumentRequestTypeId}`
    }

    if (riskRatingId != undefined) {
      url = `${url}&riskRatingId=${riskRatingId}`
    }

    if (disbursementValueMin != undefined) {
      url = `${url}&disbursementValueMin=${disbursementValueMin}`
    }
    if (disbursementValueMax != undefined) {
      url = `${url}&disbursementValueMax=${disbursementValueMax}`
    }
    if (anticipatedValueMin != undefined) {
      url = `${url}&anticipatedValueMin=${anticipatedValueMin}`
    }
    if (anticipatedValueMax != undefined) {
      url = `${url}&anticipatedValueMax=${anticipatedValueMax}`
    }
    if (timeAndAttendanceTimeMin != undefined) {
      url = `${url}&timeAndAttendanceTimeMin=${timeAndAttendanceTimeMin}`
    }
    if (timeAndAttendanceTimeMax != undefined) {
      url = `${url}&timeAndAttendanceTimeMax=${timeAndAttendanceTimeMax}`
    }
    if (timeAndAttendanceCostMin != undefined) {
      url = `${url}&timeAndAttendanceCostMin=${timeAndAttendanceCostMin}`
    }
    if (timeAndAttendanceCostMax != undefined) {
      url = `${url}&timeAndAttendanceCostMax=${timeAndAttendanceCostMax}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.get<FicaDocumentResult>(url);
  }

  public exportFicaDocuments(companyId: number, 
    ficaDocumentStatusId: number = undefined, 
    decision: boolean = undefined, 
    documentRegionId: number = undefined, 
    documentFacilityId: number = undefined, 
    from: Date = undefined,
    to: Date = undefined,
    filterFicaDocumentRequestTypeId: number = undefined, 
    riskRatingId: number = undefined, 
    disbursementValueMin: number = undefined,
    disbursementValueMax: number = undefined,
    anticipatedValueMin: number = undefined,
    anticipatedValueMax: number = undefined,
    timeAndAttendanceTimeMin: number = undefined,
    timeAndAttendanceTimeMax: number = undefined,
    timeAndAttendanceCostMin: number = undefined,
    timeAndAttendanceCostMax: number = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/fica/export/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (ficaDocumentStatusId != undefined) {
      url = `${url}&ficaDocumentStatusId=${ficaDocumentStatusId}`
    }

    if (decision != undefined) {
      url = `${url}&decision=${decision}`
    }

    if (documentRegionId != undefined) {
      url = `${url}&documentRegionId=${documentRegionId}`
    }

    if (documentFacilityId != undefined) {
      url = `${url}&documentFacilityId=${documentFacilityId}`
    }

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (filterFicaDocumentRequestTypeId != undefined) {
      url = `${url}&ficaDocumentRequestTypeId=${filterFicaDocumentRequestTypeId}`
    }

    if (riskRatingId != undefined) {
      url = `${url}&riskRatingId=${riskRatingId}`
    }

    if (disbursementValueMin != undefined) {
      url = `${url}&disbursementValueMin=${disbursementValueMin}`
    }
    if (disbursementValueMax != undefined) {
      url = `${url}&disbursementValueMax=${disbursementValueMax}`
    }
    if (anticipatedValueMin != undefined) {
      url = `${url}&anticipatedValueMin=${anticipatedValueMin}`
    }
    if (anticipatedValueMax != undefined) {
      url = `${url}&anticipatedValueMax=${anticipatedValueMax}`
    }
    if (timeAndAttendanceTimeMin != undefined) {
      url = `${url}&timeAndAttendanceTimeMin=${timeAndAttendanceTimeMin}`
    }
    if (timeAndAttendanceTimeMax != undefined) {
      url = `${url}&timeAndAttendanceTimeMax=${timeAndAttendanceTimeMax}`
    }
    if (timeAndAttendanceCostMin != undefined) {
      url = `${url}&timeAndAttendanceCostMin=${timeAndAttendanceCostMin}`
    }
    if (timeAndAttendanceCostMax != undefined) {
      url = `${url}&timeAndAttendanceCostMax=${timeAndAttendanceCostMax}`
    }


    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetFicaDocument(id: number, companyId: number) {
    let url = `api/${this._controller}/fica/${id}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<FicaDocument>(url);
  }

  public AddFicaDocument(document: FicaDocument) {
    let url = `api/${this._controller}/fica`;
    return this.post<FicaDocument, {}>(url, document);
  }

  public UpdateFicaDocument(document: FicaDocument) {
    let url = `api/${this._controller}/fica`;
    return this.put<FicaDocument, {}>(url, document);
  }

  public GetFicaDocumentFile(id: number, companyId: number, file: string) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/fica/${id}/${file}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetFicaDocumentHistoryFile(id: number, companyId: number, historyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/fica/${id}/history/${historyId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  

  public getFicaDocumentCorrespondence(id: number, companyId: number) {
    let url = `api/${this._controller}/fica/${id}/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<FicaDocumentCorrespondence[]>(url);
  }

  public exportFicaDocumentCorrespondence(id: number, companyId: number) {

    let url = `${this.env.getApiUrl()}/api/${this._controller}/fica/${id}/correspondence/export?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    //return this.get<FicaDocumentCorrespondence[]>(url);

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public addFicaDocumentCorrespondence(data: FicaDocumentCorrespondence, companyId: number) {
    let url = `api/${this._controller}/fica/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<FicaDocumentCorrespondence, {}>(url, data);
  }

  public getFicaDocumentCorrespondenceFile(id: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/fica/${id}/correspondence/download?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getFicaDocumentCorrespondenceAttachmentFile(id: number, attachmentId: number, correspondenceId: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/fica/${id}/correspondence/${correspondenceId}/attachment/${attachmentId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public updateFicaDocumentNotes(data: UpdateFicaDocumentNotes, companyId: number) {
    let url = `api/${this._controller}/fica/notes?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<UpdateFicaDocumentNotes, {}>(url, data);
  }

  public getFicaDocumentReminders(id: number, companyId: number) {
    let url = `api/${this._controller}/fica/${id}/reminders?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<FicaDocumentReminder[]>(url);
  }

  public addFicaDocumentReminder(data: FicaDocumentReminder, companyId: number) {
    let url = `api/${this._controller}/fica/reminder?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<FicaDocumentReminder, {}>(url, data);
  }



    /////////////////////////////////////////////////////////////////////
  //            MATTER MANAGEMENT
  /////////////////////////////////////////////////////////////////////

  public GetMatterManagementStatuses() {
    let url = `api/${this._controller}/matter-management/statuses`;
    return this.get<MatterManagementDocumentStatus[]>(url);
  }

  public GetMatterManagementAppealStatuses() {
    let url = `api/${this._controller}/matter-management/appeal-statuses`;
    return this.get<MatterManagementDocumentAppealStatus[]>(url);
  }

  public GetMatterManagementRequestTypes() {
    let url = `api/${this._controller}/matter-management/request-types`;
    return this.get<MatterManagementDocumentRequestType[]>(url);
  }

  

  public GetMatterManagementReferrals() {
    let url = `api/${this._controller}/matter-management/referrals`;
    return this.get<MatterManagementDocumentReferral[]>(url);
  }

  public GetMatterManagementDocuments(
    companyId: number = undefined, 
    matterManagementDocumentStatusId: number = undefined, 
    decision: boolean = undefined, 
    documentRegionId: number = undefined, 
    documentFacilityId: number = undefined, 
    appealStatusId: number = undefined,
    matterManagementDocumentReferralId: number = undefined,
    from: Date = undefined,
    to: Date = undefined,
    matterManagementDocumentRequestTypeId: number = undefined,
    riskRatingId: number = undefined,
    disbursementValueMin: number = undefined,
    disbursementValueMax: number = undefined,
    anticipatedValueMin: number = undefined,
    anticipatedValueMax: number = undefined,
    timeAndAttendanceTimeMin: number = undefined,
    timeAndAttendanceTimeMax: number = undefined,
    timeAndAttendanceCostMin: number = undefined,
    timeAndAttendanceCostMax: number = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {

    let url = `api/${this._controller}/matter-management/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (matterManagementDocumentStatusId != undefined) {
      url = `${url}&matterManagementDocumentStatusId=${matterManagementDocumentStatusId}`
    }

    if (decision != undefined) {
      url = `${url}&decision=${decision}`
    }

    if (documentRegionId != undefined) {
      url = `${url}&documentRegionId=${documentRegionId}`
    }

    if (documentFacilityId != undefined) {
      url = `${url}&documentFacilityId=${documentFacilityId}`
    }

    if (appealStatusId != undefined) {
      url = `${url}&appealStatusId=${appealStatusId}`
    }

    if (matterManagementDocumentReferralId != undefined) {
      url = `${url}&matterManagementDocumentReferralId=${matterManagementDocumentReferralId}`
    }

  
    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (matterManagementDocumentRequestTypeId != undefined) {
      url = `${url}&matterManagementDocumentRequestTypeId=${matterManagementDocumentRequestTypeId}`
    }

    if (riskRatingId != undefined) {
      url = `${url}&riskRatingId=${riskRatingId}`
    }

    if (disbursementValueMin != undefined) {
      url = `${url}&disbursementValueMin=${disbursementValueMin}`
    }
    if (disbursementValueMax != undefined) {
      url = `${url}&disbursementValueMax=${disbursementValueMax}`
    }
    if (anticipatedValueMin != undefined) {
      url = `${url}&anticipatedValueMin=${anticipatedValueMin}`
    }
    if (anticipatedValueMax != undefined) {
      url = `${url}&anticipatedValueMax=${anticipatedValueMax}`
    }
    if (timeAndAttendanceTimeMin != undefined) {
      url = `${url}&timeAndAttendanceTimeMin=${timeAndAttendanceTimeMin}`
    }
    if (timeAndAttendanceTimeMax != undefined) {
      url = `${url}&timeAndAttendanceTimeMax=${timeAndAttendanceTimeMax}`
    }
    if (timeAndAttendanceCostMin != undefined) {
      url = `${url}&timeAndAttendanceCostMin=${timeAndAttendanceCostMin}`
    }
    if (timeAndAttendanceCostMax != undefined) {
      url = `${url}&timeAndAttendanceCostMax=${timeAndAttendanceCostMax}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.get<MatterManagementDocumentResult>(url);
  }

  public exportMatterManagementDocuments(companyId: number, 
    matterManagementDocumentStatusId: number = undefined, 
    decision: boolean = undefined, 
    documentRegionId: number = undefined, 
    documentFacilityId: number = undefined, 
    appealStatusId: number = undefined,
    matterManagementDocumentReferralId: number = undefined,
    from: Date = undefined,
    to: Date = undefined,
    matterManagementDocumentRequestTypeId: number = undefined,
    riskRatingId: number = undefined,
    disbursementValueMin: number = undefined,
    disbursementValueMax: number = undefined,
    anticipatedValueMin: number = undefined,
    anticipatedValueMax: number = undefined,
    timeAndAttendanceTimeMin: number = undefined,
    timeAndAttendanceTimeMax: number = undefined,
    timeAndAttendanceCostMin: number = undefined,
    timeAndAttendanceCostMax: number = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/export/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (matterManagementDocumentStatusId != undefined) {
      url = `${url}&matterManagementDocumentStatusId=${matterManagementDocumentStatusId}`
    }

    if (decision != undefined) {
      url = `${url}&decision=${decision}`
    }

    if (documentRegionId != undefined) {
      url = `${url}&documentRegionId=${documentRegionId}`
    }

    if (documentFacilityId != undefined) {
      url = `${url}&documentFacilityId=${documentFacilityId}`
    }

    if (appealStatusId != undefined) {
      url = `${url}&appealStatusId=${appealStatusId}`
    }

    if (matterManagementDocumentReferralId != undefined) {
      url = `${url}&matterManagementDocumentReferralId=${matterManagementDocumentReferralId}`
    }

    

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (matterManagementDocumentRequestTypeId != undefined) {
      url = `${url}&matterManagementDocumentRequestTypeId=${matterManagementDocumentRequestTypeId}`
    }

    if (riskRatingId != undefined) {
      url = `${url}&riskRatingId=${riskRatingId}`
    }

    if (disbursementValueMin != undefined) {
      url = `${url}&disbursementValueMin=${disbursementValueMin}`
    }
    if (disbursementValueMax != undefined) {
      url = `${url}&disbursementValueMax=${disbursementValueMax}`
    }
    if (anticipatedValueMin != undefined) {
      url = `${url}&anticipatedValueMin=${anticipatedValueMin}`
    }
    if (anticipatedValueMax != undefined) {
      url = `${url}&anticipatedValueMax=${anticipatedValueMax}`
    }
    if (timeAndAttendanceTimeMin != undefined) {
      url = `${url}&timeAndAttendanceTimeMin=${timeAndAttendanceTimeMin}`
    }
    if (timeAndAttendanceTimeMax != undefined) {
      url = `${url}&timeAndAttendanceTimeMax=${timeAndAttendanceTimeMax}`
    }
    if (timeAndAttendanceCostMin != undefined) {
      url = `${url}&timeAndAttendanceCostMin=${timeAndAttendanceCostMin}`
    }
    if (timeAndAttendanceCostMax != undefined) {
      url = `${url}&timeAndAttendanceCostMax=${timeAndAttendanceCostMax}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetMatterManagementDocument(id: number, companyId: number) {
    let url = `api/${this._controller}/matter-management/${id}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<MatterManagementDocument>(url);
  }

  public AddMatterManagementDocument(document: MatterManagementDocument) {
    let url = `api/${this._controller}/matter-management`;
    return this.post<MatterManagementDocument, {}>(url, document);
  }

  public UpdateMatterManagementDocument(document: MatterManagementDocument) {
    let url = `api/${this._controller}/matter-management`;
    return this.put<MatterManagementDocument, {}>(url, document);
  }

  public GetMatterManagementDocumentFile(id: number, companyId: number, file: string) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/${id}/${file}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetMatterManagementDocumentHistoryFile(id: number, companyId: number, historyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/${id}/history/${historyId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetMatterManagementDocumentAdditionalFile(id: number, companyId: number, fileid: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/${id}/additional/${fileid}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }


  
  

  public getMatterManagementDocumentCorrespondence(id: number, companyId: number) {
    let url = `api/${this._controller}/matter-management/${id}/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<MatterManagementDocumentCorrespondence[]>(url);
  }

  public exportMatterManagementDocumentCorrespondence(id: number, companyId: number) {

    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/${id}/correspondence/export?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    //return this.get<MatterManagementDocumentCorrespondence[]>(url);

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public addMatterManagementDocumentCorrespondence(data: MatterManagementDocumentCorrespondence, companyId: number) {
    let url = `api/${this._controller}/matter-management/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<MatterManagementDocumentCorrespondence, {}>(url, data);
  }

  public getMatterManagementDocumentCorrespondenceFile(id: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/${id}/correspondence/download?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getMatterManagementDocumentCorrespondenceAttachmentFile(id: number, attachmentId: number, correspondenceId: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/${id}/correspondence/${correspondenceId}/attachment/${attachmentId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public updateMatterManagementDocumentNotes(data: UpdateMatterManagementDocumentNotes, companyId: number) {
    let url = `api/${this._controller}/matter-management/notes?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<UpdateMatterManagementDocumentNotes, {}>(url, data);
  }

  public getMatterManagementDocumentReminders(id: number, companyId: number) {
    let url = `api/${this._controller}/matter-management/${id}/reminders?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<MatterManagementDocumentReminder[]>(url);
  }

  public addMatterManagementDocumentReminder(data: MatterManagementDocumentReminder, companyId: number) {
    let url = `api/${this._controller}/matter-management/reminder?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<MatterManagementDocumentReminder, {}>(url, data);
  }



  
}
