import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DocumentRiskRating, PermitDocument, PermitDocumentReminder, PermitDocumentRequestType, PermitDocumentResult, PermitDocumentStatus, UpdatePermitDocumentNotes } from 'src/app/models/documents';
import { DocumentsApiService } from 'src/app/services/documents-api.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-permits',
  templateUrl: './permits.component.html',
  styleUrl: './permits.component.scss'
})
export class PermitsComponent {

  modalRef: NgbModalRef;
  
  companyId: number;
  orgId: number;
  apiCompanyId?: number;

  userId: number;
  user: boolean = false;
  contractAdmin: boolean = false;
  companyAdmin: boolean = false;
  orgAdmin: boolean = false;
  superAdmin: boolean = false;

  result: PermitDocumentResult;

  firstEnabled: boolean = false;
  prevEnabled: boolean = false;
  nextEnabled: boolean = false;
  lastEnabled: boolean = false;

  currentPage: number = 1;

  perPageItems = [5, 10, 20, 50, 100];
  perPage = 10;

  filterGranted: boolean = undefined;
  filterStatusId: number = undefined;
  filterRegionId: number = undefined;
  filterFacilityId: number = undefined;
  filterPermitRequestTypeId: number = undefined;
  filterRiskRatingId: number = undefined;
  filterDisbursementValueMin: number = undefined;
  filterDisbursementValueMax: number = undefined;
  filterAnticipatedValueMin: number = undefined;
  filterAnticipatedValueMax: number = undefined;
  filterTimeAndAttendanceTimeMin: number = undefined;
  filterTimeAndAttendanceTimeMax: number = undefined;
  filterTimeAndAttendanceCostMin: number = undefined;
  filterTimeAndAttendanceCostMax: number = undefined;

  statuses: PermitDocumentStatus[];
  requestTypes: PermitDocumentRequestType[];
  riskRatings: DocumentRiskRating[];

  constructor(private token: TokenService, private router: Router, private ngZone: NgZone, private route: ActivatedRoute, private documentsApi: DocumentsApiService, private spinner: SpinnerService, private modalService: NgbModal) {

  }

  ngOnInit() {

    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user || this.contractAdmin) {
      this.companyId = this.token.companyId();
      this.apiCompanyId = this.companyId;
      if (this.companyId) {
        this.init();
      }

    }
    else if (this.superAdmin || this.orgAdmin) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
        if (this.companyId) {
          this.init();
        }
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }
  }

  init() {

    if (this.superAdmin) {
      this.orgId = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
    }
    else {
      this.orgId = this.token.organisationId();
    }

    this.documentsApi.GetPermitStatuses().subscribe(data => {
      this.statuses = data;
    });

    this.documentsApi.GetPermitRequestTypes().subscribe(data => {
      this.requestTypes = data;
    });

    this.documentsApi.GetRiskRatings().subscribe(data => {
      this.riskRatings = data;
    });

    this.getData();


  }

  
  getData() {
    
    this.documentsApi.GetPermitDocuments(this.apiCompanyId, this.filterStatusId, this.filterGranted, this.filterPermitRequestTypeId, this.filterRiskRatingId, this.filterDisbursementValueMin,
      this.filterDisbursementValueMax,
      this.filterAnticipatedValueMin,
      this.filterAnticipatedValueMax,
      this.filterTimeAndAttendanceTimeMin,
      this.filterTimeAndAttendanceTimeMax,
      this.filterTimeAndAttendanceCostMin,
      this.filterTimeAndAttendanceCostMax,
      this.perPage, this.currentPage).subscribe(data => {
      this.result = data;

      this.firstEnabled = this.result.currentPage > 1;
      this.prevEnabled = this.result.currentPage > 1;

      this.nextEnabled = this.result.currentPage < data.totalPages;
      this.lastEnabled = this.result.currentPage < data.totalPages;
    })
  }

  exportData(){
    this.spinner.startBackground();
    this.documentsApi.ExportPermitDocuments(this.apiCompanyId, this.filterStatusId, this.filterGranted, this.filterPermitRequestTypeId, this.filterRiskRatingId, this.filterDisbursementValueMin,
      this.filterDisbursementValueMax,
      this.filterAnticipatedValueMin,
      this.filterAnticipatedValueMax,
      this.filterTimeAndAttendanceTimeMin,
      this.filterTimeAndAttendanceTimeMax,
      this.filterTimeAndAttendanceCostMin,
      this.filterTimeAndAttendanceCostMax,
      this.perPage, this.currentPage).subscribe(data => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }

  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.init();
    }
    else {

    }
  }

  download(ev: Event, id: number, file: string) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.GetPermitDocumentFile(id, this.apiCompanyId, file).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  first() {
    this.currentPage = 1;
    this.getData();
  }

  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.getData();
  }

  next() {
    if (this.currentPage < this.result.totalPages) {
      this.currentPage++;
    }

    this.getData();
  }

  last() {
    this.currentPage = this.result.totalPages;
    this.getData();
  }

  perPageChange(e: Event) {
    localStorage["PER_PAGE"] = this.perPage;
    this.currentPage = 1;
    this.getData();
  }

  public currentReminders: PermitDocumentReminder[];
  public currentReminder: PermitDocumentReminder;
  public currentDocument: PermitDocument;

  openReminderModal(ev: Event, content: any, document: PermitDocument){
    ev.preventDefault();

    this.spinner.startBackground();
    this.currentDocument = document;

    this.documentsApi.getPermitDocumentReminders(document.id, this.apiCompanyId).subscribe((data => {

      this.currentReminders = data;
      this.currentReminder = {
        permitDocumentId: this.currentDocument.id,
        reminderText: '',
        reminderDateTime: new Date(),
        reminderEmail: ''
      };
      this.spinner.stopBackground();
      this.modalRef = this.modalService.open(content);

      this.modalRef.result.then((result) => {        
      }, (reason) => {
      });

    }));

    
  }

  addReminder(){
    this.spinner.startBackground();
    this.documentsApi.addPermitDocumentReminder(this.currentReminder, this.apiCompanyId).subscribe(() => {
      this.spinner.stopBackground();
      this.modalRef.close();
      //this.getContracts();
    });
  }


  currentNotes: string;

  openNotesModal(content, contract: PermitDocument) {
    this.currentNotes = contract.notes;

    this.modalService.open(content, { size: 'sm' }).result.then((result) => {
      let newNotes = this.currentNotes;
      let data: UpdatePermitDocumentNotes = {
        id: contract.id,
        notes: newNotes
      };

      this.spinner.startBackground();

      this.documentsApi.updatePermitDocumentNotes(data, this.apiCompanyId).subscribe(() => {
        this.spinner.stopBackground();
        this.getData();
        

      });
    }, (reason) => {
    });
  }


  truncate(str?: string) {
    if (!str) {
      return str;
    }
    if (str && str.length > 40)
      return str.substring(0, 40) + '...';
    else
      return str;
  };

  
  calculateCosts(item: PermitDocument){
    let total = 0;
    item.permitDocumentCosts.forEach(f => total += f.rate * f.time);

    return total;
  }

  calculateTime(item: PermitDocument){
    let total = 0;
    item.permitDocumentCosts.forEach(f => total += f.time);

    return total;
  }

  visibleId: number;

  showHide(id) {

    if (this.visibleId == id) {
      this.visibleId = undefined;
    }
    else {
      this.visibleId = id;
    }
  }
}
