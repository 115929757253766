<div class="mb-1" *ngIf="superAdmin || orgAdmin">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.result">
    <h2>KYC and Due Dilligence</h2>

    <div class="d-flex align-items-end   mb-2">
        <button class="btn btn-primary me-2" routerLink="/dashboard/documents/home">&lt; BACK</button>



        <div class="flex-fill">

        </div>

        <button class="btn btn-primary" routerLink="/dashboard/documents/fica/0">NEW REQUEST</button>



    </div>

    <div class="d-flex flex-wrap   mb-2">

        <div class="me-2">
            <label for="filterDecision">Date From:</label>
            <input class="form-control" [ngModel]="filterFrom | date:'yyyy-MM-dd'"
                (ngModelChange)="filterFrom = $event; getData();" type="date" placeholder="Select date">
        </div>

        <div class="me-2">
            <label for="filterDecision">Date To:</label>
            <input class="form-control" [ngModel]="filterTo | date:'yyyy-MM-dd'"
                (ngModelChange)="filterTo = $event; getData();" type="date" placeholder="Select date">
        </div>

        <div class="me-2">
            <label for="filterDecision">Status:</label>
            <ng-select [(ngModel)]="filterStatusId" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 250px;" placeholder="All">
                <ng-option *ngFor="let status of statuses" [value]="status.id">{{status.name}}</ng-option>
            </ng-select>
        </div>
        <div class="me-2">
            <label for="filterDecision">Decision:</label>
            <ng-select [(ngModel)]="filterDecision" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 100px;" placeholder="All">
                <ng-option [value]="1">APPROVED</ng-option>
                <ng-option [value]="0">DECLINED</ng-option>
                <ng-option [value]="-1">PARTIALLY</ng-option>
            </ng-select>
        </div>

        <div class="me-2">
            <label for="filterFicaRequestTypeId">Request Type:</label>
            <ng-select [(ngModel)]="filterFicaRequestTypeId" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 250px;" placeholder="All">
                <ng-option *ngFor="let requestType of requestTypes" [value]="requestType.id">{{requestType.name}}</ng-option>
            </ng-select>
        </div>

        <div class="me-2">
            <label for="filterRiskRatingId">Risk Rating:</label>
            <ng-select [(ngModel)]="filterRiskRatingId" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 150px;" placeholder="All">
                <ng-option *ngFor="let riskRating of riskRatings" [value]="riskRating.id">{{riskRating.name}}</ng-option>
            </ng-select>
        </div>

        <div class="me-2">
            <label for="filterRiskRatingId">Disbursement:</label>
            <div class="d-flex">
            <input class="form-control me-1" [ngModel]="filterDisbursementValueMin" style="max-width: 80px;"
                (ngModelChange)="filterDisbursementValueMin = $event; getData();" type="number" min="0" placeholder="Min">
                <input class="form-control" [ngModel]="filterDisbursementValueMax" style="max-width: 80px;"
                (ngModelChange)="filterDisbursementValueMax = $event; getData();" type="number" min="0" placeholder="Max">
            </div>
        </div>
        <div class="me-2">
            <label for="filterRiskRatingId">Anticipated Value:</label>
            <div class="d-flex">
            <input class="form-control me-1" [ngModel]="filterAnticipatedValueMin" style="max-width: 80px;"
                (ngModelChange)="filterAnticipatedValueMin = $event; getData();" type="number" min="0" placeholder="Min">
                <input class="form-control" [ngModel]="filterAnticipatedValueMax" style="max-width: 80px;"
                (ngModelChange)="filterAnticipatedValueMax = $event; getData();" type="number" min="0" placeholder="Max">
            </div>
        </div>
        <div class="me-2">
            <label for="filterRiskRatingId">Time & Attendance Hours:</label>
            <div class="d-flex">
            <input class="form-control me-1" [ngModel]="filterTimeAndAttendanceTimeMin" style="max-width: 80px;"
                (ngModelChange)="filterTimeAndAttendanceTimeMin = $event; getData();" type="number" min="0" placeholder="Min">
                <input class="form-control" [ngModel]="filterTimeAndAttendanceTimeMax" style="max-width: 80px;"
                (ngModelChange)="filterTimeAndAttendanceTimeMax = $event; getData();" type="number" min="0" placeholder="Max">
            </div>
        </div>

        <div class="me-2">
            <label for="filterRiskRatingId">Time & Attendance Cost:</label>
            <div class="d-flex">
            <input class="form-control me-1" [ngModel]="filterTimeAndAttendanceCostMin" style="max-width: 80px;"
                (ngModelChange)="filterTimeAndAttendanceCostMin = $event; getData();" type="number" min="0" placeholder="Min">
                <input class="form-control" [ngModel]="filterTimeAndAttendanceCostMax" style="max-width: 80px;"
                (ngModelChange)="filterTimeAndAttendanceCostMax = $event; getData();" type="number" min="0" placeholder="Max">
            </div>
        </div>

        <!-- <div class="me-2">
            <label for="documentRegionId">Region:</label>
            <ng-select [(ngModel)]="filterRegionId" [items]="regions" bindLabel="name" bindValue="id"
                id="documentRegionId" (change)="getFacilities()" style="min-width: 250px;" placeholder="All"
                [clearable]="true"></ng-select>
        </div> -->

        <!-- <div class="me-2">
            <label for="documentFacilityId">Facility:</label>
            <ng-select [(ngModel)]="filterFacilityId" [items]="facilities" bindLabel="name" bindValue="id"
                id="documentFacilityId" placeholder="All" [clearable]="true" style="min-width: 250px;"
                [readonly]="facilities == undefined" (change)="getData()"></ng-select>
        </div> -->






        <div class="ms-auto d-flex align-items-end">
            <button class="btn btn-primary" (click)="exportData()">EXPORT</button>
        </div>
    </div>

    <div class="row">



        <div class="col">

            <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
                <tr>

                    <th class="text-center">REF NO</th>
                    <th class="text-center">RECIPIENT</th>
                    <th class="text-center">CLIENT</th>
                    <th class="text-center">STATUS</th>
                    <th class="text-center">VIEW</th>
                    <th class="text-center">NOTES</th>
                    <th class="text-center">REMINDERS AND TASKS</th>
                </tr>
                <ng-container *ngFor="let item of result?.items">
                    <tr>

                        <td class="p-2"><a class="p-0"
                                [routerLink]="'/dashboard/documents/fica/' + item.id">{{item.referenceNumber}}</a></td>
                        <td>
                            <strong>Accountable recipient of the request:</strong><br />
                            {{item.recipientFirstname}} {{item.recipientLastname}}<br />
                            {{item.recipientContactNumber}}<br />
                            {{item.recipientEmail}}<br />
                            <strong>Entity:</strong><br />
                            {{item.company}} <br />
                        </td>
                       
                        <td>
                            {{item.clientFirstname}} {{item.clientLastname}}<br />
                            {{item.clientIDNumber}}<br />
                            {{item.clientCompany}} {{item.clientCompanyRegistrationNumber}}<br />
                            {{item.clientContactNumber}}<br />
                            {{item.clientEmail}}<br />
                            {{item.clientAddress}}
                        </td>

                        <td>
                            {{item.ficaDocumentStatus}}<br />
                        </td>
                        <td class="p-2"><button class="btn btn-light" (click)="showHide(item.id)">{{visibleId == item.id
                                ? 'Hide' : 'Show'}} Details</button></td>
                        <td>
                            <div class="d-flex align-items-start">
                                <span class=" flex-grow-1" placement="top"
                                    [ngbTooltip]="item.notes"><pre>{{truncate(item.notes)}}</pre></span>
                                <a class="ms-1 icon-link" (click)="openNotesModal(notesModal, item)"><span
                                        class="icon icon-small icon-edit-red"></span></a>
                            </div>
                        </td>
                        <td  class="text-center"><a href="#" (click)="openReminderModal($event, reminderModal, item)" title="Reminders and Tasks">
                            <span class="icon icon-notification-red"></span>
                          </a></td>

                    </tr>
                    <tr class="bg-light" *ngIf="visibleId == item.id">
                        <td class="p-0" colspan="7">

                            <table class="table table-bordered table-sm company-acts-table mb-0">
                                <tr>
                                    <th class="text-center">DISBURSEMENTS</th>
                                    <th class="text-center">DEAL VALUE</th>
                                    <th class="text-center">TIME & ATTENDANCE</th>
                                    <th class="text-center">REQUESTOR</th>
                                    <th class="text-center">REQUEST DETAILS</th>
                                    <th class="text-center">CHARGES/FEES</th>
                                    <th class="text-center">DECISION</th>
                                    <th class="text-center">NB DATES</th>
                                    <th class="text-center">FILES</th>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Cost:</strong><br />
                                        {{item.disbursementCost}}<br />
                                        <strong>Cost per month:</strong><br />
                                        {{item.disbursementCostPerMonth}}<br />
                                        <strong>Cost per annum:</strong><br />
                                        {{item.disbursementCostPerAnnum}}<br />
                                        <strong>Increase per annum:</strong><br />
                                        {{item.disbursementIncreasePerAnnum}}<br />
                                    </td>
                                    <td>
                                        <strong>Value:</strong><br />
                                        {{item.dealValue}}<br />
                                        <strong>Monthly:</strong><br />
                                        {{item.dealValuePerMonth}}<br />
                                        <strong>Cost per Annum:</strong><br />
                                        {{item.dealValuePerAnnum}}<br />
                                        <strong>Increase:</strong><br />
                                        {{item.dealIncreasePerAnnum}}<br />
                                    </td>
                                    <td class="text-center">
                                        <strong>Time:</strong><br />
                                        {{calculateTime(item)}} hours<br />
                                        <strong>Cost:</strong><br />
                                        R {{calculateCosts(item) | number: '1.2-2'}}

                                    </td>
                                    <td>
                                        <strong>Requestor details:</strong><br />
                                        {{item.requestorName}}<br />
                                        {{item.requestorIDNumber}}
                                    </td>
                                    <td>
                                        <strong>Request type:</strong><br />
                                        {{item.ficaDocumentRequestType}}<br />
                                        <strong>Details:</strong><br />
                                        {{item.requestDetails}}<br />
                                        <strong>Reason:</strong><br />
                                        {{item.requestReason}}<br />
                                        <strong>Request Date:</strong><br />
                                        {{item.requestDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Received Date:</strong><br />
                                        {{item.receivedDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Acknowledged Date:</strong><br />
                                        {{item.acknowledgedDate | date:'yyyy-MM-dd'}}
                                    </td>
                                    <td>
                                        <strong>Credit Bureux Fee:</strong><br />
                                        {{item.creditBureuxFee}}<br />
                                        <strong>Sanctions Fee:</strong><br />
                                        {{item.sanctionsFee}}<br />
                                        <strong>Other:</strong><br />
                                        {{item.otherFee}}<br />
                                        <strong>Deposit:</strong><br />
                                        {{item.totalFee}}<br />
                                        <strong>Amount to be banked:</strong><br />
                                        {{item.departmentToBeCharged}}<br />
                                    </td>
                                    <td>
                                        <strong>Outcome:</strong><br />
                                        {{getDecisionLabel(item.decision)}}<br />
                                        <strong>Reason:</strong><br />
                                        {{item.decisionReason}}<br />
                                        <strong>Decision Date:</strong><br />
                                        {{item.decisionDate | date:'yyyy-MM-dd'}}<br />
                                    </td>
                                    <td>
                                        <strong>Request:</strong><br />
                                        {{item.requestDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Received:</strong><br />
                                        {{item.receivedDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Transaction Opened:</strong><br />
                                        {{item.transactionOpenedDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Transaction Approved:</strong><br />
                                        {{item.transactionApprovedDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Transaction Closed:</strong><br />
                                        {{item.transactionClosedDate | date:'yyyy-MM-dd'}}<br />
                                        <!-- <strong>Renewal:</strong><br />
                                        {{item.renewalDate | date:'yyyy-MM-dd'}}<br /> -->
                                    </td>
                                    <td>
                                        <a href="#" (click)="download($event, item.id, 'Document')"
                                            class="d-flex align-items-center"><span
                                                class="icon icon-view-red me-2"></span>Main
                                            Document</a><br />

                                        <a href="#" (click)="download($event, item.id, 'KYCForms')"
                                            class="d-flex align-items-center" *ngIf="item.kycFormFilePath != null"><span
                                                class="icon icon-view-red me-2"></span>KYC Forms</a><br />
                                        
                                                <a href="#" (click)="download($event, item.id, 'CompanyDocuments')"
                                            class="d-flex align-items-center" *ngIf="item.companyDocumentsFilePath != null"><span
                                                class="icon icon-view-red me-2"></span>Company Documents</a><br />

                                                <a href="#" (click)="download($event, item.id, 'IdentityDocuments')"
                                            class="d-flex align-items-center" *ngIf="item.identityDocumentsFilePath != null"><span
                                                class="icon icon-view-red me-2"></span>Identity Documents</a><br />

                                                <a href="#" (click)="download($event, item.id, 'AdditionalTransaction')"
                                            class="d-flex align-items-center" *ngIf="item.additionalTransactionFilePath != null"><span
                                                class="icon icon-view-red me-2"></span>Additional Transaction Documentation</a><br />
                                    </td>
                                </tr>
                            </table>

                        </td>

                    </tr>
                </ng-container>

            </table>

            <div class="pager-bar py-4 mb-1" *ngIf="result && result.items">
                <div class="container text-center d-flex flex-row align-items-center justify-content-center"
                    *ngIf="result.items.length > 0">
                    <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt;
                        First</button>&nbsp;
                    <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
                    <span class="px-4">Page {{result.currentPage}} of {{result.totalPages}}</span>
                    <div class="px-4 d-flex flex-row align-items-center">
                        <div class="pe-2">Items per page:
                        </div>
                        <div>
                            <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
                        </div>
                    </div>
                    <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
                    <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last
                        &gt;&gt;</button>&nbsp;
                </div>
            </div>

        </div>

    </div>
</div>

<ng-template #reminderModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Reminders and Tasks</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form>
        <div class="modal-body">

            <div class="form-group">
                <label>Responsible Person Email</label>
                <input class="form-control" [ngModel]="currentReminder.reminderEmail"
                    (ngModelChange)="currentReminder.reminderEmail = $event" type="email" id="reminderEmail"
                    name="reminderEmail" placeholder="Responsible Person Email">
            </div>
            <div class="form-group">
                <label>Reminder Date</label>
                <input class="form-control" [ngModel]="currentReminder.reminderDateTime | date:'yyyy-MM-dd'"
                    (ngModelChange)="currentReminder.reminderDateTime = $event" type="date" id="reminderDateTime"
                    name="reminderDateTime" placeholder="Reminder Date">
            </div>
            <div class="form-group">
                <label>Reminder Text</label>
                <input class="form-control" [(ngModel)]="currentReminder.reminderText" type="text" id="reminderText"
                    name="reminderText" placeholder="Reminder Text">
            </div>
            <div class="form-group">
                <button type="button" ngbAutofocus class="btn btn-primary"
                    [disabled]="!currentReminder.reminderDateTime || !currentReminder.reminderText || !currentReminder.reminderEmail"
                    (click)="addReminder()">Save</button>
            </div>

            <h5>Current Reminders</h5>
            <div *ngFor="let reminder of currentReminders" [class.strike]="!reminder.active">
                <hr />
                {{reminder.reminderDateTime | date:'yyyy-MM-dd'}} ({{reminder.reminderEmail}})<br />
                {{reminder.reminderText}}

            </div>

        </div>
        <div class="modal-footer">
            <button type="button" ngbAutofocus class="btn btn-primary"
                
                (click)="modal.close('Close click')">Close</button>
        </div>
    </form>
</ng-template>



<ng-template #notesModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update Notes</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <textarea class="form-control" id="notes" name="notes" [(ngModel)]="currentNotes">
              </textarea>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
    </div>
</ng-template>