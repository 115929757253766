<div class="mb-1" *ngIf="superAdmin || orgAdmin">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.apiCompanyId">

    <div class="card mb-3" *ngIf="currentDocumentId != 0">
        <div class="card-body">
            <h4 class="card-title">Workflow</h4>
            <div class="mb-3 d-flex justify-content-between align-items-center">
                Correspondence
                <button class="btn btn-primary" (click)="sendCorrespondence(content)">Send</button>
            </div>
            <hr />
            <div class="mb-3 d-flex justify-content-between align-items-center">
                Workflow
                <div>

                    <button class="btn btn-primary me-2" (click)="viewCorrespondence()">{{ showCorrespondence ? 'Hide': 'View'}}</button>
                    <button class="btn btn-primary" (click)="exportCorrespondence()">Export</button>
                </div>

            </div>
            <div *ngIf="showCorrespondence">

                <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
                    <tr>
                        <th>DATE/TIME</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>SENDER</th>
                        <th>TYPE</th>
                        <th>DOCUMENT</th>
                        <th>ATTACHMENTS</th>
                    </tr>
                    <tr *ngFor="let item of correspondence">
                        <td>{{item.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.sender}}</td>
                        <td>{{item.documentCorrespondenceType}}</td>

                        <td class="text-center">
                            <a href="#" (click)="download($event, item.id)" class="d-flex align-items-center">
                                <span class="icon icon-view-red me-2"></span>
                            </a>
                        </td>
                        <td class="text-center">
                            <div class="d-flex">
                                <a href="#" *ngFor="let attachment of item.attachments"
                                    (click)="downloadAttachment($event, attachment.id, item.id)"
                                    class="d-flex align-items-center">
                                    <span class="icon icon-view-red me-1"></span>
                                </a>
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    </div>

    <div class="text-end mb-2"><button class="btn btn-primary" (click)="print()">Export</button></div>
    <form id="mainForm" [formGroup]="mainForm" (ngSubmit)="submit()">


        <div class="card mb-3" *ngIf="currentDocumentId == 0">
            <div class="card-body">
                <h4 class="card-title">Document Template</h4>

                <div class="mb-3">
                    <label for="standardTemplate" class="form-label">Template Type</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="standardTemplate"
                                id="standardTemplateYes"
                                [class.is-invalid]="mainForm.controls.standardTemplate.touched && mainForm.controls.standardTemplate.invalid"
                                [value]="true" formControlName="standardTemplate">
                            <label class="form-check-label" for="standardTemplateYes">Standard</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="standardTemplate" id="standardTemplateNo"
                                [class.is-invalid]="mainForm.controls.standardTemplate.touched && mainForm.controls.standardTemplate.invalid"
                                [value]="false" formControlName="standardTemplate">
                            <label class="form-check-label" for="standardTemplateNo">Own provided</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.standardTemplate.errors">
                        <div *ngIf="mainForm.controls.standardTemplate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" [class.d-none]="mainForm.controls.standardTemplate.value != true">
                    <label for="documentTemplateId" class="form-label">Template</label>
                    <ng-select [items]="templates" bindLabel="name" bindValue="id" id="documentTemplateId"
                        [class.is-invalid]="mainForm.controls.documentTemplateId.touched && mainForm.controls.documentTemplateId.invalid"
                        formControlName="documentTemplateId" placeholder="Select a standard template"
                        [clearable]="true"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentTemplateId.errors">
                        <div *ngIf="mainForm.controls.documentTemplateId.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" [class.d-none]="mainForm.controls.standardTemplate.value != false">
                    <label for="documentFileData" class="form-label">Template</label>
                    <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                        [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid"
                        (change)="fileUpload(mainForm.controls.documentFileData, $event)">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFileData.errors">
                        <div *ngIf="mainForm.controls.documentFileData.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" [class.d-none]="mainForm.controls.standardTemplate.value != false">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="useAsTemplate"
                            formControlName="useAsTemplate">
                        <label class="form-check-label" for="useAsTemplate">Load document as a Standard Template</label>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.useAsTemplate.errors">
                        <div *ngIf="mainForm.controls.useAsTemplate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" *ngIf="mainForm.controls.useAsTemplate.value"
                    [class.d-none]="mainForm.controls.standardTemplate.value != false">
                    <label for="templateName" class="form-label">Template Name</label>
                    <input type="text" class="form-control" id="templateName" formControlName="templateName"
                        [class.is-invalid]="mainForm.controls.templateName.touched && mainForm.controls.templateName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.templateName.errors">
                        <div *ngIf="mainForm.controls.templateName.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card mb-3" *ngIf="currentDocumentId > 0">
            <div class="card-body">
                <h4 class="card-title">Document</h4>

                <div class="mb-3">
                    <label for="documentFileData" class="form-label">Update Document</label>
                    <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                        [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid"
                        (change)="fileUpload(mainForm.controls.documentFileData, $event)">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFileData.errors">
                        <div *ngIf="mainForm.controls.documentFileData.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">


                <div class="mb-3">
                    <label for="requestedDate" class="form-label">Date of Instruction/Request</label>
                    <input type="date" class="form-control" id="requestedDate" formControlName="requestedDate"
                        [class.is-invalid]="mainForm.controls.requestedDate.touched && mainForm.controls.requestedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestedDate.errors">
                        <div *ngIf="mainForm.controls.requestedDate.errors.required">Required</div>
                    </div>
                </div>


            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Required By (Responsible Person in Department)</h4>

                <div class="mb-3">
                    <label for="requiredByName" class="form-label">Name</label>
                    <input type="text" class="form-control" id="requiredByName" formControlName="requiredByName"
                        [class.is-invalid]="mainForm.controls.requiredByName.touched && mainForm.controls.requiredByName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requiredByName.errors">
                        <div *ngIf="mainForm.controls.requiredByName.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requiredByIDNumber" class="form-label">ID Number</label>
                    <input type="text" class="form-control" id="requiredByIDNumber" formControlName="requiredByIDNumber"
                        [class.is-invalid]="mainForm.controls.requiredByIDNumber.touched && mainForm.controls.requiredByIDNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requiredByIDNumber.errors">
                        <div *ngIf="mainForm.controls.requiredByIDNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="departmentId" class="form-label"
                        [class.is-invalid]="mainForm.controls.departmentId.touched && mainForm.controls.departmentId.invalid">Department</label>
                    <ng-select [items]="departments" bindLabel="name" bindValue="id" id="departmentId"
                        formControlName="departmentId" placeholder="Select a department"
                        [clearable]="false"
                        [class.is-invalid]="mainForm.controls.departmentId.touched && mainForm.controls.departmentId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.departmentId.errors">
                        <div *ngIf="mainForm.controls.departmentId.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requiredByAddress" class="form-label">Address</label>
                    <textarea type="text" class="form-control" id="requiredByAddress"
                        formControlName="requiredByAddress"></textarea>
                </div>

                <div class="mb-3">
                    <label for="requiredByContactPerson" class="form-label">Contact Person</label>
                    <input type="text" class="form-control" id="requiredByContactPerson"
                        formControlName="requiredByContactPerson"
                        [class.is-invalid]="mainForm.controls.requiredByContactPerson.touched && mainForm.controls.requiredByContactPerson.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requiredByContactPerson.errors">
                        <div *ngIf="mainForm.controls.requiredByContactPerson.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requiredByEmail" class="form-label">Email</label>
                    <input type="text" class="form-control" id="requiredByEmail" formControlName="requiredByEmail"
                        [class.is-invalid]="mainForm.controls.requiredByEmail.touched && mainForm.controls.requiredByEmail.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requiredByEmail.errors">
                        <div *ngIf="mainForm.controls.requiredByEmail.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.requiredByEmail.errors.email">Invalid</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requiredByContactNumber" class="form-label">Contact Number</label>
                    <input type="text" class="form-control" id="requiredByContactNumber"
                        formControlName="requiredByContactNumber"
                        [class.is-invalid]="mainForm.controls.requiredByContactNumber.touched && mainForm.controls.requiredByContactNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requiredByContactNumber.errors">
                        <div *ngIf="mainForm.controls.requiredByContactNumber.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Requested By (Entity Requesting Policy)</h4>

                <div class="mb-3">
                    <label for="requestorCompany" class="form-label">Company Name</label>
                    <input type="text" class="form-control" id="requestorCompany" formControlName="requestorCompany"
                        [class.is-invalid]="mainForm.controls.requestorCompany.touched && mainForm.controls.requestorCompany.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorCompany.errors">
                        <div *ngIf="mainForm.controls.requestorCompany.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorDepartment" class="form-label">Department</label>
                    <input type="text" class="form-control" id="requestorDepartment"
                        formControlName="requestorDepartment"
                        [class.is-invalid]="mainForm.controls.requestorDepartment.touched && mainForm.controls.requestorDepartment.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorDepartment.errors">
                        <div *ngIf="mainForm.controls.requestorDepartment.errors.required">Required</div>
                    </div>
                </div>

                

            </div>
        </div>






        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Request Details</h4>

                <div class="mb-3">
                    <label for="policyDocumentRequestTypeId" class="form-label"
                        [class.is-invalid]="mainForm.controls.policyDocumentRequestTypeId.touched && mainForm.controls.policyDocumentRequestTypeId.invalid">Type
                        of Request</label>
   
                    <ng-select [items]="requestTypes" bindLabel="name" bindValue="id" id="policyDocumentRequestTypeId"
                        formControlName="policyDocumentRequestTypeId" placeholder="Select a request type"
                        [clearable]="false"
                        [class.is-invalid]="mainForm.controls.policyDocumentRequestTypeId.touched && mainForm.controls.policyDocumentRequestTypeId.invalid"></ng-select>

                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.policyDocumentRequestTypeId.errors">
                        <div *ngIf="mainForm.controls.policyDocumentRequestTypeId.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" [class.d-none]="mainForm.controls.policyDocumentRequestTypeId.value != requestTypeOther?.id">
                    <label for="policyDocumentRequestTypeOther" class="form-label"
                        [class.is-invalid]="mainForm.controls.policyDocumentRequestTypeOther.touched && mainForm.controls.policyDocumentRequestTypeOther.invalid">Type
                        of request details</label>
                    <input type="text" class="form-control" id="policyDocumentRequestTypeOther" formControlName="policyDocumentRequestTypeOther" bindLabel="name" bindValue="id"
                        [class.is-invalid]="mainForm.controls.policyDocumentRequestTypeOther.touched && mainForm.controls.policyDocumentRequestTypeOther.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.policyDocumentRequestTypeOther.errors">
                        <div *ngIf="mainForm.controls.policyDocumentRequestTypeOther.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="policyType" class="form-label">Policy Type</label>
                    <input type="text" class="form-control" id="policyType" formControlName="policyType"
                        [class.is-invalid]="mainForm.controls.policyType.touched && mainForm.controls.policyType.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.policyType.errors">
                        <div *ngIf="mainForm.controls.policyType.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="law" class="form-label">Law</label>
                    <input type="text" class="form-control" id="law" formControlName="law"
                        [class.is-invalid]="mainForm.controls.law.touched && mainForm.controls.law.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.law.errors">
                        <div *ngIf="mainForm.controls.law.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="applicableArea" class="form-label">Applicable Area</label>
                    <input type="text" class="form-control" id="applicableArea" formControlName="applicableArea"
                        [class.is-invalid]="mainForm.controls.applicableArea.touched && mainForm.controls.applicableArea.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.applicableArea.errors">
                        <div *ngIf="mainForm.controls.applicableArea.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>






        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Disbursements</h4>


                <div class="mb-3">
                    <label for="disbursementCost" class="form-label">Cost</label>
                    <input type="number" class="form-control" id="disbursementCost" formControlName="disbursementCost"
                        [class.is-invalid]="mainForm.controls.disbursementCost.touched && mainForm.controls.disbursementCost.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.disbursementCost.errors">
                        <div *ngIf="mainForm.controls.disbursementCost.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.disbursementCost.errors.min">Invalid number</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="disbursementCostPerMonth" class="form-label">Cost per Month</label>
                    <input type="number" class="form-control" id="disbursementCostPerMonth" formControlName="disbursementCostPerMonth"
                        [class.is-invalid]="mainForm.controls.disbursementCostPerMonth.touched && mainForm.controls.disbursementCostPerMonth.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.disbursementCostPerMonth.errors">
                        <div *ngIf="mainForm.controls.disbursementCostPerMonth.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.disbursementCostPerMonth.errors.min">Invalid number</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="disbursementCostPerAnnum" class="form-label">Cost per Annum</label>
                    <input type="number" class="form-control" id="disbursementCostPerAnnum" formControlName="disbursementCostPerAnnum"
                        [class.is-invalid]="mainForm.controls.disbursementCostPerAnnum.touched && mainForm.controls.disbursementCostPerAnnum.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.disbursementCostPerAnnum.errors">
                        <div *ngIf="mainForm.controls.disbursementCostPerAnnum.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.disbursementCostPerAnnum.errors.min">Invalid number</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="disbursementIncreasePerAnnum" class="form-label">Increase per Annum</label>
                    <input type="number" class="form-control" id="disbursementIncreasePerAnnum"
                        formControlName="disbursementIncreasePerAnnum"
                        [class.is-invalid]="mainForm.controls.disbursementIncreasePerAnnum.touched && mainForm.controls.disbursementIncreasePerAnnum.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.disbursementIncreasePerAnnum.errors">
                        <div *ngIf="mainForm.controls.disbursementIncreasePerAnnum.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.disbursementIncreasePerAnnum.errors.min">Invalid number</div>
                    </div>
                </div>

            </div>
        </div>
        

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Deal Value</h4>

                <div class="mb-3">
                    <label for="dealValue" class="form-label">Anticipated value</label>
                    <input type="number" class="form-control" id="dealValue" formControlName="dealValue"
                        [class.is-invalid]="mainForm.controls.dealValue.touched && mainForm.controls.dealValue.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.dealValue.errors">
                        <div *ngIf="mainForm.controls.dealValue.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.dealValue.errors.min">Invalid number</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="dealValuePerMonth" class="form-label">Per month</label>
                    <input type="number" class="form-control" id="dealValuePerMonth"
                        formControlName="dealValuePerMonth"
                        [class.is-invalid]="mainForm.controls.dealValuePerMonth.touched && mainForm.controls.dealValuePerMonth.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.dealValuePerMonth.errors">
                        <div *ngIf="mainForm.controls.dealValuePerMonth.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.dealValuePerMonth.errors.min">Invalid number</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="dealValuePerAnnum" class="form-label">Per annum</label>
                    <input type="number" class="form-control" id="dealValuePerAnnum"
                        formControlName="dealValuePerAnnum"
                        [class.is-invalid]="mainForm.controls.dealValuePerAnnum.touched && mainForm.controls.dealValuePerAnnum.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.dealValuePerAnnum.errors">
                        <div *ngIf="mainForm.controls.dealValuePerAnnum.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.dealValuePerAnnum.errors.min">Invalid number</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="dealIncreasePerAnnum" class="form-label">Increase per annum</label>
                    <input type="number" class="form-control" id="dealIncreasePerAnnum"
                        formControlName="dealIncreasePerAnnum"
                        [class.is-invalid]="mainForm.controls.dealIncreasePerAnnum.touched && mainForm.controls.dealIncreasePerAnnum.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.dealIncreasePerAnnum.errors">
                        <div *ngIf="mainForm.controls.dealIncreasePerAnnum.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.dealIncreasePerAnnum.errors.min">Invalid number</div>
                    </div>
                </div>



            </div>
        </div>



        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">TIME & ATTENDANCE COST</h4>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Hourly Rate</th>
                            <th>Time</th>
                            <th>Total</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container formArrayName="policyDocumentCosts">
                            <ng-container
                                *ngFor="let policyDocumentCostForm of mainForm.controls.policyDocumentCosts.controls; let i = index">

                                <tr [formGroup]="policyDocumentCostForm" class="mb-2">
                                    <td>
                                        <input type="date" class="form-control" id="date" formControlName="date"
                                            [class.is-invalid]="policyDocumentCostForm.controls.date.touched && policyDocumentCostForm.controls.date.invalid"
                                            placeholder="Date">
                                        <div class="invalid-feedback text-end"
                                            *ngIf="policyDocumentCostForm.controls.date.errors">
                                            <div *ngIf="policyDocumentCostForm.controls.date.errors.required">
                                                Required</div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" id="description"
                                            formControlName="description"
                                            [class.is-invalid]="policyDocumentCostForm.controls.description.touched && policyDocumentCostForm.controls.description.invalid"
                                            placeholder="Description">
                                        <div class="invalid-feedback text-end"
                                            *ngIf="policyDocumentCostForm.controls.description.errors">
                                            <div
                                                *ngIf="policyDocumentCostForm.controls.description.errors.required">
                                                Required</div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" id="rate" formControlName="rate"
                                            [class.is-invalid]="policyDocumentCostForm.controls.rate.touched && policyDocumentCostForm.controls.rate.invalid"
                                            placeholder="Hourly Rate">
                                        <div class="invalid-feedback text-end"
                                            *ngIf="policyDocumentCostForm.controls.rate.errors">
                                            <div *ngIf="policyDocumentCostForm.controls.rate.errors.required">
                                                Required</div>
                                            <div *ngIf="policyDocumentCostForm.controls.rate.errors.min">
                                                Invalid</div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" id="time" formControlName="time"
                                            [class.is-invalid]="policyDocumentCostForm.controls.time.touched && policyDocumentCostForm.controls.time.invalid"
                                            placeholder="Time">
                                        <div class="invalid-feedback text-end"
                                            *ngIf="policyDocumentCostForm.controls.time.errors">
                                            <div *ngIf="policyDocumentCostForm.controls.time.errors.required">
                                                Required</div>
                                            <div *ngIf="policyDocumentCostForm.controls.time.errors.min">
                                                Invalid</div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" readonly placeholder="Total"
                                            [value]="policyDocumentCostForm.controls.time.value * policyDocumentCostForm.controls.rate.value" />
                                    </td>

                                    <td class="text-center">
                                        <button type="button" class="btn btn-link" (click)="removeCost(i)">X</button>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>

                    </tbody>
                </table>
                <div class="text-end">
                    <button type="button" class="btn btn-default" (click)="addCost()">Add</button>&nbsp;

                </div>


            </div>
        </div>

        


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Duration &amp; Dates</h4>




                <div class="mb-3">
                    <label for="policyStartDate" class="form-label">Start Date</label>
                    <input type="date" class="form-control" id="policyStartDate" formControlName="policyStartDate"
                        [class.is-invalid]="mainForm.controls.policyStartDate.touched && mainForm.controls.policyStartDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.policyStartDate.errors">
                        <div *ngIf="mainForm.controls.policyStartDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="policyEndDate" class="form-label">End Date</label>
                    <input type="date" class="form-control" id="policyEndDate" formControlName="policyEndDate"
                        [class.is-invalid]="mainForm.controls.policyEndDate.touched && mainForm.controls.policyEndDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.policyEndDate.errors">
                        <div *ngIf="mainForm.controls.policyEndDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="policyTerm" class="form-label">Term</label>
                    <input type="text" class="form-control" id="policyTerm" formControlName="policyTerm"
                        [class.is-invalid]="mainForm.controls.policyTerm.touched && mainForm.controls.policyTerm.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.policyTerm.errors">
                        <div *ngIf="mainForm.controls.policyTerm.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="policyRenewalDate" class="form-label">Renewal Date</label>
                    <input type="date" class="form-control" id="policyRenewalDate" formControlName="policyRenewalDate"
                        [class.is-invalid]="mainForm.controls.policyRenewalDate.touched && mainForm.controls.policyRenewalDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.policyRenewalDate.errors">
                        <div *ngIf="mainForm.controls.policyRenewalDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="policyTerminationDate" class="form-label">Termination Date</label>
                    <input type="date" class="form-control" id="policyTerminationDate"
                        formControlName="policyTerminationDate"
                        [class.is-invalid]="mainForm.controls.policyTerminationDate.touched && mainForm.controls.policyTerminationDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.policyTerminationDate.errors">
                        <div *ngIf="mainForm.controls.policyTerminationDate.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>









        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Policy Status</h4>

                <div class="mb-3">
                    <label for="policyDocumentStatusId" class="form-label">Current Status</label>
                    <ng-select [items]="statuses" bindLabel="name" bindValue="id" id="policyDocumentStatusId"
                        formControlName="policyDocumentStatusId" placeholder="Select a request status"
                        [clearable]="false"
                        [class.is-invalid]="mainForm.controls.policyDocumentStatusId.touched && mainForm.controls.policyDocumentStatusId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.policyDocumentStatusId.errors">
                        <div *ngIf="mainForm.controls.policyDocumentStatusId.errors.required">Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <label for="policyApprover" class="form-label">Persons who approved policy</label>
                    <input type="text" class="form-control" id="policyApprover" formControlName="policyApprover"
                        [class.is-invalid]="mainForm.controls.policyApprover.touched && mainForm.controls.policyApprover.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.policyApprover.errors">
                        <div *ngIf="mainForm.controls.policyApprover.errors.required">Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <label for="policyGranted" class="form-label">Policy Granted</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="policyGranted" id="policyGrantedYes"
                                [value]="true" formControlName="policyGranted"
                                [class.is-invalid]="mainForm.controls.policyGranted.touched && mainForm.controls.policyGranted.invalid">
                            <label class="form-check-label" for="policyGrantedYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="policyGranted" id="policyGrantedNo"
                                [value]="false" formControlName="policyGranted"
                                [class.is-invalid]="mainForm.controls.policyGranted.touched && mainForm.controls.policyGranted.invalid">
                            <label class="form-check-label" for="policyGrantedNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.policyGranted.errors">
                        <div *ngIf="mainForm.controls.policyGranted.errors.required">Required</div>
                    </div>
                </div>


            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Risk Rating</h4>
                <div class="mb-3">
                    <label for="documentRiskRatingId" class="form-label"
                        [class.is-invalid]="mainForm.controls.documentRiskRatingId.touched && mainForm.controls.documentRiskRatingId.invalid">Risk Rating</label>
                    <ng-select [items]="riskRatings" bindLabel="name" bindValue="id"
                        id="documentRiskRatingId" formControlName="documentRiskRatingId"
                        placeholder="Select a risk rating" [clearable]="false"
                        [class.is-invalid]="mainForm.controls.documentRiskRatingId.touched && mainForm.controls.documentRiskRatingId.invalid"></ng-select>
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.documentRiskRatingId.errors">
                        <div *ngIf="mainForm.controls.documentRiskRatingId.errors.required">Required</div>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-end">
            <button type="button" class="btn btn-default" routerLink="/dashboard/documents/policies">Cancel</button>&nbsp;
            <button type="submit" class="btn btn-primary">Submit</button>
        </div>
    </form>




</div>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Send Correspondence</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="correspondenceForm" (ngSubmit)="submitCorrespondence()">

            <div class="mb-3">
                <label for="documentCorrespondenceTypeId" class="form-label">Type</label>
                <ng-select [items]="documentCorrespondenceTypes" bindLabel="name" bindValue="id"
                    id="documentCorrespondenceTypeId"
                    [class.is-invalid]="correspondenceForm.controls.documentCorrespondenceTypeId.touched && correspondenceForm.controls.documentCorrespondenceTypeId.invalid"
                    formControlName="documentCorrespondenceTypeId" placeholder="Select a template"></ng-select>
                <div class="invalid-feedback text-end"
                    *ngIf="correspondenceForm.controls.documentCorrespondenceTypeId.errors">
                    <div *ngIf="correspondenceForm.controls.documentCorrespondenceTypeId.errors.required">Required</div>
                </div>

            </div>

            <div class="mb-3">
                <label for="sender" class="form-label">Sender</label>
                <input type="text" class="form-control" id="sender" formControlName="sender"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.sender.touched) && correspondenceForm.controls.sender.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.sender.errors">
                    <div *ngIf="correspondenceForm.controls.sender.errors.required">Required</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="name" class="form-label">Name</label>
                <input type="text" class="form-control" id="name" formControlName="name"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.name.touched) && correspondenceForm.controls.name.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.name.errors">
                    <div *ngIf="correspondenceForm.controls.name.errors.required">Required</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input type="text" class="form-control" id="email" formControlName="email"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.email.touched) && correspondenceForm.controls.email.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.email.errors">
                    <div *ngIf="correspondenceForm.controls.email.errors.required">Required</div>
                    <div *ngIf="correspondenceForm.controls.email.errors.email">Invalid</div>
                </div>
            </div>

            <div class="mb-3">
                <label for="documentFileData" class="form-label">Document</label>
                <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                    (change)="fileUpload(correspondenceForm.controls.documentFileData, $event)"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.documentFileData.touched) && correspondenceForm.controls.documentFileData.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.documentFileData.errors">
                    <div *ngIf="correspondenceForm.controls.documentFileData.errors.required">Required</div>
                </div>
            </div>


            <div *ngFor="let attachment of correspondenceForm.controls.attachments.controls; let i = index"
                formArrayName="attachments">
                <div class="mb-3">
                    <label for="attachmentFileData" class="form-label">Attachment {{i+1}}</label>
                    <div class="input-group">
                        <input type="file" class="form-control" id="attachmentFileData" [formControl]="attachment"
                            (change)="fileUpload(attachment, $event)"
                            [class.is-invalid]="(correspondenceFormSubmitted || attachment.touched) && attachment.invalid">
                        <button type="button" class="btn btn-danger" (click)="removeAttachement(i)">X</button>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="attachment.errors">
                        <div *ngIf="attachment.errors.required">Required</div>
                    </div>

                </div>

            </div>
            <div class="text-end">
                <button type="button" class="btn btn-link" (click)="addAttachement()">+ Add Attachment</button>
            </div>

            <div class="text-end">
                <button type="button" class="btn btn-default" (click)="modal.dismiss()">Cancel</button>&nbsp;
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </form>
    </div>

</ng-template>