<div class="mb-1" *ngIf="superAdmin || orgAdmin">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.apiCompanyId">

    <div class="card mb-3" *ngIf="currentDocumentId != 0">
        <div class="card-body">
            <h4 class="card-title">Workflow</h4>
            <div class="mb-3 d-flex justify-content-between align-items-center">
                Correspondence
                <button class="btn btn-primary" (click)="sendCorrespondence(content)">Send</button>
            </div>
            <hr />
            <div class="mb-3 d-flex justify-content-between align-items-center">
                Workflow
                <div>

                    <button class="btn btn-primary me-2" (click)="viewCorrespondence()">{{ showCorrespondence ? 'Hide': 'View'}}</button>
                    <button class="btn btn-primary" (click)="exportCorrespondence()">Export</button>
                </div>


            </div>
            <div *ngIf="showCorrespondence">

                <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
                    <tr>
                        <th>DATE/TIME</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>SENDER</th>
                        <th>TYPE</th>
                        <th>DOCUMENT</th>
                        <th>ATTACHMENTS</th>
                    </tr>
                    <tr *ngFor="let item of correspondence">
                        <td>{{item.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.sender}}</td>
                        <td>{{item.documentCorrespondenceType}}</td>

                        <td class="text-center"><a href="#" (click)="download($event, item.id)"
                                class="d-flex align-items-center"><span class="icon icon-view-red me-2"></span></a></td>
                        <td class="text-center">
                            <div class="d-flex">
                                <a href="#" *ngFor="let attachment of item.attachments"
                                    (click)="downloadAttachment($event, attachment.id, item.id)"
                                    class="d-flex align-items-center">
                                    <span class="icon icon-view-red me-1"></span>
                                </a>
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    </div>

    <div class="text-end mb-2"><button class="btn btn-primary" (click)="print()">Export</button></div>
    <form id="mainForm" [formGroup]="mainForm" (ngSubmit)="submit()">


        <div class="card mb-3" *ngIf="currentDocumentId == 0">
            <div class="card-body">
                <h4 class="card-title">Request Form</h4>




                <div class="mb-3">
                    <label for="documentFileData" class="form-label"
                        [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid">Request
                        Form</label>
                    <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                        [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid"
                        (change)="fileUpload(mainForm.controls.documentFileData, $event)">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFileData.errors">
                        <div *ngIf="mainForm.controls.documentFileData.errors.required">Required</div>
                    </div>


                </div>





            </div>
        </div>

        <div class="card mb-3" *ngIf="currentDocumentId > 0">
            <div class="card-body">
                <h4 class="card-title">Request Form</h4>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="documentFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid">Update
                            Request Form</label>
                    </div>

                    <div *ngIf="currentDocument?.documentFilePath" class="mb-2">



                        <div class="d-flex align-items-center">
                            <button *ngIf="currentDocument.documentFilePath" type="button"
                                (click)="downloadDocumentFile($event, 'Document')"
                                class="btn btn-link d-flex justify-content-between align-items-center me-2"><span
                                    class="icon icon-view-red"></span>&nbsp;DOWNLOAD CURRENT</button>
                            <button type="button" class="btn btn-primary me-2" [disabled]="!currentDocument.documentHistory || currentDocument.documentHistory.length == 0"
                                (click)="showHistory = !showHistory; showDocumentUpload = false;">{{showHistory
                                ? 'HIDE' : 'SHOW'}} History</button>
                            <button *ngIf="!showDocumentUpload" type="button" class="btn btn-primary"
                                (click)="showDocumentUpload = true; showHistory = false;">Upload new</button>
                            <button *ngIf="showDocumentUpload" type="button" class=" btn btn-primary"
                                (click)="showDocumentUpload = false;">Cancel</button>

                        </div>
                        <div *ngIf="showHistory" class="mb-2">
                            <button *ngFor="let doc of currentDocument.documentHistory" type="button"
                                (click)="downloadDocumentHistoryFile($event, doc.id)"
                                class="btn btn-link d-flex justify-content-between align-items-center"><span
                                    class="icon icon-view-red"></span>&nbsp;{{doc.createdAt | date:'yyyy-MM-dd HH:mm:ss'
                                }}</button>
                        </div>

                        <div class="mb-2">

                        </div>

                        <div *ngIf="!currentDocument?.documentFilePath || showDocumentUpload" class="mb-2">
                            <input type="file" class="form-control" id="documentFileData"
                                formControlName="documentFileData"
                                [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid"
                                (change)="fileUpload(mainForm.controls.documentFileData, $event)">
                            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFileData.errors">
                                <div *ngIf="mainForm.controls.documentFileData.errors.required">Required</div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>



        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Accountable recipient of the request</h4>

                <div class="mb-3">
                    <label for="recipientFirstname" class="form-label"
                        [class.is-invalid]="mainForm.controls.recipientFirstname.touched && mainForm.controls.recipientFirstname.invalid">First
                        Name</label>
                    <input type="text" class="form-control" id="recipientFirstname" formControlName="recipientFirstname"
                        [class.is-invalid]="mainForm.controls.recipientFirstname.touched && mainForm.controls.recipientFirstname.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recipientFirstname.errors">
                        <div *ngIf="mainForm.controls.recipientFirstname.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="recipientLastname" class="form-label"
                        [class.is-invalid]="mainForm.controls.recipientLastname.touched && mainForm.controls.recipientLastname.invalid">Last
                        Name</label>
                    <input type="text" class="form-control" id="recipientLastname" formControlName="recipientLastname"
                        [class.is-invalid]="mainForm.controls.recipientLastname.touched && mainForm.controls.recipientLastname.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recipientLastname.errors">
                        <div *ngIf="mainForm.controls.recipientLastname.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="recipientContactNumber" class="form-label"
                        [class.is-invalid]="mainForm.controls.recipientContactNumber.touched && mainForm.controls.recipientContactNumber.invalid">Contact
                        Number</label>
                    <input type="text" class="form-control" id="recipientContactNumber"
                        formControlName="recipientContactNumber"
                        [class.is-invalid]="mainForm.controls.recipientContactNumber.touched && mainForm.controls.recipientContactNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recipientContactNumber.errors">
                        <div *ngIf="mainForm.controls.recipientContactNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="recipientEmail" class="form-label"
                        [class.is-invalid]="mainForm.controls.recipientEmail.touched && mainForm.controls.recipientEmail.invalid">Email</label>
                    <input type="text" class="form-control" id="recipientEmail" formControlName="recipientEmail"
                        [class.is-invalid]="mainForm.controls.recipientEmail.touched && mainForm.controls.recipientEmail.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recipientEmail.errors">
                        <div *ngIf="mainForm.controls.recipientEmail.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.recipientEmail.errors.email">Invalid</div>
                    </div>
                </div>

            </div>
        </div>

        <!-- <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Applicable facility or department</h4>

                <div class="mb-3">
                    <label for="documentRegionId" class="form-label">Region</label>
                    <ng-select [items]="regions" bindLabel="name" bindValue="id" id="documentRegionId"
                        formControlName="documentRegionId" placeholder="Select a region" [clearable]="false"
                        [class.is-invalid]="mainForm.controls.documentRegionId.touched && mainForm.controls.documentRegionId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentRegionId.errors">
                        <div *ngIf="mainForm.controls.documentRegionId.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="documentFacilityId" class="form-label">Facility</label>
                    <ng-select [items]="facilities" bindLabel="name" bindValue="id" id="documentFacilityId"
                        formControlName="documentFacilityId" placeholder="Select a facility" [clearable]="false"
                        [readonly]="facilities == undefined"
                        [class.is-invalid]="mainForm.controls.documentFacilityId.touched && mainForm.controls.documentFacilityId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFacilityId.errors">
                        <div *ngIf="mainForm.controls.documentFacilityId.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div> -->

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Requestor's Details</h4>


                <div class="mb-3">
                    <label for="receivedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.receivedDate.touched && mainForm.controls.receivedDate.invalid">Date
                        Request was Received</label>
                    <input type="date" class="form-control" id="receivedDate" formControlName="receivedDate"
                        [class.is-invalid]="mainForm.controls.receivedDate.touched && mainForm.controls.receivedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.receivedDate.errors">
                        <div *ngIf="mainForm.controls.receivedDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="acknowledgedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.acknowledgedDate.touched && mainForm.controls.acknowledgedDate.invalid">Date
                        Request was Acknowledged</label>
                    <input type="date" class="form-control" id="acknowledgedDate" formControlName="acknowledgedDate"
                        [class.is-invalid]="mainForm.controls.acknowledgedDate.touched && mainForm.controls.acknowledgedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.acknowledgedDate.errors">
                        <div *ngIf="mainForm.controls.acknowledgedDate.errors.required">Required</div>
                    </div>
                </div>

                <h5>Company / Person Details</h5>

                <div class="mb-3">
                    <label for="companyPersonIDNumber" class="form-label"
                        [class.is-invalid]="mainForm.controls.companyPersonIDNumber.touched && mainForm.controls.companyPersonIDNumber.invalid">ID
                        Number / Company Registration Number</label>
                    <input type="text" class="form-control" id="companyPersonIDNumber"
                        formControlName="companyPersonIDNumber"
                        [class.is-invalid]="mainForm.controls.companyPersonIDNumber.touched && mainForm.controls.companyPersonIDNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.companyPersonIDNumber.errors">
                        <div *ngIf="mainForm.controls.companyPersonIDNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="companyPersonName" class="form-label"
                        [class.is-invalid]="mainForm.controls.companyPersonName.touched && mainForm.controls.companyPersonName.invalid">Name
                        & Surname / Company Name</label>
                    <input type="text" class="form-control" id="companyPersonName" formControlName="companyPersonName"
                        [class.is-invalid]="mainForm.controls.companyPersonName.touched && mainForm.controls.companyPersonName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.companyPersonName.errors">
                        <div *ngIf="mainForm.controls.companyPersonName.errors.required">Required</div>
                    </div>
                </div>
                
                <div class="mb-3">
                    <label for="departmentId" class="form-label"
                        [class.is-invalid]="mainForm.controls.departmentId.touched && mainForm.controls.departmentId.invalid">Department</label>
                    <ng-select [items]="departments" bindLabel="name" bindValue="id" id="departmentId"
                        formControlName="departmentId" placeholder="Select a department"
                        [clearable]="false"
                        [class.is-invalid]="mainForm.controls.departmentId.touched && mainForm.controls.departmentId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.departmentId.errors">
                        <div *ngIf="mainForm.controls.departmentId.errors.required">Required</div>
                    </div>
                </div>

                <hr />

                <h5>Affected Parties</h5>

                <ng-container formArrayName="matterManagementAffectedParties">
                    <ng-container
                        *ngFor="let affectedPartyForm of mainForm.controls.matterManagementAffectedParties.controls; let i = index">

                        <div [formGroup]="affectedPartyForm" class="mb-2">
                            <h5>Party {{i + 1}}</h5>
                            <input type="hidden" formControlName="id" id="id">
                            <div class="mb-3">
                                <label for="firstname" class="form-label">First name</label>
                                <input type="text" class="form-control" id="firstname" formControlName="firstname"
                                    [class.is-invalid]="affectedPartyForm.controls.firstname.touched && affectedPartyForm.controls.firstname.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="affectedPartyForm.controls.firstname.errors">
                                    <div *ngIf="affectedPartyForm.controls.firstname.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="lastname" class="form-label">Last name</label>
                                <input type="text" class="form-control" id="lastname" formControlName="lastname"
                                    [class.is-invalid]="affectedPartyForm.controls.lastname.touched && affectedPartyForm.controls.lastname.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="affectedPartyForm.controls.lastname.errors">
                                    <div *ngIf="affectedPartyForm.controls.lastname.errors.required">Required
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="idNumber" class="form-label">ID Number</label>
                                <input type="text" class="form-control" id="idNumber" formControlName="idNumber"
                                    [class.is-invalid]="affectedPartyForm.controls.idNumber.touched && affectedPartyForm.controls.idNumber.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="affectedPartyForm.controls.idNumber.errors">
                                    <div *ngIf="affectedPartyForm.controls.idNumber.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="company" class="form-label">Company</label>
                                <input type="text" class="form-control" id="company" formControlName="company"
                                    [class.is-invalid]="affectedPartyForm.controls.company.touched && affectedPartyForm.controls.company.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="affectedPartyForm.controls.company.errors">
                                    <div *ngIf="affectedPartyForm.controls.company.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="companyRegistrationNumber" class="form-label">Company Registration
                                    Number</label>
                                <input type="text" class="form-control" id="companyRegistrationNumber"
                                    formControlName="companyRegistrationNumber"
                                    [class.is-invalid]="affectedPartyForm.controls.companyRegistrationNumber.touched && affectedPartyForm.controls.companyRegistrationNumber.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="affectedPartyForm.controls.companyRegistrationNumber.errors">
                                    <div *ngIf="affectedPartyForm.controls.companyRegistrationNumber.errors.required">
                                        Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="contactNumber" class="form-label">Contact Number</label>
                                <input type="text" class="form-control" id="contactNumber"
                                    formControlName="contactNumber"
                                    [class.is-invalid]="affectedPartyForm.controls.contactNumber.touched && affectedPartyForm.controls.contactNumber.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="affectedPartyForm.controls.contactNumber.errors">
                                    <div *ngIf="affectedPartyForm.controls.contactNumber.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="email" class="form-label">Email</label>
                                <input type="text" class="form-control" id="email" formControlName="email"
                                    [class.is-invalid]="affectedPartyForm.controls.email.touched && affectedPartyForm.controls.email.invalid">
                                <div class="invalid-feedback text-end" *ngIf="affectedPartyForm.controls.email.errors">
                                    <div *ngIf="affectedPartyForm.controls.email.errors.required">Required</div>
                                    <div *ngIf="affectedPartyForm.controls.email.errors.email">Invalid</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="address" class="form-label">Address</label>
                                <input type="text" class="form-control" id="address" formControlName="address"
                                    [class.is-invalid]="affectedPartyForm.controls.address.touched && affectedPartyForm.controls.address.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="affectedPartyForm.controls.address.errors">
                                    <div *ngIf="affectedPartyForm.controls.address.errors.required">Required</div>
                                </div>
                            </div>


                            <div class="text-end" *ngIf="mainForm.controls.matterManagementAffectedParties.length > 1">
                                <button type="button" class="btn btn-default"
                                    (click)="removeAffectedParty(i)">Remove</button>&nbsp;

                            </div>

                        </div>
                        <hr />
                    </ng-container>
                </ng-container>
                <div class="text-end">
                    <button type="button" class="btn btn-default" (click)="addAffectedParty()">Add</button>&nbsp;

                </div>




                <hr />

                <h5>Details of Matter</h5>


                <!-- <div class="mb-3">
                    <label for="requestType" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestType.touched && mainForm.controls.requestType.invalid">Type
                        of request</label>
                    <input type="text" class="form-control" id="requestType" formControlName="requestType"
                        [class.is-invalid]="mainForm.controls.requestType.touched && mainForm.controls.requestType.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestType.errors">
                        <div *ngIf="mainForm.controls.requestType.errors.required">Required</div>
                    </div>
                </div> -->

                <div class="mb-3">
                    <label for="matterManagementDocumentRequestTypeId" class="form-label"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentRequestTypeId.touched && mainForm.controls.matterManagementDocumentRequestTypeId.invalid">Type
                        of Request</label>
   
                    <ng-select [items]="requestTypes" bindLabel="name" bindValue="id" id="matterManagementDocumentRequestTypeId"
                        formControlName="matterManagementDocumentRequestTypeId" placeholder="Select a request type"
                        [clearable]="false"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentRequestTypeId.touched && mainForm.controls.matterManagementDocumentRequestTypeId.invalid"></ng-select>

                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.matterManagementDocumentRequestTypeId.errors">
                        <div *ngIf="mainForm.controls.matterManagementDocumentRequestTypeId.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" [class.d-none]="mainForm.controls.matterManagementDocumentRequestTypeId.value != requestTypeOther.id">
                    <label for="matterManagementDocumentRequestTypeOther" class="form-label"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentRequestTypeOther.touched && mainForm.controls.matterManagementDocumentRequestTypeOther.invalid">Type
                        of request details</label>
                    <input type="text" class="form-control" id="matterManagementDocumentRequestTypeOther" formControlName="matterManagementDocumentRequestTypeOther" bindLabel="name" bindValue="id"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentRequestTypeOther.touched && mainForm.controls.matterManagementDocumentRequestTypeOther.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.matterManagementDocumentRequestTypeOther.errors">
                        <div *ngIf="mainForm.controls.matterManagementDocumentRequestTypeOther.errors.required">Required</div>
                    </div>
                </div>




                <div class="mb-3">
                    <label for="requestDetails" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestDetails.touched && mainForm.controls.requestDetails.invalid">Describe
                        what information and records are
                        required</label>
                    <textarea type="text" class="form-control" id="requestDetails" formControlName="requestDetails"
                        [class.is-invalid]="mainForm.controls.requestDetails.touched && mainForm.controls.requestDetails.invalid"></textarea>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestDetails.errors">
                        <div *ngIf="mainForm.controls.requestDetails.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestReason" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestReason.touched && mainForm.controls.requestReason.invalid">Describe
                        the reasons why the case has been opened</label>
                    <textarea type="text" class="form-control" id="requestReason" formControlName="requestReason"
                        [class.is-invalid]="mainForm.controls.requestReason.touched && mainForm.controls.requestReason.invalid"></textarea>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestReason.errors">
                        <div *ngIf="mainForm.controls.requestReason.errors.required">Required</div>
                    </div>
                </div>

                <hr />

                <div class="mb-3">
                    <label for="incidentDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.incidentDate.touched && mainForm.controls.incidentDate.invalid">Date
                        to which the matter relates</label>
                    <input type="date" class="form-control" id="incidentDate" formControlName="incidentDate"
                        [class.is-invalid]="mainForm.controls.incidentDate.touched && mainForm.controls.incidentDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.incidentDate.errors">
                        <div *ngIf="mainForm.controls.incidentDate.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Status of request</h4>

                <div class="mb-3">
                    <label for="matterManagementDocumentStatusId" class="form-label"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentStatusId.touched && mainForm.controls.matterManagementDocumentStatusId.invalid">What
                        is the current status of the
                        request</label>
                    <ng-select [items]="statuses" bindLabel="name" bindValue="id" id="matterManagementDocumentStatusId"
                        formControlName="matterManagementDocumentStatusId" placeholder="Select a request status"
                        [clearable]="false"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentStatusId.touched && mainForm.controls.matterManagementDocumentStatusId.invalid"></ng-select>
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.matterManagementDocumentStatusId.errors">
                        <div *ngIf="mainForm.controls.matterManagementDocumentStatusId.errors.required">Required</div>
                    </div>
                </div>



            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Disbursements</h4>


                <div class="mb-3">
                    <label for="disbursementCost" class="form-label">Cost</label>
                    <input type="number" class="form-control" id="disbursementCost" formControlName="disbursementCost"
                        [class.is-invalid]="mainForm.controls.disbursementCost.touched && mainForm.controls.disbursementCost.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.disbursementCost.errors">
                        <div *ngIf="mainForm.controls.disbursementCost.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.disbursementCost.errors.min">Invalid number</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="disbursementCostPerMonth" class="form-label">Cost per Month</label>
                    <input type="number" class="form-control" id="disbursementCostPerMonth" formControlName="disbursementCostPerMonth"
                        [class.is-invalid]="mainForm.controls.disbursementCostPerMonth.touched && mainForm.controls.disbursementCostPerMonth.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.disbursementCostPerMonth.errors">
                        <div *ngIf="mainForm.controls.disbursementCostPerMonth.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.disbursementCostPerMonth.errors.min">Invalid number</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="disbursementCostPerAnnum" class="form-label">Cost per Annum</label>
                    <input type="number" class="form-control" id="disbursementCostPerAnnum" formControlName="disbursementCostPerAnnum"
                        [class.is-invalid]="mainForm.controls.disbursementCostPerAnnum.touched && mainForm.controls.disbursementCostPerAnnum.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.disbursementCostPerAnnum.errors">
                        <div *ngIf="mainForm.controls.disbursementCostPerAnnum.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.disbursementCostPerAnnum.errors.min">Invalid number</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="disbursementIncreasePerAnnum" class="form-label">Increase per Annum</label>
                    <input type="number" class="form-control" id="disbursementIncreasePerAnnum"
                        formControlName="disbursementIncreasePerAnnum"
                        [class.is-invalid]="mainForm.controls.disbursementIncreasePerAnnum.touched && mainForm.controls.disbursementIncreasePerAnnum.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.disbursementIncreasePerAnnum.errors">
                        <div *ngIf="mainForm.controls.disbursementIncreasePerAnnum.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.disbursementIncreasePerAnnum.errors.min">Invalid number</div>
                    </div>
                </div>

            </div>
        </div>
        

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Deal Value</h4>

                <div class="mb-3">
                    <label for="dealValue" class="form-label">Anticipated value</label>
                    <input type="number" class="form-control" id="dealValue" formControlName="dealValue"
                        [class.is-invalid]="mainForm.controls.dealValue.touched && mainForm.controls.dealValue.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.dealValue.errors">
                        <div *ngIf="mainForm.controls.dealValue.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.dealValue.errors.min">Invalid number</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="dealValuePerMonth" class="form-label">Per month</label>
                    <input type="number" class="form-control" id="dealValuePerMonth"
                        formControlName="dealValuePerMonth"
                        [class.is-invalid]="mainForm.controls.dealValuePerMonth.touched && mainForm.controls.dealValuePerMonth.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.dealValuePerMonth.errors">
                        <div *ngIf="mainForm.controls.dealValuePerMonth.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.dealValuePerMonth.errors.min">Invalid number</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="dealValuePerAnnum" class="form-label">Per annum</label>
                    <input type="number" class="form-control" id="dealValuePerAnnum"
                        formControlName="dealValuePerAnnum"
                        [class.is-invalid]="mainForm.controls.dealValuePerAnnum.touched && mainForm.controls.dealValuePerAnnum.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.dealValuePerAnnum.errors">
                        <div *ngIf="mainForm.controls.dealValuePerAnnum.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.dealValuePerAnnum.errors.min">Invalid number</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="dealIncreasePerAnnum" class="form-label">Increase per annum</label>
                    <input type="number" class="form-control" id="dealIncreasePerAnnum"
                        formControlName="dealIncreasePerAnnum"
                        [class.is-invalid]="mainForm.controls.dealIncreasePerAnnum.touched && mainForm.controls.dealIncreasePerAnnum.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.dealIncreasePerAnnum.errors">
                        <div *ngIf="mainForm.controls.dealIncreasePerAnnum.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.dealIncreasePerAnnum.errors.min">Invalid number</div>
                    </div>
                </div>



            </div>
        </div>
        
        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">TIME & ATTENDANCE COST</h4>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Hourly Rate</th>
                            <th>Time</th>
                            <th>Total</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container formArrayName="matterManagementDocumentCosts">
                            <ng-container
                                *ngFor="let matterManagementDocumentCostForm of mainForm.controls.matterManagementDocumentCosts.controls; let i = index">

                                <tr [formGroup]="matterManagementDocumentCostForm" class="mb-2">
                                    <td>
                                        <input type="date" class="form-control" id="date" formControlName="date"
                                            [class.is-invalid]="matterManagementDocumentCostForm.controls.date.touched && matterManagementDocumentCostForm.controls.date.invalid"
                                            placeholder="Date">
                                        <div class="invalid-feedback text-end"
                                            *ngIf="matterManagementDocumentCostForm.controls.date.errors">
                                            <div *ngIf="matterManagementDocumentCostForm.controls.date.errors.required">
                                                Required</div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" id="description"
                                            formControlName="description"
                                            [class.is-invalid]="matterManagementDocumentCostForm.controls.description.touched && matterManagementDocumentCostForm.controls.description.invalid"
                                            placeholder="Description">
                                        <div class="invalid-feedback text-end"
                                            *ngIf="matterManagementDocumentCostForm.controls.description.errors">
                                            <div
                                                *ngIf="matterManagementDocumentCostForm.controls.description.errors.required">
                                                Required</div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" id="rate" formControlName="rate"
                                            [class.is-invalid]="matterManagementDocumentCostForm.controls.rate.touched && matterManagementDocumentCostForm.controls.rate.invalid"
                                            placeholder="Hourly Rate">
                                        <div class="invalid-feedback text-end"
                                            *ngIf="matterManagementDocumentCostForm.controls.rate.errors">
                                            <div *ngIf="matterManagementDocumentCostForm.controls.rate.errors.required">
                                                Required</div>
                                            <div *ngIf="matterManagementDocumentCostForm.controls.rate.errors.min">
                                                Invalid</div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" id="time" formControlName="time"
                                            [class.is-invalid]="matterManagementDocumentCostForm.controls.time.touched && matterManagementDocumentCostForm.controls.time.invalid"
                                            placeholder="Time">
                                        <div class="invalid-feedback text-end"
                                            *ngIf="matterManagementDocumentCostForm.controls.time.errors">
                                            <div *ngIf="matterManagementDocumentCostForm.controls.time.errors.required">
                                                Required</div>
                                            <div *ngIf="matterManagementDocumentCostForm.controls.time.errors.min">
                                                Invalid</div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" readonly placeholder="Total"
                                            [value]="matterManagementDocumentCostForm.controls.time.value * matterManagementDocumentCostForm.controls.rate.value" />
                                    </td>

                                    <td class="text-center">
                                        <button type="button" class="btn btn-link" (click)="removeCost(i)">X</button>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>

                    </tbody>
                </table>
                <div class="text-end">
                    <button type="button" class="btn btn-default" (click)="addCost()">Add</button>&nbsp;

                </div>


            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Response Outcome/ Decision</h4>

                <div class="mb-3">
                    <label for="decision" class="form-label"
                        [class.is-invalid]="mainForm.controls.decision.touched && mainForm.controls.decision.invalid">Outcome</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="decision" id="decisionNo" [value]="false"
                                formControlName="decision"
                                [class.is-invalid]="mainForm.controls.decision.touched && mainForm.controls.decision.invalid">
                            <label class="form-check-label" for="decisionNo">Open</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="decision" id="decisionYes" [value]="true"
                                formControlName="decision"
                                [class.is-invalid]="mainForm.controls.decision.touched && mainForm.controls.decision.invalid">
                            <label class="form-check-label" for="decisionYes">Settled</label>
                        </div>

                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.decision.errors">
                        <div *ngIf="mainForm.controls.decision.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="decisionReason" class="form-label"
                        [class.is-invalid]="mainForm.controls.decisionReason.touched && mainForm.controls.decisionReason.invalid">Reason
                        for outcome</label>
                    <input type="text" class="form-control" id="decisionReason" formControlName="decisionReason"
                        [class.is-invalid]="mainForm.controls.decisionReason.touched && mainForm.controls.decisionReason.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.decisionReason.errors">
                        <div *ngIf="mainForm.controls.decisionReason.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="decisionDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.decisionDate.touched && mainForm.controls.decisionDate.invalid">Date
                        of decision</label>
                    <input type="date" class="form-control" id="decisionDate" formControlName="decisionDate"
                        [class.is-invalid]="mainForm.controls.decisionDate.touched && mainForm.controls.decisionDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.decisionDate.errors">
                        <div *ngIf="mainForm.controls.decisionDate.errors.required">Required</div>
                    </div>
                </div>




                <div class="mb-3">
                    <label for="recordsLocation" class="form-label"
                        [class.is-invalid]="mainForm.controls.recordsLocation.touched && mainForm.controls.recordsLocation.invalid">Where
                        are the records housed?</label>
                    <input type="text" class="form-control" id="recordsLocation" formControlName="recordsLocation"
                        [class.is-invalid]="mainForm.controls.recordsLocation.touched && mainForm.controls.recordsLocation.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recordsLocation.errors">
                        <div *ngIf="mainForm.controls.recordsLocation.errors.required">Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <label for="matterManagementDocumentAppealStatusId" class="form-label"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentAppealStatusId.touched && mainForm.controls.matterManagementDocumentAppealStatusId.invalid">Appeal
                        Status</label>
                    <ng-select [items]="appealStatuses" bindLabel="name" bindValue="id"
                        id="matterManagementDocumentAppealStatusId"
                        formControlName="matterManagementDocumentAppealStatusId" placeholder="Select an appeal status"
                        [clearable]="false"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentAppealStatusId.touched && mainForm.controls.matterManagementDocumentAppealStatusId.invalid"></ng-select>
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.matterManagementDocumentAppealStatusId.errors">
                        <div *ngIf="mainForm.controls.matterManagementDocumentAppealStatusId.errors.required">Required
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="matterManagementDocumentReferralId" class="form-label"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentReferralId.touched && mainForm.controls.matterManagementDocumentReferralId.invalid">Referrals</label>
                    <ng-select [items]="referrals" bindLabel="name" bindValue="id"
                        id="matterManagementDocumentReferralId" formControlName="matterManagementDocumentReferralId"
                        placeholder="Select a referral" [clearable]="false"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentReferralId.touched && mainForm.controls.matterManagementDocumentReferralId.invalid"></ng-select>
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.matterManagementDocumentReferralId.errors">
                        <div *ngIf="mainForm.controls.matterManagementDocumentReferralId.errors.required">Required</div>
                    </div>
                </div>


            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Important Dates</h4>


                <div class="mb-3">
                    <label for="caseOpenedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.caseOpenedDate.touched && mainForm.controls.caseOpenedDate.invalid">Date
                        case was opened</label>
                    <input type="date" class="form-control" id="caseOpenedDate" formControlName="caseOpenedDate"
                        [class.is-invalid]="mainForm.controls.caseOpenedDate.touched && mainForm.controls.caseOpenedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.caseOpenedDate.errors">
                        <div *ngIf="mainForm.controls.caseOpenedDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="decisionProvidedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.decisionProvidedDate.touched && mainForm.controls.decisionProvidedDate.invalid">Date
                        decision was provided to the
                        requestor</label>
                    <input type="date" class="form-control" id="decisionProvidedDate"
                        formControlName="decisionProvidedDate">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.decisionProvidedDate.errors"
                        [class.is-invalid]="mainForm.controls.decisionProvidedDate.touched && mainForm.controls.decisionProvidedDate.invalid">
                        <div *ngIf="mainForm.controls.decisionProvidedDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="caseClosedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.caseClosedDate.touched && mainForm.controls.caseClosedDate.invalid">Closed
                        Date</label>
                    <input type="date" class="form-control" id="caseClosedDate" formControlName="caseClosedDate"
                        [class.is-invalid]="mainForm.controls.caseClosedDate.touched && mainForm.controls.caseClosedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.caseClosedDate.errors">
                        <div *ngIf="mainForm.controls.caseClosedDate.errors.required">Required</div>
                    </div>
                </div>

                <!-- <div class="mb-3">
                    <label for="renewalDate" class="form-label">Renewal Date</label>
                    <input type="date" class="form-control" id="renewalDate" formControlName="renewalDate"
                        [class.is-invalid]="mainForm.controls.renewalDate.touched && mainForm.controls.renewalDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.renewalDate.errors">
                        <div *ngIf="mainForm.controls.renewalDate.errors.required">Required</div>
                    </div>
                </div> -->



            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Risk Rating</h4>
                <div class="mb-3">
                    <label for="documentRiskRatingId" class="form-label"
                        [class.is-invalid]="mainForm.controls.documentRiskRatingId.touched && mainForm.controls.documentRiskRatingId.invalid">Risk Rating</label>
                    <ng-select [items]="riskRatings" bindLabel="name" bindValue="id"
                        id="documentRiskRatingId" formControlName="documentRiskRatingId"
                        placeholder="Select a risk rating" [clearable]="false"
                        [class.is-invalid]="mainForm.controls.documentRiskRatingId.touched && mainForm.controls.documentRiskRatingId.invalid"></ng-select>
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.documentRiskRatingId.errors">
                        <div *ngIf="mainForm.controls.documentRiskRatingId.errors.required">Required</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Additional Supporting Documents</h4>


                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="outcomeReportFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.outcomeReportFileData.touched && mainForm.controls.outcomeReportFileData.invalid">Outcome
                            report
                        </label>

                    </div>
                    <div *ngIf="currentDocument?.outcomeReportFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.outcomeReportFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'OutcomeReport')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showOutcomeReportUpload" type="button" class="btn btn-primary"
                            (click)="showOutcomeReportUpload = true;">Upload new</button>
                        <button *ngIf="showOutcomeReportUpload" type="button" class=" btn btn-primary"
                            (click)="showOutcomeReportUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.outcomeReportFilePath || showOutcomeReportUpload">
                        <input type="file" class="form-control" id="outcomeReportFileData"
                            formControlName="outcomeReportFileData"
                            (change)="fileUpload(mainForm.controls.outcomeReportFileData, $event)"
                            [class.is-invalid]="mainForm.controls.outcomeReportFileData.touched && mainForm.controls.outcomeReportFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.outcomeReportFileData.errors">
                            <div *ngIf="mainForm.controls.outcomeReportFileData.errors.required">Required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="letterheadFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.letterheadFileData.touched && mainForm.controls.letterheadFileData.invalid">Certified
                            Copy of data subject
                            ID/Company
                            Letterhead </label>
                    </div>

                    <div *ngIf="currentDocument?.letterheadFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.letterheadFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'Letterhead')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showLetterheadUpload" type="button" class="btn btn-primary"
                            (click)="showLetterheadUpload = true;">Upload new</button>
                        <button *ngIf="showLetterheadUpload" type="button" class=" btn btn-primary"
                            (click)="showLetterheadUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.letterheadFilePath || showLetterheadUpload">
                        <input type="file" class="form-control" id="letterheadFileData"
                            formControlName="letterheadFileData"
                            (change)="fileUpload(mainForm.controls.letterheadFileData, $event)"
                            [class.is-invalid]="mainForm.controls.letterheadFileData.touched && mainForm.controls.letterheadFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.letterheadFileData.errors">
                            <div *ngIf="mainForm.controls.letterheadFileData.errors.required">Required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="consentFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.consentFileData.touched && mainForm.controls.consentFileData.invalid">Signed
                            consent or power of attorney
                            including
                            proof of relationship</label>
                    </div>

                    <div *ngIf="currentDocument?.consentFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.consentFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'Consent')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showConsentUpload" type="button" class="btn btn-primary"
                            (click)="showConsentUpload = true;">Upload new</button>
                        <button *ngIf="showConsentUpload" type="button" class=" btn btn-primary"
                            (click)="showConsentUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.consentFilePath || showConsentUpload">
                        <input type="file" class="form-control" id="consentFileData" formControlName="consentFileData"
                            (change)="fileUpload(mainForm.controls.consentFileData, $event)"
                            [class.is-invalid]="mainForm.controls.consentFileData.touched && mainForm.controls.consentFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.consentFileData.errors">
                            <div *ngIf="mainForm.controls.consentFileData.errors.required">Required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="kycFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.kycFileData.touched && mainForm.controls.kycFileData.invalid">KYC,
                            Due Dilligence, FICA or Otherwise</label>
                    </div>

                    <div *ngIf="currentDocument?.kycFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.kycFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'KYC')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showKYCUpload" type="button" class="btn btn-primary"
                            (click)="showKYCUpload = true;">Upload new</button>
                        <button *ngIf="showKYCUpload" type="button" class=" btn btn-primary"
                            (click)="showKYCUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.kycFilePath || showKYCUpload">
                        <input type="file" class="form-control" id="kycFileData" formControlName="kycFileData"
                            (change)="fileUpload(mainForm.controls.kycFileData, $event)"
                            [class.is-invalid]="mainForm.controls.kycFileData.touched && mainForm.controls.kycFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.kycFileData.errors">
                            <div *ngIf="mainForm.controls.kycFileData.errors.required">Required</div>
                        </div>
                    </div>
                </div>
                <hr />


                <ng-container formArrayName="matterManagementDocumentAdditionalFiles">
                    <ng-container
                        *ngFor="let matterManagementDocumentAdditionalFileForm of mainForm.controls.matterManagementDocumentAdditionalFiles.controls; let i = index">
                        
                        <div [formGroup]="matterManagementDocumentAdditionalFileForm">
                            <ng-container *ngIf="matterManagementDocumentAdditionalFileForm.controls.id.value > 0">
                                <label class="form-label">{{matterManagementDocumentAdditionalFileForm.controls.description.value}}</label>
                                <button type="button"
                                    (click)="downloadAdditionalFile($event, matterManagementDocumentAdditionalFileForm.controls.id.value)"
                                    class="btn btn-link d-flex justify-content-between align-items-center"><span
                                        class="icon icon-view-red"></span>&nbsp;Download</button>
                            </ng-container>
                            <ng-container *ngIf="matterManagementDocumentAdditionalFileForm.controls.id.value == 0">


                                <div class="mb-1">
                                    <label for="description" class="form-label"
                                        [class.is-invalid]="matterManagementDocumentAdditionalFileForm.controls.description.touched && matterManagementDocumentAdditionalFileForm.controls.description.invalid">Description</label>
                                    <input type="text" class="form-control" id="description"
                                        formControlName="description"
                                        [class.is-invalid]="matterManagementDocumentAdditionalFileForm.controls.description.touched && matterManagementDocumentAdditionalFileForm.controls.description.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="matterManagementDocumentAdditionalFileForm.controls.description.errors">
                                        <div
                                            *ngIf="matterManagementDocumentAdditionalFileForm.controls.description.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <input type="file" class="form-control" id="fileData" formControlName="fileData"
                                        placeholder="Additional File"
                                        (change)="fileUpload(matterManagementDocumentAdditionalFileForm.controls.fileData, $event)"
                                        [class.is-invalid]="matterManagementDocumentAdditionalFileForm.controls.fileData.touched && matterManagementDocumentAdditionalFileForm.controls.fileData.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="matterManagementDocumentAdditionalFileForm.controls.fileData.errors">
                                        <div
                                            *ngIf="matterManagementDocumentAdditionalFileForm.controls.fileData.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>

                <div class="text-end">
                    <button type="button" class="btn btn-default" (click)="addFile()">Add Additional File</button>&nbsp;

                </div>




            </div>
        </div>

        <div class="text-end">
            <button type="button" class="btn btn-default"
                routerLink="/dashboard/documents/matter-management">Cancel</button>&nbsp;
            <button type="submit" class="btn btn-primary">Submit</button>
        </div>
    </form>




</div>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Send Correspondence</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="correspondenceForm" (ngSubmit)="submitCorrespondence()">

            <div class="mb-3">
                <label for="documentCorrespondenceTypeId" class="form-label"
                    [class.is-invalid]="correspondenceForm.controls.documentCorrespondenceTypeId.touched && correspondenceForm.controls.documentCorrespondenceTypeId.invalid">Type</label>
                <ng-select [items]="documentCorrespondenceTypes" bindLabel="name" bindValue="id"
                    id="documentCorrespondenceTypeId"
                    [class.is-invalid]="correspondenceForm.controls.documentCorrespondenceTypeId.touched && correspondenceForm.controls.documentCorrespondenceTypeId.invalid"
                    formControlName="documentCorrespondenceTypeId" placeholder="Select a template"></ng-select>
                <div class="invalid-feedback text-end"
                    *ngIf="correspondenceForm.controls.documentCorrespondenceTypeId.errors">
                    <div *ngIf="correspondenceForm.controls.documentCorrespondenceTypeId.errors.required">Required</div>
                </div>

            </div>

            <div class="mb-3">
                <label for="sender" class="form-label"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.sender.touched) && correspondenceForm.controls.sender.invalid">Sender</label>
                <input type="text" class="form-control" id="sender" formControlName="sender"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.sender.touched) && correspondenceForm.controls.sender.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.sender.errors">
                    <div *ngIf="correspondenceForm.controls.sender.errors.required">Required</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="name" class="form-label"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.name.touched) && correspondenceForm.controls.name.invalid">Name</label>
                <input type="text" class="form-control" id="name" formControlName="name"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.name.touched) && correspondenceForm.controls.name.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.name.errors">
                    <div *ngIf="correspondenceForm.controls.name.errors.required">Required</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="email" class="form-label"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.email.touched) && correspondenceForm.controls.email.invalid">Email</label>
                <input type="text" class="form-control" id="email" formControlName="email"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.email.touched) && correspondenceForm.controls.email.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.email.errors">
                    <div *ngIf="correspondenceForm.controls.email.errors.required">Required</div>
                    <div *ngIf="correspondenceForm.controls.email.errors.email">Invalid</div>
                </div>
            </div>

            <div class="mb-3">
                <label for="documentFileData" class="form-label"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.documentFileData.touched) && correspondenceForm.controls.documentFileData.invalid">Document</label>
                <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                    (change)="fileUpload(correspondenceForm.controls.documentFileData, $event)"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.documentFileData.touched) && correspondenceForm.controls.documentFileData.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.documentFileData.errors">
                    <div *ngIf="correspondenceForm.controls.documentFileData.errors.required">Required</div>
                </div>
            </div>

            <div *ngFor="let attachment of correspondenceForm.controls.attachments.controls; let i = index"
                formArrayName="attachments">
                <div class="mb-3">
                    <label for="attachmentFileData" class="form-label"
                        [class.is-invalid]="(correspondenceFormSubmitted || attachment.touched) && attachment.invalid">Attachment
                        {{i+1}}</label>
                    <div class="input-group">
                        <input type="file" class="form-control" id="attachmentFileData" [formControl]="attachment"
                            (change)="fileUpload(attachment, $event)"
                            [class.is-invalid]="(correspondenceFormSubmitted || attachment.touched) && attachment.invalid">
                        <button type="button" class="btn btn-danger" (click)="removeAttachement(i)">X</button>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="attachment.errors">
                        <div *ngIf="attachment.errors.required">Required</div>
                    </div>

                </div>

            </div>
            <div class="text-end">
                <button type="button" class="btn btn-link" (click)="addAttachement()">+ Add Attachment</button>
            </div>


            <div class="text-end">
                <button type="button" class="btn btn-default" (click)="modal.dismiss()">Cancel</button>&nbsp;
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </form>
    </div>

</ng-template>