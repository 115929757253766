<div class="mb-1" *ngIf="superAdmin || orgAdmin">
    
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.apiCompanyId">
    <h2>Documents</h2>
    <div class="listing flex-grow-1 p-3 mb-1"><a href="#" routerLink="/dashboard/documents/paia"><h4>PAIA Requests</h4></a></div>
    <div class="listing flex-grow-1 p-3 mb-1"><a href="#" routerLink="/dashboard/documents/fica"><h4>KYC and Due Diligence</h4></a></div>
    <div class="listing flex-grow-1 p-3 mb-1"><a href="#" routerLink="/dashboard/documents/contracts"><h4>Contracts</h4></a></div>
    <div class="listing flex-grow-1 p-3 mb-1"><a href="#" routerLink="/dashboard/documents/policies"><h4>Policies</h4></a></div>
    <div class="listing flex-grow-1 p-3 mb-1"><a href="#" routerLink="/dashboard/documents/permits"><h4>Permits</h4></a></div>
    
    <div class="listing flex-grow-1 p-3 mb-1"><a href="#" routerLink="/dashboard/documents/matter-management"><h4>Matter Management</h4></a></div>
</div>