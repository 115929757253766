import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DocumentCorrespondenceAttachment, DocumentCorrespondenceType, DocumentRegion, DocumentRiskRating, DocumentTemplate, DocumentTemplateType, MatterManagementAffectedParty, MatterManagementDocument, MatterManagementDocumentAdditionalFile, MatterManagementDocumentAppealStatus, MatterManagementDocumentCorrespondence, MatterManagementDocumentCost, MatterManagementDocumentReferral, MatterManagementDocumentRequestType, MatterManagementDocumentStatus } from 'src/app/models/documents';
import { DocumentsApiService } from 'src/app/services/documents-api.service';
import { TokenService } from 'src/app/services/token.service';
import { formatDate } from '@angular/common';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Department } from 'src/app/models/department';
import { LookupApiService } from 'src/app/services/lookup-api.service';

@Component({
  selector: 'app-matter-management-add-edit',
  templateUrl: './matter-management-add-edit.component.html',
  styleUrl: './matter-management-add-edit.component.scss'
})
export class MatterManagementAddEditComponent implements OnInit {

  private modalService = inject(NgbModal);

  companyId: number;
  orgId: number;
  apiCompanyId?: number;

  userId: number;
  user: boolean = false;
  contractAdmin: boolean = false;
  companyAdmin: boolean = false;
  orgAdmin: boolean = false;
  superAdmin: boolean = false;

  templateTypes: DocumentTemplateType[];
  documentCorrespondenceTypes: DocumentCorrespondenceType[];

  //regions: DocumentRegion[];
  //facilities: DocumentRegion[];
  statuses: MatterManagementDocumentStatus[];
  appealStatuses: MatterManagementDocumentAppealStatus[];
  referrals: MatterManagementDocumentReferral[];
  requestTypes: MatterManagementDocumentRequestType[];
  requestTypeOther!: MatterManagementDocumentRequestType;
  riskRatings: DocumentRiskRating[];
  departments: Department[];
  
  submitted = false;


  mainForm = new FormGroup({
    id: new FormControl(0),
    createdAt: new FormControl(),
    lastModified: new FormControl(),
    referenceNumber: new FormControl(),

    documentFilePath: new FormControl(),
    documentFileData: new FormControl(undefined, Validators.required),

    //standardTemplate: new FormControl(undefined, Validators.required),
    //useAsTemplate: new FormControl(false),
    //templateName: new FormControl(undefined),

    //documentTemplateId: new FormControl(),

    recipientFirstname: new FormControl(undefined, Validators.required),
    recipientLastname: new FormControl(undefined, Validators.required),
    recipientContactNumber: new FormControl(undefined, Validators.required),
    recipientEmail: new FormControl(undefined, [Validators.required, Validators.email]),

    //documentRegionId: new FormControl(undefined, Validators.required),
    //documentFacilityId: new FormControl(undefined, Validators.required),

    receivedDate: new FormControl(undefined, Validators.required),
    acknowledgedDate: new FormControl(undefined, Validators.required),

    companyPersonName: new FormControl(),
    companyPersonIDNumber: new FormControl(),
    departmentId: new FormControl(),
    
    requestorFirstname: new FormControl(),
    requestorLastname: new FormControl(),
    requestorIDNumber: new FormControl(),
    requestorCompany: new FormControl(),
    requestorCompanyRegistrationNumber: new FormControl(),
    requestorContactNumber: new FormControl(),
    requestorEmail: new FormControl(),
    requestorAddress: new FormControl(),

    matterManagementDocumentRequestTypeId: new FormControl(undefined, Validators.required),
    matterManagementDocumentRequestTypeOther: new FormControl(undefined),
    documentRiskRatingId: new FormControl(undefined),

    requestDetails: new FormControl(),
    requestReason: new FormControl(),
    incidentDate: new FormControl(),

    disbursementCost: new FormControl(undefined, Validators.min(0)),
    disbursementCostPerMonth: new FormControl(undefined, Validators.min(0)),
    disbursementCostPerAnnum: new FormControl(undefined, Validators.min(0)),
    disbursementIncreasePerAnnum: new FormControl(undefined, Validators.min(0)),

    dealValue: new FormControl(undefined, Validators.min(0)),
    dealValuePerMonth: new FormControl(undefined, Validators.min(0)),
    dealValuePerAnnum: new FormControl(undefined, Validators.min(0)),
    dealIncreasePerAnnum: new FormControl(undefined, Validators.min(0)),
    
    matterManagementDocumentStatusId: new FormControl(undefined, Validators.required),



    decision: new FormControl(),
    decisionReason: new FormControl(),
    decisionDate: new FormControl(),


    recordsLocation: new FormControl(undefined),

    matterManagementDocumentAppealStatusId: new FormControl(undefined),
    matterManagementDocumentReferralId: new FormControl(undefined),


    //infoRequired: new FormControl(),
    //infoRequiredReason: new FormControl(),

    caseOpenedDate: new FormControl(),
    decisionProvidedDate: new FormControl(),
    caseClosedDate: new FormControl(),
    //renewalDate: new FormControl(),

    outcomeReportFilePath: new FormControl(),
    outcomeReportFileData: new FormControl(),

    letterheadFilePath: new FormControl(),
    letterheadFileData: new FormControl(),

    consentFilePath: new FormControl(),
    consentFileData: new FormControl(),

    kycFilePath: new FormControl(),
    kycFileData: new FormControl(),

    companyId: new FormControl(0),
    company: new FormControl(),
    userId: new FormControl(0),
    user: new FormControl(),

    notes: new FormControl(),

    matterManagementAffectedParties: new FormArray([]),
    matterManagementDocumentCosts: new FormArray([]),
    matterManagementDocumentAdditionalFiles: new FormArray([]),

    //documentTemplate: new FormControl(),
    //matterManagementDocumentStatus: new FormControl(),
    //documentFacility: new FormControl(),
  });

  currentDocumentId = 0;
  //currentDocument: MatterManagementDocument;

  constructor(
    private documentsApi: DocumentsApiService,
    private route: ActivatedRoute,
    private token: TokenService,
    private router: Router,
    private spinner: SpinnerService,
    private alertService: AlertService,
    private lookupApi: LookupApiService) { }

  ngOnInit() {

    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user || this.contractAdmin) {
      this.companyId = this.token.companyId();
      this.apiCompanyId = this.companyId;
      if (this.companyId) {
        this.init();
      }

    }
    else if (this.superAdmin || this.orgAdmin) {
      // this.route.paramMap.subscribe((params: ParamMap) => {
      //   this.companyId = +params.get('companyId');
      //   this.apiCompanyId = this.companyId;
      //   if (this.companyId) {
      //     this.init();
      //   }
      // });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }


    this.route.paramMap.subscribe((params: ParamMap) => {
      this.currentDocumentId = +params.get('id');
      this.init();
    });

    // this.documentsApi.GetRegions().subscribe(data => {
    //   this.regions = data;
    // });
    this.documentsApi.GetMatterManagementStatuses().subscribe(data => {
      this.statuses = data;
    });

    this.documentsApi.GetMatterManagementAppealStatuses().subscribe(data => {
      this.appealStatuses = data;
    });

    this.documentsApi.GetMatterManagementReferrals().subscribe(data => {
      this.referrals = data;
    });

    this.documentsApi.GetRiskRatings().subscribe(data => {
      this.riskRatings = data;
    });

    this.documentsApi.GetMatterManagementRequestTypes().subscribe(data => {
      this.requestTypes = data;
      this.requestTypeOther = this.requestTypes.find(f => f.name == 'Other');
    });

    this.lookupApi.ListDepartments().subscribe(data => {
      this.departments = data;
    });

    this.mainForm.controls.matterManagementDocumentRequestTypeId.valueChanges.subscribe(data => {
      
      if (this.mainForm.controls.matterManagementDocumentRequestTypeId.value == this.requestTypeOther?.id) {
        this.mainForm.controls.matterManagementDocumentRequestTypeOther.addValidators(Validators.required);
      }
      else {
        this.mainForm.controls.matterManagementDocumentRequestTypeOther.removeValidators(Validators.required);
      }
      this.mainForm.controls.matterManagementDocumentRequestTypeOther.updateValueAndValidity();
    });

  }

  currentDocument: MatterManagementDocument;
  init() {

    if (this.currentDocumentId > 0 && this.apiCompanyId) {
      this.documentsApi.GetDocumentCorrespondenceTypes('MAtterManagement').subscribe(data => {
        this.documentCorrespondenceTypes = data;
      });
      this.documentsApi.GetMatterManagementDocument(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
        if (!data) {
          this.router.navigateByUrl('/dashboard/documents/matter-management');
          return;
        }

        this.currentDocument = data;

        this.mainForm.patchValue(data);

        this.mainForm.controls.documentFileData.clearValidators();
        this.mainForm.controls.documentFileData.updateValueAndValidity();

        //if (this.requestTypes.findIndex(f => f.id == data.matterManagementDocumentRequestTypeId) == -1) {
          //this.mainForm.controls.requestType.setValue(data.requestType);
        //}

        if (this.mainForm.controls.matterManagementDocumentRequestTypeId.value == this.requestTypeOther?.id) {
          this.mainForm.controls.matterManagementDocumentRequestTypeOther.addValidators(Validators.required);
        }
        else {
          this.mainForm.controls.matterManagementDocumentRequestTypeOther.removeValidators(Validators.required);
        }
        this.mainForm.controls.matterManagementDocumentRequestTypeOther.updateValueAndValidity();

        this.mainForm.patchValue({
          acknowledgedDate: data.acknowledgedDate ? formatDate(data.acknowledgedDate, 'yyyy-MM-dd', 'en') : undefined,
          //renewalDate: data.renewalDate? formatDate(data.renewalDate, 'yyyy-MM-dd', 'en') : undefined,
          incidentDate: data.incidentDate ? formatDate(data.incidentDate, 'yyyy-MM-dd', 'en') : undefined,
          receivedDate: data.receivedDate ? formatDate(data.receivedDate, 'yyyy-MM-dd', 'en') : undefined,
          decisionDate: data.decisionDate ? formatDate(data.decisionDate, 'yyyy-MM-dd', 'en') : undefined,
          caseClosedDate: data.caseClosedDate ? formatDate(data.caseClosedDate, 'yyyy-MM-dd', 'en') : undefined,
          caseOpenedDate: data.caseOpenedDate ? formatDate(data.caseOpenedDate, 'yyyy-MM-dd', 'en') : undefined,
          decisionProvidedDate: data.decisionProvidedDate ? formatDate(data.decisionProvidedDate, 'yyyy-MM-dd', 'en') : undefined
        });

        this.mainForm.controls.matterManagementAffectedParties = new FormArray([]);

        data.matterManagementAffectedParties.forEach(tp => {
          this.addAffectedParty(tp)
        });

        this.mainForm.controls.matterManagementDocumentCosts = new FormArray([]);

        data.matterManagementDocumentCosts.forEach(tp => {
          this.addCost(tp)
        });

        this.mainForm.controls.matterManagementDocumentAdditionalFiles = new FormArray([]);

        data.matterManagementDocumentAdditionalFiles.forEach(tp => {
          this.addFile(tp)
        });


        this.mainForm.updateValueAndValidity();

      })
    }
    else {
      this.mainForm.reset();
      this.mainForm.controls.matterManagementAffectedParties = new FormArray([]);
      this.addAffectedParty();

      this.mainForm.controls.matterManagementDocumentCosts = new FormArray([]);
      //this.addCost();

      this.mainForm.controls.matterManagementDocumentAdditionalFiles = new FormArray([]);
      //this.addFile();
    }
  }

  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.init();
    }
    else {

    }
  }

  fileUpload(control: FormControl, event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 2147483648) { //2MB
        control.setValue(undefined);
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        control.patchValue(reader.result);
      };
    }
  }

  newAffectedParty(affectedparty: MatterManagementAffectedParty = null): FormGroup {

    let fg = new FormGroup({
      id: new FormControl(0),
      firstname: new FormControl(undefined, Validators.required),
      lastname: new FormControl(),
      idNumber: new FormControl(),
      postal: new FormControl(),
      physical: new FormControl(),
      company: new FormControl(),
      companyRegistrationNumber: new FormControl(),
      contactNumber: new FormControl(),
      email: new FormControl(undefined, Validators.email),
      address: new FormControl(),
      matterManagementDocumentId: new FormControl(0)

    });

    if (affectedparty) {
      fg.patchValue(affectedparty);
    }
    return fg;
  }

  addAffectedParty(affectedparty: MatterManagementAffectedParty = null) {
    this.mainForm.controls.matterManagementAffectedParties.push(this.newAffectedParty(affectedparty));
    this.mainForm.updateValueAndValidity();
  }

  removeAffectedParty(index: number) {
    this.mainForm.controls.matterManagementAffectedParties.removeAt(index);
    this.mainForm.updateValueAndValidity();
  }



  newCost(cost: MatterManagementDocumentCost = null): FormGroup {

    let fg = new FormGroup({
      id: new FormControl(0),
      date: new FormControl(undefined, Validators.required),
      description: new FormControl(undefined, Validators.required),
      rate: new FormControl(undefined, [Validators.required, Validators.min(0.01)]),
      time: new FormControl(undefined, [Validators.required, Validators.min(0.01)]),
      matterManagementDocumentId: new FormControl(0)
    });

    if (cost) {
      fg.patchValue(cost);

      fg.patchValue({
        date: cost.date ? formatDate(cost.date, 'yyyy-MM-dd', 'en') : undefined
      });
    }
    return fg;
  }

  addCost(cost: MatterManagementDocumentCost = null) {
    this.mainForm.controls.matterManagementDocumentCosts.push(this.newCost(cost));
    this.mainForm.updateValueAndValidity();
    return this.mainForm.controls.matterManagementDocumentCosts.length - 1;
  }

  removeCost(index: number) {
    this.mainForm.controls.matterManagementDocumentCosts.removeAt(index);
    this.mainForm.updateValueAndValidity();
  }


  newFile(file: MatterManagementDocumentAdditionalFile = null): FormGroup {

    let fg = new FormGroup({
      id: new FormControl(0),
      description: new FormControl(undefined, Validators.required),
      filePath: new FormControl(undefined),
      fileData: new FormControl(undefined),
      matterManagementDocumentId: new FormControl(0)
    });

    if (file) {
      fg.patchValue(file);
    }

    fg.valueChanges.subscribe(s => {
      this.mainForm.updateValueAndValidity();
      fg.updateValueAndValidity();
    });
    return fg;
  }

  addFile(file: MatterManagementDocumentAdditionalFile = null) {
    this.mainForm.controls.matterManagementDocumentAdditionalFiles.push(this.newFile(file));
    this.mainForm.updateValueAndValidity();
    return this.mainForm.controls.matterManagementDocumentAdditionalFiles.length - 1;
  }

  removeFile(index: number) {
    this.mainForm.controls.matterManagementDocumentAdditionalFiles.removeAt(index);
    this.mainForm.updateValueAndValidity();
  }


  public findInvalidControls() {

    const controls = this.mainForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        console.log('INVALID', name);
      }
    }

  }

  submit() {

    let index = this.addCost();
    this.removeCost(index);

    index = this.addFile();
    this.removeFile(index);

    console.log(this.apiCompanyId);
    this.mainForm.markAllAsTouched();

    if (!this.mainForm.valid) {
      this.findInvalidControls();
      this.alertService.error('Not all required fields have been completed please check the form.');
      return;
    }

    let document = this.mainForm.value as MatterManagementDocument;
    document.id = this.currentDocumentId;
    document.companyId = this.apiCompanyId;
    document.userId = this.userId;

    this.spinner.startBackground();
    let func: Observable<{}>;

    if (this.currentDocumentId == 0) {
      func = this.documentsApi.AddMatterManagementDocument(document);

    } else {
      func = this.documentsApi.UpdateMatterManagementDocument(document);
    }

    func.subscribe(() => {
      this.spinner.stopBackground();
      this.alertService.success('Record succesfully added');
      this.router.navigateByUrl('/dashboard/documents/matter-management');
    }, error => {
      this.spinner.stopBackground();
      this.alertService.error(JSON.stringify(error));
    });



  }

  correspondenceForm: FormGroup;
  correspondenceFormSubmitted = false;

  sendCorrespondence(content: TemplateRef<any>) {
    this.correspondenceForm = new FormGroup({
      name: new FormControl(undefined, Validators.required),
      email: new FormControl(undefined, [Validators.required, Validators.email]),
      sender: new FormControl(undefined, Validators.required),
      documentFileData: new FormControl(undefined, Validators.required),
      documentCorrespondenceTypeId: new FormControl(undefined, Validators.required),
      attachments: new FormArray([])
    });

    this.correspondenceFormSubmitted = false;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {

      },
      (reason) => {

      },
    );
  }

  addAttachement() {
    (this.correspondenceForm.controls.attachments as FormArray).push(new FormControl(undefined, Validators.required));

    this.correspondenceForm.updateValueAndValidity();
  }

  removeAttachement(index: number) {
    (this.correspondenceForm.controls.attachments as FormArray).removeAt(index);
    this.correspondenceForm.updateValueAndValidity();
  }

  submitCorrespondence() {
    this.correspondenceFormSubmitted = true;
    if (!this.correspondenceForm.valid) {
      return;
    }

    this.showCorrespondence = false;

    let attachments: DocumentCorrespondenceAttachment[] = [];

    (this.correspondenceForm.controls.attachments as FormArray).controls.forEach(f => {
      let a: DocumentCorrespondenceAttachment = {
        documentFileData: f.value
      };
      attachments.push(a);
    });


    let model: MatterManagementDocumentCorrespondence = {
      documentCorrespondenceTypeId: this.correspondenceForm.controls.documentCorrespondenceTypeId.value,
      matterManagementDocumentId: this.currentDocumentId,
      email: this.correspondenceForm.controls.email.value,
      name: this.correspondenceForm.controls.name.value,
      sender: this.correspondenceForm.controls.sender.value,
      documentFileData: this.correspondenceForm.controls.documentFileData.value,
      attachments: attachments
    };

    this.spinner.start();

    this.documentsApi.addMatterManagementDocumentCorrespondence(model, this.apiCompanyId).subscribe(data => {
      this.spinner.stop();
      this.alertService.success('Correspondence succesfully submitted');
      this.modalService.dismissAll();
    }, error => {
      this.spinner.stop();
      this.alertService.error(JSON.stringify(error));
    })

  }

  correspondence: MatterManagementDocumentCorrespondence[];
  showCorrespondence = false;
  viewCorrespondence() {

    this.spinner.startBackground();
    this.documentsApi.getMatterManagementDocumentCorrespondence(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
      this.showCorrespondence = true;
      this.correspondence = data;
      this.spinner.stopBackground();
    });
  }

  exportCorrespondence() {
    this.spinner.startBackground();
    this.documentsApi.exportMatterManagementDocumentCorrespondence(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }

  hideCorrespondence() {
    this.showCorrespondence = false;
  }

  download(ev: Event, id: number) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.getMatterManagementDocumentCorrespondenceFile(id, this.apiCompanyId).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  downloadAttachment(ev: Event, attachmentId: number, correspondenceId: number) {
    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.getMatterManagementDocumentCorrespondenceAttachmentFile(this.currentDocumentId, attachmentId, correspondenceId, this.apiCompanyId).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    });
  }

  downloadDocumentFile(ev: Event, file: string) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.GetMatterManagementDocumentFile(this.currentDocumentId, this.apiCompanyId, file).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  downloadDocumentHistoryFile(ev: Event, id: number) {
    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.GetMatterManagementDocumentHistoryFile(this.currentDocumentId, this.apiCompanyId, id).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  downloadAdditionalFile(ev: Event, id: number) {
    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.GetMatterManagementDocumentAdditionalFile(this.currentDocumentId, this.apiCompanyId, id).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  print() {
    let popupWin = window.open('', '_blank', 'width=1080,height=595');
    if (!popupWin) {
      return;
    }
    popupWin.document.open();
    let printContents = document.getElementById("mainForm")?.innerHTML;

    let printHead = document.head.innerHTML;
    popupWin.document
      .write(`<html>
           ${printHead}
          <body onload="window.print();">${printContents}</body></html>`);
    popupWin.document.close();

  }
  
}
