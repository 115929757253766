<div class="mb-1" *ngIf="superAdmin || orgAdmin">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.result">
    <h2>PAIA Requests</h2>

    <div class="d-flex align-items-end   mb-2">
        <button class="btn btn-primary me-2" routerLink="/dashboard/documents/home">&lt; BACK</button>
        <div class="flex-fill"></div>
        <button class="btn btn-primary" routerLink="/dashboard/documents/paia/0">NEW REQUEST</button>
    </div>

    <div class="d-flex flex-wrap   mb-2">

        <div class="me-2">
            <label for="filterDecision">Date From:</label>
            <input class="form-control" [ngModel]="filterFrom | date:'yyyy-MM-dd'"
                (ngModelChange)="filterFrom = $event; getData();" type="date" placeholder="Select date">
        </div>

        <div class="me-2">
            <label for="filterDecision">Date To:</label>
            <input class="form-control" [ngModel]="filterTo | date:'yyyy-MM-dd'"
                (ngModelChange)="filterTo = $event; getData();" type="date" placeholder="Select date">
        </div>

        <div class="me-2">
            <label for="filterDecision">Status:</label>
            <ng-select [(ngModel)]="filterStatusId" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 250px;" placeholder="All">
                <ng-option *ngFor="let status of statuses" [value]="status.id">{{status.name}}</ng-option>
            </ng-select>
        </div>
        <div class="me-2">
            <label for="filterDecision">Decision:</label>
            <ng-select [(ngModel)]="filterDecision" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 100px;" placeholder="All">
                <ng-option [value]="1">APPROVED</ng-option>
                <ng-option [value]="0">DECLINED</ng-option>
                <ng-option [value]="-1">PARTIALLY</ng-option>
            </ng-select>
        </div>
        <div class="me-2">
            <label for="filterRequestType">Request Type:</label>
            <ng-select [(ngModel)]="filterRequestType" [items]="requestTypes"
                id="filterRequestType" (change)="getData()" style="min-width: 250px;" placeholder="All"
                [clearable]="true"></ng-select>
        </div>

        <!-- <div class="me-2">
            <label for="documentFacilityId">Facility:</label>
            <ng-select [(ngModel)]="filterFacilityId" [items]="facilities" bindLabel="name" bindValue="id"
                id="documentFacilityId" placeholder="All" [clearable]="true" style="min-width: 250px;"
                [readonly]="facilities == undefined" (change)="getData()"></ng-select>
        </div> -->



        <div class="me-2">
            <label for="filterAppealStatusId">Appeal Status:</label>
            <ng-select [(ngModel)]="filterAppealStatusId" (change)="getData()" [clearable]="true"
                [dropdownPosition]="'top'" style="min-width: 250px;" placeholder="All">
                <ng-option *ngFor="let status of appealStatuses" [value]="status.id">{{status.name}}</ng-option>
            </ng-select>
        </div>

        <div class="me-2">
            <label for="filterAccessGranted">Request granted access in terms of Public interest in terms of section 46
                and 70 of PAIA:</label>
            <ng-select [(ngModel)]="filterAccessGranted" (change)="getData()" [clearable]="true"
                [dropdownPosition]="'top'" style="min-width: 250px;" placeholder="All">
                <ng-option [value]="true">Yes</ng-option>
                <ng-option [value]="false">No</ng-option>
            </ng-select>
        </div>

        <div class="me-2">
            <label for="filterConsent">Has consent been obtained from a Third Party in terms of Sections 48 and
                72:</label>
            <ng-select [(ngModel)]="filterConsent" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 250px;" placeholder="All">
                <ng-option [value]="true">Yes</ng-option>
                <ng-option [value]="false">No</ng-option>
            </ng-select>
        </div>

        <div class="me-2">
            <label for="filterNewOrExisting">Is this a new or existing {{orgId == 55 ? 'Medical ' : '' }}Legal Matter:</label>
            <ng-select [(ngModel)]="filterNewOrExisting" (change)="getData()" [clearable]="true"
                [dropdownPosition]="'top'" style="min-width: 250px;" placeholder="All">
                <ng-option [value]="true">Yes</ng-option>
                <ng-option [value]="false">No</ng-option>
            </ng-select>
        </div>

        <div class="me-2">
            <label for="filterPaiaDocumentReferralId">Referrals:</label>
            <ng-select [(ngModel)]="filterPaiaDocumentReferralId" (change)="getData()" [clearable]="true"
                [dropdownPosition]="'top'" style="min-width: 250px;" placeholder="All">
                <ng-option *ngFor="let referral of referrals" [value]="referral.id">{{referral.name}}</ng-option>
            </ng-select>
        </div>


        <div class="ms-auto d-flex align-items-end">
            <button class="btn btn-primary" (click)="exportData()">EXPORT</button>
        </div>
    </div>

    <div class="row">



        <div class="col">

            <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
                <tr>

                    <th style="width: 15%;" class="text-center">REF NO</th>
                    <th style="width: 15%;" class="text-center">FACILITY</th>
                    <th style="width: 15%;" class="text-center">DATA SUBJECT</th>
                    <th style="width: 15%;" class="text-center">STATUS</th>
                    <th style="width: 10%;" class="text-center">VIEW</th>
                    <th style="width: 20%;" class="text-center">NOTES</th>
                    <th style="width: 10%;" class="text-center">REMINDERS AND TASKS</th>
                </tr>
                <ng-container *ngFor="let item of result?.items">
                    <tr>

                        <td class="p-2"><a class="p-0"
                                [routerLink]="'/dashboard/documents/paia/' + item.id">{{item.referenceNumber}}</a></td>
                        <td>
                            <strong>Person dealing with request:</strong><br />
                            {{item.recipientFirstname}} {{item.recipientLastname}}<br />
                            {{item.recipientContactNumber}}<br />
                            {{item.recipientEmail}}<br />
                            <strong>Entity/Facility:</strong><br />
                            {{item.company}} <br />
                            <!--<strong>Facility:</strong><br />
                            {{item.documentFacility}}-->
                        </td>
                        <td>
                            {{item.subjectName}}<br />
                            {{item.subjectIDNumber}}
                        </td>


                        <td>
                            {{item.paiaDocumentStatus}}<br />
                            <strong>Extension beyond 30 days:</strong><br />
                            {{item.extension ? 'YES' : 'NO'}}
                        </td>
                        <td class="p-2"><button class="btn btn-light" (click)="showHide(item.id)">{{visibleId == item.id
                                ? 'Hide' : 'Show'}} Details</button></td>
                        <td>
                            <div class="d-flex align-items-start">
                                <span class=" flex-grow-1" placement="top" [ngbTooltip]="item.notes">
                                    <pre>{{truncate(item.notes)}}</pre>
                                </span>
                                <a class="ms-1 icon-link" (click)="openNotesModal(notesModal, item)"><span
                                        class="icon icon-small icon-edit-red"></span></a>
                            </div>
                        </td>
                        <td class="text-center"><a href="#" (click)="openReminderModal($event, reminderModal, item)"
                                title="Reminders and Tasks">
                                <span class="icon icon-notification-red"></span>
                            </a></td>

                    </tr>
                    <tr class="bg-light" *ngIf="visibleId == item.id">
                        <td class="p-0" colspan="8">

                            <table class="table table-bordered table-sm company-acts-table mb-0">
                                <tr>
                                    <!-- <th class="text-center">REF NO</th> -->
                                    <!-- <th class="text-center">FACILITY</th> -->
                                    <!-- <th class="text-center">DATA SUBJECT</th> -->
                                    <th style="width: 15%;" class="text-center">REQUESTOR</th>
                                    <th style="width: 15%;" class="text-center">PAIA REQUEST</th>
                                    <!-- <th class="text-center">STATUS</th> -->
                                    <th style="width: 15%;" class="text-center">CHARGES/FEES</th>
                                    <th style="width: 20%;" class="text-center">DECISION</th>
                                    <th style="width: 15%;" class="text-center">NB DATES</th>
                                    <th style="width: 20%;" class="text-center">FILES</th>
                                </tr>
                                <tr>
                                    <!-- <td class="p-2"><a class="p-0"
                                        [routerLink]="'/dashboard/documents/paia/' + item.id">{{item.referenceNumber}}</a></td> -->
                                    <!-- <td>
                                        <strong>Person dealing with request:</strong><br />
                                        {{item.recipientFirstname}} {{item.recipientLastname}}<br />
                                        {{item.recipientContactNumber}}<br />
                                        {{item.recipientEmail}}<br />
                                        <strong>Region:</strong><br />
                                        {{item.documentRegion}}<br />
                                        <strong>Facility:</strong><br />
                                        {{item.documentFacility}}
                                    </td> -->
                                    <!-- <td>
                                        <strong>Requestor details:</strong><br />
                                        {{item.subjectName}}<br />
                                        {{item.subjectIDNumber}}
                                    </td> -->
                                    <td>
                                        <strong>Requestor details:</strong><br />
                                        {{item.requestorFirstname}} {{item.requestorLastname}}<br />
                                        {{item.requestorIDNumber}}<br />
                                        {{item.requestorCompany}}<br />
                                        {{item.requestorCompanyRegistrationNumber}}<br />
                                        {{item.requestorContactNumber}}<br />
                                        {{item.requestorEmail}}<br />
                                        {{item.requestorAddress}}
                                    </td>
                                    <td>
                                        <strong>Request type:</strong><br />
                                        {{item.requestType}}<br />
                                        <strong>Details:</strong><br />
                                        {{item.requestDetails}}<br />
                                        <strong>Reason:</strong><br />
                                        {{item.requestReason}}<br />
                                        <strong>Incident Date:</strong><br />
                                        {{item.incidentDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Received Date:</strong><br />
                                        {{item.receivedDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Acknowledged Date:</strong><br />
                                        {{item.acknowledgedDate | date:'yyyy-MM-dd'}}
                                    </td>
                                    <!-- <td>
                                        {{item.paiaDocumentStatus}}<br />
                                        <strong>Extension beyond 30 days:</strong><br />
                                        {{item.extension ? 'YES' : 'NO'}}
                                    </td> -->
                                    <td>
                                        <strong>Request Fee:</strong><br />
                                        {{item.requestFee}}<br />
                                        <strong>No Request Fee:</strong><br />
                                        {{item.noRequestFee}}<br />
                                        <strong>Cost to prepare reply:</strong><br />
                                        {{item.prepareReplyFee}}<br />
                                        <strong>Deposit:</strong><br />
                                        {{item.deposit}}<br />
                                        <strong>Amount to be banked:</strong><br />
                                        {{item.amountBanked}}<br />
                                    </td>
                                    <td>
                                        <strong>Outcome:</strong><br />
                                        {{getDecisionLabel(item.decision)}}<br />
                                        <strong>Reason:</strong><br />
                                        {{item.decisionReason}}<br />
                                        <strong>Decision Date:</strong><br />
                                        {{item.decisionDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Are these records securely maintained by Management?</strong><br />
                                        {{item.legalFileSecure ? 'YES' : 'NO'}}<br />
                                        <strong>Has consent been obtained from a Third Party in terms of Sections 48 and
                                            72?</strong><br />
                                        {{item.consent ? 'YES' : 'NO'}}
                                    </td>
                                    <td>
                                        <strong>Request:</strong><br />
                                        {{item.requestDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Received:</strong><br />
                                        {{item.receivedDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Case Opened:</strong><br />
                                        {{item.caseOpenedDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Decision Provided:</strong><br />
                                        {{item.decisionProvidedDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Case Closed:</strong><br />
                                        {{item.caseClosedDate | date:'yyyy-MM-dd'}}<br />
                                        <!-- <strong>Renewal:</strong><br />
                                        {{item.renewalDate | date:'yyyy-MM-dd'}}<br /> -->
                                    </td>
                                    <td>
                                        <a href="#" (click)="download($event, item.id, 'Document')"
                                            class="d-flex align-items-center"><span
                                                class="icon icon-view-red me-2"></span>Main
                                            Document</a><br />

                                        <a href="#" (click)="download($event, item.id, 'Form2')"
                                            class="d-flex align-items-center" *ngIf="item.form2FilePath != null"><span
                                                class="icon icon-view-red me-2"></span>Form 2</a><br />
                                        <a href="#" (click)="download($event, item.id, 'Letterhead')"
                                            *ngIf="item.letterheadFilePath" class="d-flex align-items-center"><span
                                                class="icon icon-view-red me-2"></span>Certified
                                            Copy of Data Subject ID/Company</a><br />
                                        <a href="#" (click)="download($event, item.id, 'Consent')"
                                            *ngIf="item.consentFilePath != null" class="d-flex align-items-center"><span
                                                class="icon icon-view-red me-2"></span>Upload signed
                                            consent or power of attorney including
                                            proof of relationship</a>
                                    </td>
                                </tr>
                            </table>

                        </td>

                    </tr>
                </ng-container>

            </table>

            <div class="pager-bar py-4 mb-1" *ngIf="result && result.items">
                <div class="container text-center d-flex flex-row align-items-center justify-content-center"
                    *ngIf="result.items.length > 0">
                    <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt;
                        First</button>&nbsp;
                    <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
                    <span class="px-4">Page {{result.currentPage}} of {{result.totalPages}}</span>
                    <div class="px-4 d-flex flex-row align-items-center">
                        <div class="pe-2">Items per page:
                        </div>
                        <div>
                            <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
                        </div>
                    </div>
                    <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
                    <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last
                        &gt;&gt;</button>&nbsp;
                </div>
            </div>

        </div>

    </div>
</div>

<ng-template #reminderModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Reminders and Tasks</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form>
        <div class="modal-body">

            <div class="form-group">
                <label>Responsible Person Email</label>
                <input class="form-control" [ngModel]="currentReminder.reminderEmail"
                    (ngModelChange)="currentReminder.reminderEmail = $event" type="email" id="reminderEmail"
                    name="reminderEmail" placeholder="Responsible Person Email">
            </div>
            <div class="form-group">
                <label>Reminder Date</label>
                <input class="form-control" [ngModel]="currentReminder.reminderDateTime | date:'yyyy-MM-dd'"
                    (ngModelChange)="currentReminder.reminderDateTime = $event" type="date" id="reminderDateTime"
                    name="reminderDateTime" placeholder="Reminder Date">
            </div>
            <div class="form-group">
                <label>Reminder Text</label>
                <input class="form-control" [(ngModel)]="currentReminder.reminderText" type="text" id="reminderText"
                    name="reminderText" placeholder="Reminder Text">
            </div>
            <div class="form-group">
                <button type="button" ngbAutofocus class="btn btn-primary"
                    [disabled]="!currentReminder.reminderDateTime || !currentReminder.reminderText || !currentReminder.reminderEmail"
                    (click)="addReminder()">Save</button>
            </div>

            <h5>Current Reminders</h5>
            <div *ngFor="let reminder of currentReminders" [class.strike]="!reminder.active">
                <hr />
                {{reminder.reminderDateTime | date:'yyyy-MM-dd'}} ({{reminder.reminderEmail}})<br />
                {{reminder.reminderText}}

            </div>

        </div>
        <div class="modal-footer">
            <button type="button" ngbAutofocus class="btn btn-primary"
                (click)="modal.close('Close click')">Close</button>
        </div>
    </form>
</ng-template>



<ng-template #notesModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update Notes</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <textarea class="form-control" id="notes" name="notes" [(ngModel)]="currentNotes">
              </textarea>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
    </div>
</ng-template>