import { formatDate } from '@angular/common';
import { Component, TemplateRef, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Department } from 'src/app/models/department';
import { ContractDocumentCorrespondence, DocumentCorrespondenceAttachment, DocumentCorrespondenceType, DocumentRiskRating, DocumentTemplate, DocumentTemplateType, PolicyDocument, PolicyDocumentCorrespondence, PolicyDocumentCost, PolicyDocumentRequestType, PolicyDocumentStatus } from 'src/app/models/documents';
import { AlertService } from 'src/app/services/alert.service';
import { DocumentsApiService } from 'src/app/services/documents-api.service';
import { LookupApiService } from 'src/app/services/lookup-api.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TokenService } from 'src/app/services/token.service';


@Component({
  selector: 'app-policies-add-edit',
  templateUrl: './policies-add-edit.component.html',
  styleUrl: './policies-add-edit.component.scss'
})
export class PoliciesAddEditComponent {
  private modalService = inject(NgbModal);

  companyId: number;
  orgId: number;
  apiCompanyId?: number;

  userId: number;
  user: boolean = false;
  contractAdmin: boolean = false;
  companyAdmin: boolean = false;
  orgAdmin: boolean = false;
  superAdmin: boolean = false;

  templateTypes: DocumentTemplateType[];
  templates: DocumentTemplate[];
  documentCorrespondenceTypes: DocumentCorrespondenceType[];

  statuses: PolicyDocumentStatus[];

  requestTypes: PolicyDocumentRequestType[];
  requestTypeOther!: PolicyDocumentRequestType;
  riskRatings: DocumentRiskRating[];
  departments: Department[];
  
  submitted = false;

  mainForm = new FormGroup({
    id: new FormControl(),
    createdAt: new FormControl(),
    lastModified: new FormControl(),
    referenceNumber: new FormControl(),

    documentFilePath: new FormControl(),
    documentFileData: new FormControl(),

    standardTemplate: new FormControl(undefined, Validators.required),
    useAsTemplate: new FormControl(false),
    templateName: new FormControl(undefined),

    documentTemplateId: new FormControl(),

    requestedDate: new FormControl(),

    requiredByName: new FormControl(),
    requiredByIDNumber: new FormControl(),
    departmentId: new FormControl(),
    requiredByAddress: new FormControl(),
    requiredByContactPerson: new FormControl(),
    requiredByEmail: new FormControl(),
    requiredByContactNumber: new FormControl(),

    requestorCompany: new FormControl(),
    requestorDepartment: new FormControl(),
    requestorName: new FormControl(),
    requestorEmail: new FormControl(),
    requestorContactNumber: new FormControl(),

    policyDocumentRequestTypeId: new FormControl(undefined, Validators.required),
    policyDocumentRequestTypeOther: new FormControl(undefined),
    documentRiskRatingId: new FormControl(undefined),

    policyType: new FormControl(),
    law: new FormControl(),
    applicableArea: new FormControl(),

    disbursementCost: new FormControl(undefined, Validators.min(0)),
    disbursementCostPerMonth: new FormControl(undefined, Validators.min(0)),
    disbursementCostPerAnnum: new FormControl(undefined, Validators.min(0)),
    disbursementIncreasePerAnnum: new FormControl(undefined, Validators.min(0)),

    dealValue: new FormControl(undefined, Validators.min(0)),
    dealValuePerMonth: new FormControl(undefined, Validators.min(0)),
    dealValuePerAnnum: new FormControl(undefined, Validators.min(0)),
    dealIncreasePerAnnum: new FormControl(undefined, Validators.min(0)),

    policyStartDate: new FormControl(),
    policyEndDate: new FormControl(),
    policyTerm: new FormControl(),
    policyRenewalDate: new FormControl(),
    policyTerminationDate: new FormControl(),

    policyApprover: new FormControl(),
    policyGranted: new FormControl(),
    companyId: new FormControl(),
    company: new FormControl(),
    policyDocumentStatusId: new FormControl(undefined, Validators.required),
    policyDocumentStatus: new FormControl(),
    userId: new FormControl(0),
    user: new FormControl(),

    policyDocumentCosts: new FormArray([]),
  });

  currentDocumentId = 0;
  //currentDocument: PolicyDocument;


  constructor(
    private documentsApi: DocumentsApiService,
    private route: ActivatedRoute,
    private token: TokenService,
    private router: Router,
    private spinner: SpinnerService,
    private alertService: AlertService,
    private lookupApi: LookupApiService) { }

  ngOnInit() {

    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user) {
      this.companyId = this.token.companyId();
      if (this.companyId) {
        this.init();
      }

    }
    else if (this.superAdmin || this.orgAdmin) {
      // this.route.paramMap.subscribe((params: ParamMap) => {
      //   this.companyId = +params.get('companyId');
      //   this.apiCompanyId = this.companyId;
      //   if (this.companyId) {
      //     this.init();
      //   }
      // });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }


    this.route.paramMap.subscribe((params: ParamMap) => {
      this.currentDocumentId = +params.get('id');

      this.init();
    });

    this.documentsApi.GetPolicyStatuses().subscribe(data => {
      this.statuses = data;
    });

    this.mainForm.controls.standardTemplate.valueChanges.subscribe(data => {
      this.mainForm.controls.documentTemplateId.clearValidators();
      this.mainForm.controls.documentFileData.clearValidators();

      if (this.currentDocumentId == 0) {
        if (data == true) {
          this.mainForm.controls.documentTemplateId.addValidators(Validators.required);
        }
        else {
          this.mainForm.controls.documentFileData.addValidators(Validators.required);
        }
      }

      this.mainForm.controls.documentTemplateId.updateValueAndValidity();
      this.mainForm.controls.documentFileData.updateValueAndValidity();
    });

    this.mainForm.controls.useAsTemplate.valueChanges.subscribe(data => {
      if (this.mainForm.controls.useAsTemplate.value) {
        this.mainForm.controls.templateName.addValidators(Validators.required);
      } else {
        this.mainForm.controls.templateName.clearValidators();
      }
      this.mainForm.controls.templateName.updateValueAndValidity();
    });

    this.documentsApi.GetRiskRatings().subscribe(data => {
      this.riskRatings = data;
    });

    this.documentsApi.GetPolicyRequestTypes().subscribe(data => {
      this.requestTypes = data;
      this.requestTypeOther = this.requestTypes.find(f => f.name == 'Other');
    });

    this.lookupApi.ListDepartments().subscribe(data => {
      this.departments = data;
    });

    this.mainForm.controls.policyDocumentRequestTypeId.valueChanges.subscribe(data => {
      
      if (this.mainForm.controls.policyDocumentRequestTypeId.value == this.requestTypeOther?.id) {
        this.mainForm.controls.policyDocumentRequestTypeOther.addValidators(Validators.required);
      }
      else {
        this.mainForm.controls.policyDocumentRequestTypeOther.removeValidators(Validators.required);
      }
      this.mainForm.controls.policyDocumentRequestTypeOther.updateValueAndValidity();
    });
  }

  init() {
    if (this.apiCompanyId) {
      this.documentsApi.GetTemplates(this.apiCompanyId, 3).subscribe(data => this.templates = data);
    }
    if (this.currentDocumentId > 0 && this.apiCompanyId) {
      this.documentsApi.GetDocumentCorrespondenceTypes('Policy').subscribe(data => {
        this.documentCorrespondenceTypes = data;
      });
      this.documentsApi.GetPolicyDocument(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
        if (!data){
          this.router.navigateByUrl('/dashboard/documents/contracts');
          return;
        }

        this.mainForm.patchValue(data);

        if (this.mainForm.controls.policyDocumentRequestTypeId.value == this.requestTypeOther?.id) {
          this.mainForm.controls.policyDocumentRequestTypeOther.addValidators(Validators.required);
        }
        else {
          this.mainForm.controls.policyDocumentRequestTypeOther.removeValidators(Validators.required);
        }
        this.mainForm.controls.policyDocumentRequestTypeOther.updateValueAndValidity();

        this.mainForm.patchValue({
          requestedDate: data.requestedDate ? formatDate(data.requestedDate, 'yyyy-MM-dd', 'en') : undefined,
          policyStartDate: data.policyStartDate ? formatDate(data.policyStartDate, 'yyyy-MM-dd', 'en') : undefined,
          policyEndDate: data.policyEndDate ? formatDate(data.policyEndDate, 'yyyy-MM-dd', 'en') : undefined,
          policyRenewalDate: data.policyRenewalDate ? formatDate(data.policyRenewalDate, 'yyyy-MM-dd', 'en') : undefined,
          policyTerminationDate: data.policyTerminationDate ? formatDate(data.policyTerminationDate, 'yyyy-MM-dd', 'en') : undefined,
        });

        this.mainForm.controls.policyDocumentCosts = new FormArray([]);

        data.policyDocumentCosts.forEach(tp => {
          this.addCost(tp)
        });

        this.mainForm.updateValueAndValidity();

      })
    }
    else{
      this.mainForm.reset();
      this.mainForm.controls.policyDocumentCosts = new FormArray([]);
    }
  }

  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.init();
    }
    
  }

  fileUpload(control: FormControl, event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 2147483648) { //2MB
        control.setValue(undefined);
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        control.patchValue(reader.result);
      };
    }
  }


    
  newCost(cost: PolicyDocumentCost = null): FormGroup {

    let fg = new FormGroup({
      id: new FormControl(0),
      date: new FormControl(undefined, Validators.required),
      description: new FormControl(undefined, Validators.required),
      rate: new FormControl(undefined, [Validators.required, Validators.min(0.01)]),
      time: new FormControl(undefined, [Validators.required, Validators.min(0.01)]),
      policyDocumentId: new FormControl(0)
    });

    if (cost) {
      fg.patchValue(cost);

      fg.patchValue({
        date: cost.date ? formatDate(cost.date, 'yyyy-MM-dd', 'en') : undefined
      });
    }
    return fg;
  }

  addCost(cost: PolicyDocumentCost = null) {
    this.mainForm.controls.policyDocumentCosts.push(this.newCost(cost));
    this.mainForm.updateValueAndValidity();
    return this.mainForm.controls.policyDocumentCosts.length - 1;
  }

  removeCost(index: number) {
    this.mainForm.controls.policyDocumentCosts.removeAt(index);
    this.mainForm.updateValueAndValidity();
  }



  submit() {

    let index = this.addCost();
    this.removeCost(index);

    console.log(this.apiCompanyId);
    this.mainForm.markAllAsTouched();

    if (!this.mainForm.valid) {
      this.alertService.error('Not all required fields have been completed please check the form.');
      return;
    }

    let document = this.mainForm.value as PolicyDocument;
    document.id = this.currentDocumentId;
    document.companyId = this.apiCompanyId;
    document.userId = this.userId;
    if (!document.useAsTemplate) document.useAsTemplate = false;

    this.spinner.startBackground();
    let func: Observable<{}>;

    if (this.currentDocumentId == 0) {
      func = this.documentsApi.AddPolicyDocument(document);

    } else {
      func = this.documentsApi.UpdatePolicyDocument(document);
    }

    func.subscribe(() => {
      this.spinner.stopBackground();
      this.alertService.success('Record succesfully added');
      this.router.navigateByUrl('/dashboard/documents/policies');
    }, error => {
      this.spinner.stopBackground();
      this.alertService.error(JSON.stringify(error));
    });

  }

  correspondenceForm: FormGroup;
  correspondenceFormSubmitted = false;

  sendCorrespondence(content: TemplateRef<any>) {
    this.correspondenceForm = new FormGroup({
      name: new FormControl(undefined, Validators.required),
      email: new FormControl(undefined, [Validators.required, Validators.email]),
      sender: new FormControl(undefined, Validators.required),
      documentFileData: new FormControl(undefined, Validators.required),
      documentCorrespondenceTypeId: new FormControl(undefined, Validators.required),
      attachments: new FormArray([])
    });

    this.correspondenceFormSubmitted = false;
    
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				
			},
			(reason) => {
				
			},
		);
  }

  addAttachement() {
    (this.correspondenceForm.controls.attachments as FormArray).push(new FormControl(undefined, Validators.required));
      
    this.correspondenceForm.updateValueAndValidity();
  }

  removeAttachement(index: number) {
    (this.correspondenceForm.controls.attachments as FormArray).removeAt(index);
    this.correspondenceForm.updateValueAndValidity();
  }

  submitCorrespondence(){
    this.correspondenceFormSubmitted = true;
    if (!this.correspondenceForm.valid){
      return;
    }

    this.showCorrespondence = false;

    let attachments: DocumentCorrespondenceAttachment[] = [];

    (this.correspondenceForm.controls.attachments as FormArray).controls.forEach(f => {
        let a: DocumentCorrespondenceAttachment = {
          documentFileData: f.value
        };
        attachments.push(a);
    });

    
    let model: PolicyDocumentCorrespondence = {
      documentCorrespondenceTypeId: this.correspondenceForm.controls.documentCorrespondenceTypeId.value,
      policyDocumentId: this.currentDocumentId,
      email: this.correspondenceForm.controls.email.value,
      name: this.correspondenceForm.controls.name.value,
      sender: this.correspondenceForm.controls.sender.value,
      documentFileData: this.correspondenceForm.controls.documentFileData.value,
      attachments: attachments
    };

    this.spinner.start();

    this.documentsApi.addPolicyDocumentCorrespondence(model, this.apiCompanyId).subscribe(data => {
      this.spinner.stop();
      this.alertService.success('Correspondence succesfully submitted');
      this.modalService.dismissAll();
    }, error => {
      this.spinner.stop();
      this.alertService.error(JSON.stringify(error));
    })

  }

  correspondence: PolicyDocumentCorrespondence[];
  showCorrespondence = false;
  viewCorrespondence(){
    if (this.showCorrespondence){
      this.showCorrespondence = false;
      return;
    }
    this.spinner.startBackground();
    this.documentsApi.getPolicyDocumentCorrespondence(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
      this.showCorrespondence = true;
      this.correspondence = data;
      this.spinner.stopBackground();
    });
  }

  exportCorrespondence() {
    this.spinner.startBackground();
    this.documentsApi.exportPolicyDocumentCorrespondence(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }

  hideCorrespondence(){
    this.showCorrespondence = false;
  }

  download(ev: Event, id: number) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.getPolicyDocumentCorrespondenceFile(id, this.apiCompanyId).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  downloadAttachment(ev: Event, attachmentId: number, correspondenceId: number){
    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.getPolicyDocumentCorrespondenceAttachmentFile(this.currentDocumentId, attachmentId, correspondenceId, this.apiCompanyId).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    });
  }

  print() {
    let popupWin = window.open('', '_blank', 'width=1080,height=595');
    if (!popupWin) {
      return;
    }
    popupWin.document.open();
    let printContents = document.getElementById("mainForm")?.innerHTML;

    let printHead = document.head.innerHTML;
    popupWin.document
      .write(`<html>
           ${printHead}
          <body onload="window.print();">${printContents}</body></html>`);
    popupWin.document.close();

  }
}
