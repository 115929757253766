<div class="mb-1" *ngIf="superAdmin || orgAdmin">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.apiCompanyId">
    <h2>Contracts</h2>

    <div class="d-flex align-items-end   mb-2">
        <button class="btn btn-primary me-2" routerLink="/dashboard/documents/home">&lt; BACK</button>
        <div class="flex-fill"></div>
        <button class="btn btn-primary" routerLink="/dashboard/documents/contracts/0">NEW REQUEST</button>
    </div>


    <div class="d-flex flex-wrap   mb-2">

        <div class="me-2">
            <label for="filterDecision">Date From:</label>
            <input class="form-control" [ngModel]="filterFrom | date:'yyyy-MM-dd'"
                (ngModelChange)="filterFrom = $event; getData();" type="date" placeholder="Select date">
        </div>

        <div class="me-2">
            <label for="filterDecision">Date To:</label>
            <input class="form-control" [ngModel]="filterTo | date:'yyyy-MM-dd'"
                (ngModelChange)="filterTo = $event; getData();" type="date" placeholder="Select date">
        </div>

        <div class="me-2">
            <label for="filterStatusId">Status:</label>
            <ng-select [(ngModel)]="filterStatusId" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 250px;" placeholder="All">
                <ng-option *ngFor="let status of statuses" [value]="status.id">{{status.name}}</ng-option>
            </ng-select>
        </div>

        <div class="me-2">
            <label for="filterContractRequestTypeId">Request Type:</label>
            <ng-select [(ngModel)]="filterContractRequestTypeId" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 250px;" placeholder="All">
                <ng-option *ngFor="let requestType of requestTypes" [value]="requestType.id">{{requestType.name}}</ng-option>
            </ng-select>
        </div>

        <div class="me-2">
            <label for="filterRiskRatingId">Risk Rating:</label>
            <ng-select [(ngModel)]="filterRiskRatingId" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 150px;" placeholder="All">
                <ng-option *ngFor="let riskRating of riskRatings" [value]="riskRating.id">{{riskRating.name}}</ng-option>
            </ng-select>
        </div>
        <div class="me-2">
            <label for="filterRiskRatingId">Disbursement:</label>
            <div class="d-flex">
            <input class="form-control me-1" [ngModel]="filterDisbursementValueMin" style="max-width: 80px;"
                (ngModelChange)="filterDisbursementValueMin = $event; getData();" type="number" min="0" placeholder="Min">
                <input class="form-control" [ngModel]="filterDisbursementValueMax" style="max-width: 80px;"
                (ngModelChange)="filterDisbursementValueMax = $event; getData();" type="number" min="0" placeholder="Max">
            </div>
        </div>
        <div class="me-2">
            <label for="filterRiskRatingId">Anticipated Value:</label>
            <div class="d-flex">
            <input class="form-control me-1" [ngModel]="filterAnticipatedValueMin" style="max-width: 80px;"
                (ngModelChange)="filterAnticipatedValueMin = $event; getData();" type="number" min="0" placeholder="Min">
                <input class="form-control" [ngModel]="filterAnticipatedValueMax" style="max-width: 80px;"
                (ngModelChange)="filterAnticipatedValueMax = $event; getData();" type="number" min="0" placeholder="Max">
            </div>
        </div>
        <div class="me-2">
            <label for="filterRiskRatingId">Time & Attendance Hours:</label>
            <div class="d-flex">
            <input class="form-control me-1" [ngModel]="filterTimeAndAttendanceTimeMin" style="max-width: 80px;"
                (ngModelChange)="filterTimeAndAttendanceTimeMin = $event; getData();" type="number" min="0" placeholder="Min">
                <input class="form-control" [ngModel]="filterTimeAndAttendanceTimeMax" style="max-width: 80px;"
                (ngModelChange)="filterTimeAndAttendanceTimeMax = $event; getData();" type="number" min="0" placeholder="Max">
            </div>
        </div>

        <div class="me-2">
            <label for="filterRiskRatingId">Time & Attendance Cost:</label>
            <div class="d-flex">
            <input class="form-control me-1" [ngModel]="filterTimeAndAttendanceCostMin" style="max-width: 80px;"
                (ngModelChange)="filterTimeAndAttendanceCostMin = $event; getData();" type="number" min="0" placeholder="Min">
                <input class="form-control" [ngModel]="filterTimeAndAttendanceCostMax" style="max-width: 80px;"
                (ngModelChange)="filterTimeAndAttendanceCostMax = $event; getData();" type="number" min="0" placeholder="Max">
            </div>
        </div>


        <div class="ms-auto d-flex align-items-end">
            <button class="btn btn-primary" (click)="exportData()">EXPORT</button>
        </div>



    </div>

    <div class="row">



        <div class="col">

            <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
                <tr>
                    <th class="text-center">REF NO</th>

                    <th style="width: 15%;" class="text-center">PARTIES TO THE AGREEMENT </th>
                    <th style="width: 15%;" class="text-center">TYPE</th>
                    <th style="width: 15%;" class="text-center">OTHER CONTRACTING PARTIES </th>
                    <th style="width: 15%;" class="text-center">STATUS</th>
                    <th style="width: 15%;" class="text-center">VIEW</th>
                    <th style="width: 20%;" class="text-center">NOTES</th>
                    <th style="width: 15%;" class="text-center">REMINDERS AND TASKS</th>






                </tr>
                <ng-container *ngFor="let item of result?.items">
                    <tr>
                        <td class="p-2"><a class="p-0"
                                [routerLink]="'/dashboard/documents/contracts/' + item.id">{{item.referenceNumber}}</a>
                        </td>


                        <td>
                            {{item.organisationName}}<br />
                            {{item.department}}<br />
                            {{item.negotiatingTeam}}<br />
                            {{item.headOfTeam}}<br />
                            {{item.contractsAdministrator}}<br />
                        </td>
                        <td>
                            {{item.contractDocumentAgreementType}}<br />
                            <strong>Details:</strong><br />
                            {{item.contractDetails}}<br />
                            <strong>Company Standard:</strong><br />
                            {{item.companyStandard}}<br />
                            <strong>Newly drafted agreement:</strong><br />
                            {{item.organisationOwnAgreement}}<br />
                            <strong>Agreement supplied by other party:</strong><br />
                            {{item.agreementSuppliedByOtherParty}}<br />
                        </td>
                        <td>
                            <ng-container *ngIf="item.contractThirdParties && item.contractThirdParties.length > 0">
                                {{item.contractThirdParties[0].name}}<br />
                                {{item.contractThirdParties[0].registrationNumber}}<br />
                                {{item.contractThirdParties[0].ckNumber}}<br />
                                {{item.contractThirdParties[0].identityNumber}}<br />
                                {{item.contractThirdParties[0].address}}<br />
                                {{item.contractThirdParties[0].email}}<br />
                                {{item.contractThirdParties[0].contactPerson}}<br />
                                {{item.contractThirdParties[0].contactNumber}}
                            </ng-container>
                        </td>

                        <td>
                            {{item.contractDocumentStatus}}
                        </td>
                        <td class="p-2"><button class="btn btn-light" (click)="showHide(item.id)">{{visibleId == item.id
                                ? 'Hide' : 'Show'}} Details</button></td>
                        <td>
                            <div class="d-flex align-items-start">
                                <span class=" flex-grow-1" placement="top" [ngbTooltip]="item.notes">
                                    <pre>{{truncate(item.notes)}}</pre>
                                </span>
                                <a class="ms-1 icon-link" (click)="openNotesModal(notesModal, item)"><span
                                        class="icon icon-small icon-edit-red"></span></a>
                            </div>
                        </td>
                        <td class="text-center"><a href="#" (click)="openReminderModal($event, reminderModal, item)"
                                title="Reminders and Tasks">
                                <span class="icon icon-notification-red"></span>
                            </a></td>








                    </tr>

                    <tr class="bg-light" *ngIf="visibleId == item.id">
                        <td class="p-0" colspan="8">

                            <table class="table table-bordered table-sm company-acts-table mb-0">
                                <tr>
                                    <th class="text-center">DATE</th>
                                    <th class="text-center">THIRD PARTY DETAILS</th>
                                    <th class="text-center">DURATION &amp; DATES</th>
                                    <th class="text-center">DISBURSEMENTS</th>
                                    <th class="text-center">DEAL VALUE</th>
                                    <th class="text-center">TIME & ATTENDANCE</th>
                                    <th class="text-center">RISK OR ONEROUS ISSUES</th>
                                    <th class="text-center">KYC<br />DD<br />FICA</th>
                                    <th class="text-center">APPROVED BY LEGAL</th>
                                    <th class="text-center">SIGNED &amp; UPLOADED</th>
                                    <th class="text-center" style="width: 150px;">FILES</th>
                                </tr>
                                <tr>
                                    <td>
                                        {{item.requestedDate | date:'yyyy-MM-dd'}}
                                    </td>

                                    <td>
                                        <ng-container *ngFor="let tp of item.contractThirdParties; let i = index">
                                            <ng-container *ngIf="i > 0">
                                                {{item.contractThirdParties[i].name}}<br />
                                                {{item.contractThirdParties[i].registrationNumber}}<br />
                                                {{item.contractThirdParties[i].ckNumber}}<br />
                                                {{item.contractThirdParties[i].identityNumber}}<br />
                                                {{item.contractThirdParties[i].address}}<br />
                                                {{item.contractThirdParties[i].email}}<br />
                                                {{item.contractThirdParties[i].contactPerson}}<br />
                                                {{item.contractThirdParties[i].contactNumber}}<br />
                                                <hr />
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <strong>Commencement:</strong><br />
                                        {{item.commencementDate | date:'yyyy-MM-dd'}}<br />
                                        <strong>Duration of Contract:</strong><br />
                                        {{item.contractDuration}}<br />
                                        <strong>Expected End Date:</strong><br />
                                        {{item.expectedEndDate | date:'yyyy-MM-dd'}}<br />
                                    </td>
                                    <td>
                                        <strong>Cost:</strong><br />
                                        {{item.disbursementCost}}<br />
                                        <strong>Cost per month:</strong><br />
                                        {{item.disbursementCostPerMonth}}<br />
                                        <strong>Cost per annum:</strong><br />
                                        {{item.disbursementCostPerAnnum}}<br />
                                        <strong>Increase per annum:</strong><br />
                                        {{item.disbursementIncreasePerAnnum}}<br />
                                    </td>
                                    <td>
                                        <strong>Value:</strong><br />
                                        {{item.dealValue}}<br />
                                        <strong>Monthly:</strong><br />
                                        {{item.dealValuePerMonth}}<br />
                                        <strong>Cost per Annum:</strong><br />
                                        {{item.dealValuePerAnnum}}<br />
                                        <strong>Increase:</strong><br />
                                        {{item.dealIncreasePerAnnum}}<br />
                                    </td>
                                    <td class="text-center">
                                        <strong>Time:</strong><br />
                                        {{calculateTime(item)}} hours<br />
                                        <strong>Cost:</strong><br />
                                        R {{calculateCosts(item) | number: '1.2-2'}}

                                    </td>
                                    <td>
                                        {{item.riskAndOnerousClauses}}
                                    </td>
                                    <td>
                                        {{item.dueDiligenceDocumentFilePath == true ? 'YES' : (item.dueDiligenceDocumentFilePath == false ? 'NO' : 'N/A')}}
                                    </td>
                                    <td>
                                        {{item.approvedByLegal == true ? 'YES' : (item.approvedByLegal == false ? 'NO' : 'N/A')}}
                                    </td>
                                    <td>
                                        {{item.signedByBothParties == true ? 'YES' : (item.signedByBothParties == false ? 'NO' : 'N/A')}}
                                    </td>

                                    <td>
                                        <a href="#" (click)="download($event, item.id, 'Document')"
                                            class="d-flex align-items-center"><span
                                                class="icon icon-view-red me-2"></span>Contract</a><br />
                                        <a *ngIf="item.dueDiligenceDocumentFilePath" href="#"
                                            (click)="download($event, item.id, 'DueDiligence')"
                                            class="d-flex align-items-center"><span
                                                class="icon icon-view-red me-2"></span>Due Diligence</a><br />

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </ng-container>
            </table>

            <div class="pager-bar py-4 mb-1" *ngIf="result && result.items">
                <div class="container text-center d-flex flex-row align-items-center justify-content-center"
                    *ngIf="result.items.length > 0">
                    <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt;
                        First</button>&nbsp;
                    <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
                    <span class="px-4">Page {{result.currentPage}} of {{result.totalPages}}</span>
                    <div class="px-4 d-flex flex-row align-items-center">
                        <div class="pe-2">Items per page:
                        </div>
                        <div>
                            <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
                        </div>
                    </div>
                    <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
                    <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last
                        &gt;&gt;</button>&nbsp;
                </div>
            </div>

        </div>

    </div>
</div>

<ng-template #reminderModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Reminders and Tasks</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form>
        <div class="modal-body">

            <div class="form-group">
                <label>Responsible Person Email</label>
                <input class="form-control" [ngModel]="currentReminder.reminderEmail"
                    (ngModelChange)="currentReminder.reminderEmail = $event" type="email" id="reminderEmail"
                    name="reminderEmail" placeholder="Responsible Person Email">
            </div>
            <div class="form-group">
                <label>Reminder Date</label>
                <input class="form-control" [ngModel]="currentReminder.reminderDateTime | date:'yyyy-MM-dd'"
                    (ngModelChange)="currentReminder.reminderDateTime = $event" type="date" id="reminderDateTime"
                    name="reminderDateTime" placeholder="Reminder Date">
            </div>
            <div class="form-group">
                <label>Reminder Text</label>
                <input class="form-control" [(ngModel)]="currentReminder.reminderText" type="text" id="reminderText"
                    name="reminderText" placeholder="Reminder Text">
            </div>
            <div class="form-group">
                <button type="button" ngbAutofocus class="btn btn-primary"
                    [disabled]="!currentReminder.reminderDateTime || !currentReminder.reminderText || !currentReminder.reminderEmail"
                    (click)="addReminder()">Save</button>
            </div>

            <h5>Current Reminders</h5>
            <div *ngFor="let reminder of currentReminders" [class.strike]="!reminder.active">
                <hr />
                {{reminder.reminderDateTime | date:'yyyy-MM-dd'}} ({{reminder.reminderEmail}})<br />
                {{reminder.reminderText}}

            </div>

        </div>
        <div class="modal-footer">
            <button type="button" ngbAutofocus class="btn btn-primary"
                (click)="modal.close('Close click')">Close</button>
        </div>
    </form>
</ng-template>



<ng-template #notesModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update Notes</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <textarea class="form-control" id="notes" name="notes" [(ngModel)]="currentNotes">
              </textarea>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
    </div>
</ng-template>